import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CreatePasswordService } from '@wlp/ui-bs-device-validation';
import { AccountTypeService, CreateAccountService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from './../../../../../../../../environments/environment';
import { LoadingService } from './../../../../../../../core/layout/services/loading.service';

@Component({
  selector: 'ui-t-create-password',
  templateUrl: './create-password.page.html',
  styleUrls: ['./create-password.page.scss'],
})
export class CreatePasswordPage implements OnInit {
  errorMessages: any;
  formCreatePassword: FormGroup;
  preAccountData: any;
  currentStep: Step;
  isPartner: boolean;
  partnerBusiness: any;
  private destroy$: Subject<null> = new Subject();
  idBusinessPartner: any;
  companyName: string = '';
  identifier: string = '';
  fullname: string = '';

  constructor(
    private createPasswordService: CreatePasswordService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private createAccountService: CreateAccountService,
    private accountTypeService: AccountTypeService,
    private translate: UiBsTranslateService,
    private loadService: LoadingService,
    private stepsService: StepFlowService,
    private router: Router,
    private storage: Storage,
  ) {
  }

  async ngOnInit() {
    this.loadService.show();
    this.isPartner = true;
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.formCreatePassword = this.createPasswordService.getForm();
    this.errorMessages = this.createPasswordService.getErrorFormMessage();

    this.currentStep = await this.stepsService.getSteps(environment, this.router.url);
    this.getDataInfo();

    this.loadService.hide();

    this.storage.get('idBusinessPartner').then((result) => {
      if (result != null) {
        this.idBusinessPartner = result;
        this.isPartner = false;
      }
    })
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }

  goContinue() {
    if (this.formCreatePassword.invalid) {
      return;
    }

    this.loadService.show();

    this.createPasswordService.createPassword('contract', this.idBusinessPartner)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res) => {
          this.loadService.hide();
          await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        }),
        retry(2),
        catchError((err) => {
          this.loadService.hide();
          this.createPasswordService.getErrorPassword(err);
          return of(null);
        })
      ).subscribe();
  }

  getDataInfo() {
    this.accountTypeService.getAccount()
      .then(res => {
        this.preAccountData = res;
        this.returnPartner();
        this.changeNameMethod();
      });
  }

  returnPartner() {
    if (this.preAccountData.companyData !== undefined) {
      this.partnerBusiness = this.preAccountData.businessPartner.find((arr: any) => {
        return arr.id === this.idBusinessPartner;
      });
      this.companyName = this.preAccountData.companyData.companyName;
      this.fullname = this.partnerBusiness.fullname;
      let partnerObj = {
        companyName: this.preAccountData.companyData.companyName,
        cpf: this.preAccountData.cpf,
        fullname: this.partnerBusiness.fullname
      }
      this.storage.set('partnerName', partnerObj);
    }
  }

  changeNameMethod() {
    this.partnerBusiness = this.preAccountData.businessPartner.find((arr: any) => {
      return arr.id === this.idBusinessPartner;
    });
    if (this.partnerBusiness.master === true && this.partnerBusiness.signature === true &&
      this.partnerBusiness.attorney === false && this.partnerBusiness.legalRepresentative === false) {
      this.identifier = 'ONBORDING.V2.FORM.LABEL.PARTNERS';
    } else if (this.partnerBusiness.attorney === true) {
      this.identifier = 'ONBORDING.V2.FORM.LABEL.ATTORNEYS';
    } else if (this.partnerBusiness.legalRepresentative === true) {
      this.identifier = 'ONBORDING.V2.FORM.LABEL.LEGAL_REPRESENTATIVES';
    } else {
      this.identifier = 'ONBORDING.V2.FORM.LABEL.PARTNERS';
    }
  }

}
