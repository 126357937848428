import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Platform} from '@ionic/angular';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {LayoutConfigModel} from './core/layout/models/layout-config.model';
import {LayoutConfigService} from './core/layout/services/layout-config.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  dark = false;
  wlName: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private layoutConfigService: LayoutConfigService,
    private titleService: Title,
    private translate: UiBsTranslateService
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.initializeApp();
  }

  initializeApp() {
    this.titleService.setTitle(this.wlName);

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.setPropertyStyles();
  }

  setPropertyStyles() {
    // Intro Vars
    const introBgColor = this.layoutConfigService.getConfig('wlTheme.intro.background.color');
    const introTxtColor = this.layoutConfigService.getConfig('wlTheme.intro.text.color');
    const introBtnBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.color.primary');
    const introBtnBgColorSecondary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.color.secondary');
    const introBtnBgHoverPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.hover.primary');
    const introBtnBgHoverSecondary = this.layoutConfigService.getConfig('wlTheme.intro.button.background.hover.secondary');
    const introBtnTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.text.color.primary');
    const introBtnTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.intro.button.text.color.secondary');
    const introBtnBorderColorPrimary = this.layoutConfigService.getConfig('wlTheme.intro.button.border.color.primary');
    const introBtnBorderColorSecondary = this.layoutConfigService.getConfig('wlTheme.intro.button.border.color.secondary');
    const introSocialColor = this.layoutConfigService.getConfig('wlTheme.intro.social.color');
    const introFooterBgColor = this.layoutConfigService.getConfig('wlTheme.intro.footer.background.color');
    const introFooterBorderColor = this.layoutConfigService.getConfig('wlTheme.intro.footer.border.color');

    // Onboarding
    const onboardingTextColorPrimary = this.layoutConfigService.getConfig('wlTheme.onboarding.text.color.primary');
    const onboardingTextColorSecondary = this.layoutConfigService.getConfig('wlTheme.onboarding.text.color.secondary');
    const onboardingTextColorTertiary = this.layoutConfigService.getConfig('wlTheme.onboarding.text.color.tertiary');

    const onboardingMessageColorInfo = this.layoutConfigService.getConfig('wlTheme.onboarding.message.color.info');
    const onboardingMessageColorSuccess = this.layoutConfigService.getConfig('wlTheme.onboarding.message.color.success');
    const onboardingMessageColorWarn = this.layoutConfigService.getConfig('wlTheme.onboarding.message.color.warn');
    const onboardingMessageColorError = this.layoutConfigService.getConfig('wlTheme.onboarding.message.color.error');

    const onboardingBtnBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.background.color.primary');
    const onboardingBtnBgColorSecondary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.background.color.secondary');
    const onboardingBtnBgHoverPrimary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.background.hover.primary');
    const onboardingBtnBgHoverSecondary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.background.hover.secondary');
    const onboardingBtnTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.text.color.primary');
    const onboardingBtnTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.text.color.secondary');
    const onboardingBtnTxtColorTertiary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.text.color.tertiary');
    const onboardingBtnBorderColorPrimary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.border.color.primary');
    const onboardingBtnBorderColorSecondary = this.layoutConfigService.getConfig('wlTheme.onboarding.button.border.color.secondary');

    // Message
    const messageColorInfo = this.layoutConfigService.getConfig('wlTheme.message.color.info');
    const messageColorSuccess = this.layoutConfigService.getConfig('wlTheme.message.color.success');
    const messageColorWarn = this.layoutConfigService.getConfig('wlTheme.message.color.warn');
    const messageColorError = this.layoutConfigService.getConfig('wlTheme.message.color.error');

    // Button General
    const buttonBackgroundColorInfo = this.layoutConfigService.getConfig('wlTheme.button.background.color.info');
    const buttonBackgroundColorSuccess = this.layoutConfigService.getConfig('wlTheme.button.background.color.success');
    const buttonBackgroundColorWarn = this.layoutConfigService.getConfig('wlTheme.button.background.color.warn');
    const buttonBackgroundColorError = this.layoutConfigService.getConfig('wlTheme.button.background.color.error');
    const buttonBackgroundColorContrast = this.layoutConfigService.getConfig('wlTheme.button.background.color.contrast');
    const buttonTextColorInfo = this.layoutConfigService.getConfig('wlTheme.button.text.color.info');
    const buttonTextColorSuccess = this.layoutConfigService.getConfig('wlTheme.button.text.color.success');
    const buttonTextColorWarn = this.layoutConfigService.getConfig('wlTheme.button.text.color.warn');
    const buttonTextColorError = this.layoutConfigService.getConfig('wlTheme.button.text.color.error');
    const buttonTextColorContrast = this.layoutConfigService.getConfig('wlTheme.button.text.color.contrast');

    // Timiline Vars
    const timelineBgColor = this.layoutConfigService.getConfig('wlTheme.timeline.background.color');
    const timelineTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.timeline.text.color.primary');
    const timelineTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.timeline.text.color.secondary');
    const timelineBulletColorPrimary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.primary');
    const timelineBulletColorSecondary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.secondary');
    const timelineBulletColorTertiary = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.color.tertiary');
    const timelineBulletBorderColor = this.layoutConfigService.getConfig('wlTheme.timeline.bullet.border.color');

    // Aside Vars
    const asideBgColor = this.layoutConfigService.getConfig('wlTheme.aside.background.color');
    const asideTxtColor = this.layoutConfigService.getConfig('wlTheme.aside.text.color');
    const asideTxtHover = this.layoutConfigService.getConfig('wlTheme.aside.text.hover');

    // Header Vars
    const headerBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.background.color.primary');
    const headerBgColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.background.color.secondary');
    const headerTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.text.color.primary');
    const headerTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.text.color.secondary');
    const headerTxtColorTertiary = this.layoutConfigService.getConfig('wlTheme.header.text.color.tertiary');
    const headerBtnBgColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.button.background.color.primary');
    const headerBtnBgColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.button.background.color.secondary');
    const headerBtnTxtColorPrimary = this.layoutConfigService.getConfig('wlTheme.header.button.text.color.primary');
    const headerBtnTxtColorSecondary = this.layoutConfigService.getConfig('wlTheme.header.button.text.color.secondary');
    const headerProfileBgColor = this.layoutConfigService.getConfig('wlTheme.header.profile.background.color');

    // Font Vars
    const fontSize = this.layoutConfigService.getConfig('wlTheme.font.size');
    const fontFamily = this.layoutConfigService.getConfig('wlTheme.font.family');

    // Global Vars
    const globalBtnTxtColor = this.layoutConfigService.getConfig('wlTheme.global.button.text.color');

    // Custom Header Vars
    const customHeaderTextColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.text.color.primary');
    const customHeaderTextColorSecondary = this.layoutConfigService.getConfig('wlTheme.custom.header.text.color.secondary');
    const customHeaderButtonColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.button.color.primary');
    const customHeaderButtonColorSecondary = this.layoutConfigService.getConfig('wlTheme.custom.header.button.color.secondary');
    const customHeaderTextButtonColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.text.button.color.primary');
    const customHeaderTextButtonColorSecondary = this.layoutConfigService.getConfig('wlTheme.custom.header.text.button.color.secondary');
    const customHeaderColorPrimary = this.layoutConfigService.getConfig('wlTheme.custom.header.color.primary');
    const customHeaderColorSecondary = this.layoutConfigService.getConfig('wlTheme.custom.header.color.secondary');

    // Color Vars
    const colorPrimary = this.layoutConfigService.getConfig('wlTheme.color.primary');
    const colorSecondary = this.layoutConfigService.getConfig('wlTheme.color.secondary');

    // CUstom height Vars
    const vh = window.innerHeight * 0.01;

    // Custom height Sets
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // Color Sets
    document.documentElement.style.setProperty('--ui-t-color-primary', colorPrimary);
    document.documentElement.style.setProperty('--ui-t-color-secondary', colorSecondary);

    document.documentElement.style.setProperty('--ion-color-primary', colorPrimary);
    document.documentElement.style.setProperty('--ion-color-primary-shade', colorPrimary);
    document.documentElement.style.setProperty('--ion-color-primary-tint', colorPrimary);

    document.documentElement.style.setProperty('--ion-color-secondary', colorSecondary);
    document.documentElement.style.setProperty('--ion-color-secondary-shade', colorSecondary);
    document.documentElement.style.setProperty('--ion-color-secondary-tint', colorSecondary);

    // Font Sets
    document.documentElement.style.setProperty('--ui-t-font-size', fontSize);
    document.documentElement.style.setProperty('--ui-t-font-family', fontFamily);

    // Global Sets
    document.documentElement.style.setProperty('--ui-t-global-button-text-color', globalBtnTxtColor);

    // Intro Sets
    document.documentElement.style.setProperty('--ui-t-intro-background-color', introBgColor);
    document.documentElement.style.setProperty('--ui-t-intro-text-color', introTxtColor);
    document.documentElement.style.setProperty('--ui-t-intro-button-background-color-primary', introBtnBgColorPrimary);
    document.documentElement.style.setProperty('--ui-t-intro-button-background-color-secondary', introBtnBgColorSecondary);
    document.documentElement.style.setProperty('--ui-t-intro-button-background-hover-primary', introBtnBgHoverPrimary);
    document.documentElement.style.setProperty('--ui-t-intro-button-background-hover-secondary', introBtnBgHoverSecondary);
    document.documentElement.style.setProperty('--ui-t-intro-button-text-color-primary', introBtnTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-intro-button-text-color-secondary', introBtnTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-intro-button-border-color-primary', introBtnBorderColorPrimary);
    document.documentElement.style.setProperty('--ui-t-intro-button-border-color-secondary', introBtnBorderColorSecondary);
    document.documentElement.style.setProperty('--ui-t-intro-social-color', introSocialColor);
    document.documentElement.style.setProperty('--ui-t-intro-footer-background-color', introFooterBgColor);
    document.documentElement.style.setProperty('--ui-t-intro-footer-border-color', introFooterBorderColor);

    // message
    document.documentElement.style.setProperty('--ui-t-message-color-info', messageColorInfo);
    document.documentElement.style.setProperty('--ui-t-message-color-success', messageColorSuccess);
    document.documentElement.style.setProperty('--ui-t-message-color-warn', messageColorWarn);
    document.documentElement.style.setProperty('--ui-t-message-color-error', messageColorError);

    // Button General
    document.documentElement.style.setProperty('--ui-t-button-background-color-info', buttonBackgroundColorInfo);
    document.documentElement.style.setProperty('--ui-t-button-background-color-success', buttonBackgroundColorSuccess);
    document.documentElement.style.setProperty('--ui-t-button-background-color-warn', buttonBackgroundColorWarn);
    document.documentElement.style.setProperty('--ui-t-button-background-color-error', buttonBackgroundColorError);
    document.documentElement.style.setProperty('--ui-t-button-background-color-contrast', buttonBackgroundColorContrast);
    document.documentElement.style.setProperty('--ui-t-button-text-color-info', buttonTextColorInfo);
    document.documentElement.style.setProperty('--ui-t-button-text-color-success', buttonTextColorSuccess);
    document.documentElement.style.setProperty('--ui-t-button-text-color-warn', buttonTextColorWarn);
    document.documentElement.style.setProperty('--ui-t-button-text-color-error', buttonTextColorError);
    document.documentElement.style.setProperty('--ui-t-button-text-color-contrast', buttonTextColorContrast);

    // Onboarding
    document.documentElement.style.setProperty('--ui-t-onboarding-text-color-primary', onboardingTextColorPrimary);
    document.documentElement.style.setProperty('--ui-t-onboarding-text-color-secondary', onboardingTextColorSecondary);
    document.documentElement.style.setProperty('--ui-t-onboarding-text-color-tertiary', onboardingTextColorTertiary);

    document.documentElement.style.setProperty('--ui-t-onboarding-message-color-info', onboardingMessageColorInfo);
    document.documentElement.style.setProperty('--ui-t-onboarding-message-color-success', onboardingMessageColorSuccess);
    document.documentElement.style.setProperty('--ui-t-onboarding-message-color-warn', onboardingMessageColorWarn);
    document.documentElement.style.setProperty('--ui-t-onboarding-message-color-error', onboardingMessageColorError);

    document.documentElement.style.setProperty('--ui-t-onboarding-button-background-color-primary', onboardingBtnBgColorPrimary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-background-color-secondary', onboardingBtnBgColorSecondary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-background-hover-primary', onboardingBtnBgHoverPrimary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-background-hover-secondary', onboardingBtnBgHoverSecondary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-text-color-primary', onboardingBtnTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-text-color-secondary', onboardingBtnTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-text-color-tertiary', onboardingBtnTxtColorTertiary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-border-color-primary', onboardingBtnBorderColorPrimary);
    document.documentElement.style.setProperty('--ui-t-onboarding-button-border-color-secondary', onboardingBtnBorderColorSecondary);

    // Timeline Stes
    document.documentElement.style.setProperty('--ui-t-timeline-background-color', timelineBgColor);
    document.documentElement.style.setProperty('--ui-t-timeline-text-color-primary', timelineTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-timeline-text-color-secondary', timelineTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-primary', timelineBulletColorPrimary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-secondary', timelineBulletColorSecondary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-color-tertiary', timelineBulletColorTertiary);
    document.documentElement.style.setProperty('--ui-t-timeline-bullet-border-color', timelineBulletBorderColor);

    // Aside Sets
    document.documentElement.style.setProperty('--ui-t-aside-background-color', asideBgColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-color', asideTxtColor);
    document.documentElement.style.setProperty('--ui-t-aside-text-hover', asideTxtHover);

    // Header Sets
    document.documentElement.style.setProperty('--ui-t-header-background-color-primary', headerBgColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-background-color-secondary', headerBgColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-primary', headerTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-secondary', headerTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-text-color-tertiary', headerTxtColorTertiary);
    document.documentElement.style.setProperty('--ui-t-header-button-background-color-primary', headerBtnBgColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-button-background-color-secondary', headerBtnBgColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-button-text-color-primary', headerBtnTxtColorPrimary);
    document.documentElement.style.setProperty('--ui-t-header-button-text-color-secondary', headerBtnTxtColorSecondary);
    document.documentElement.style.setProperty('--ui-t-header-profile-background-color', headerProfileBgColor);
    document.documentElement.style.setProperty('--ui-t-color-custom-header-color-primary', customHeaderColorPrimary);
    document.documentElement.style.setProperty('--ui-t-color-custom-header-color-secondary', customHeaderColorSecondary);
  }
}
