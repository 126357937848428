import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BilletService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Base64Service } from '@wlp/ui-bs-util';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-modal-import-billet-model',
  templateUrl: './modal-import-billet-model.component.html',
  styleUrls: ['./modal-import-billet-model.component.scss'],
})
export class ModalImportBilletModelComponent implements OnInit {
  fieladDisabled: boolean = true;
  typeFile: string = 'typeFile';
  userProfile: UserInfoProfile;
  file: any = {};
  fileName: string = '';
  constructor(
    private modalCtrl: ModalController,
    private base64Service: Base64Service,
    private billetService: BilletService,
    private loadingService: LoadingService,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService
  ) { }

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  public uploadFile(fileList: FileList) {
    let fileSelected = fileList.item(0);
    this.base64Service
      .convertFileToBase64(fileSelected)
      .then((data) => {
        this.file = {
          base64: data,
          title: fileSelected.name,
        };
        this.fieladDisabled = false;
        this.fileName = fileSelected.name
      })
      .catch((err) => {
        console.error('Erro no converte', err);
      });
  }

  public sendFile() {
    this.loadingService.show();
    this.billetService
      .postbatchBillsModel$(this.file, this.userProfile.uuidWhiteLabel)
      .pipe(
        tap((resp: any) => {
          this.loadingService.hide();
          if (resp.isExecuted) {
            this.modalCtrl.dismiss().then(() => {
              this.notification(
                'success',
                `
                  Recebemos seu Lote <b>  ${resp.lotNumber} </b> e está <b><u>Em validação</u></b>.
                  Acompanhe o processamento através do menu <b>Cobrança > Gerenciar Lotes.</b>'
                `,
                resp.uuid
              );
            });
          } else {
            this.modalCtrl.dismiss().then(() => {
              this.notification(
                'warning-circle',
                resp.message,
                ""
              );
            });
          }
        })
      )
      .subscribe();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  public closeModal(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  private async notification(status: string, message: string, uuidBillet?: string) {
    const modalSuccess = await this.modalCtrl.create({
      component: ModalAlertComponent,
      cssClass: 'modal-importation-billet',
      componentProps: {
        status,
        message,
        uuidBillet,
        confirmBtn: 'Fechar',
        confirmation: false,
      },
      backdropDismiss: false,
    });
    return await modalSuccess.present();
  }
}
