import { UserInfoService } from '@wlp/ui-bs-login';
import { FormGroup } from '@angular/forms';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { ModalController, ToastController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, Input, OnInit } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'modal-token-change-password',
  templateUrl: './modal-token-change-password.component.html',
  styleUrls: ['./modal-token-change-password.component.scss']
})
export class ModalTokenChangePasswordComponent implements OnInit {

  progressbarValue: number;
  count: number;
  sub: any;
  paramTime: { time: number; };
  time: number;
  public loading = false;
  minutes: number;
  seconds: number;
  public token: string;
  public userProfile: UserInfoProfile;
  public formToken: FormGroup;
  public errorMessages: any;
  verifySub: any;
  @Input() email: string;
  @Input() validationOnly: boolean;
  @Input() validationCard: boolean;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private toastController: ToastController,
    private translate: UiBsTranslateService,
    private tokenCodeService: TokenCodeService,
    protected userInfoService: UserInfoService
  ) { }

  ngOnInit() {
    this.loadUser();
    this.errorMessages = this.tokenCodeService.getFormMessageError();
    this.formToken = this.tokenCodeService.createFormToken();
    this.startProgress();
  }

  ionViewWillLeave() {
    this.sub.unsubscribe();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }

  }

  public async confirm() {
    this.loading = true;
    const token = this.formToken.get('token').value;
    const validToken = await this.tokenCodeService.confirmToken(
      this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token);
    if (await validToken) {
      this.loading = false;
      if (this.validationOnly) {
        if (this.validationCard) {
          this.dismissSuccess(validToken, token);
          return;
        } else {
          this.dismissSuccess(validToken, token);
          return;
        }
      }
      this.dismissSuccess(validToken, token);
    } else {
      this.loading = false;
      this.presentToast('MY_DATA.CHANGE_PASSWORD.INFOS.TOKEN_ERROR');
    }
  }

  async dismissSuccess(status: boolean, token: string) {
    this.resetSub();
    //this.verifySub.unsubscribe();
    this.modalController.dismiss(status, token);
  }

  resetSub() {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  startProgress() {
    this.progressbarValue = 1;
    this.count = 0;
    const timer$ = interval(1000);
    this.sub = timer$.subscribe((sec) => {
      this.count = sec;
      this.time = 60 - this.count
      this.minutes = Math.floor(this.time / 60);
      this.seconds = this.time - this.minutes * 60;

      this.progressbarValue = 1 - (sec * 20) / 1200;
      if (this.progressbarValue === 0) {
        this.presentToast('MY_DATA.CHANGE_PASSWORD.INFOS.TOKEN_EXPIRED');
        this.modalController.dismiss();
        this.sub.unsubscribe();
      }
    });
  }

  emailMask(email) {
    var maskedEmail = email.replace(/([^@\.])/g, "*").split('');
    var previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous == "." || previous == "@") {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join('');
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message: this.translate.applyTranslate(message),
      duration: 4000,
      position: 'top',
      color: 'secondary',
      translucent: false
    });
    toast.present();
  }
}
