import { environment } from 'src/environments/environment';

import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {
    AccountPaymentTypeResponse, AccountTypeService, CreateAccountService
} from '@wlp/ui-bs-signup';

@Component({
  selector: 'ui-t-modal-flow',
  templateUrl: './modal-flow.component.html',
  styleUrls: ['./modal-flow.component.scss'],
})
export class ModalFlowComponent implements OnInit {
  @Input() public env: any;
  @Input() public response: any;

  public status: any;
  public username: any;
  public typeflow: any;
  public typeflowOne: any;
  public typeflowTwo: any;
  public firstname: string;
  public isTypeAvaiable: boolean;
  public listAccountType: AccountPaymentTypeResponse[];

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountTypeService: AccountTypeService,
    private createAccountService: CreateAccountService
  ) {}

  ngOnInit() {
    this.isTypeAvaiable = false;
    if (this.response.length === 1) {
      this.isTypeAvaiable = false;
      this.status = this.response[0].status;
      this.setFlowPerson(this.response[0], 0);
    } else if (this.response.length > 1) {
      this.isTypeAvaiable = true;
      this.setFlowPerson(this.response[0], 1);
      this.setFlowPerson(this.response[1], 2);
    }

    this.loadAccountType();
    this.checkFlow();
  }

  continueFlow(resp) {
    // console.log('continueFlow', resp);

    this.createAccountService.processPreAccount(resp);
    if (resp.status === 'IN_ONBORDING') {
      if (resp.lastStep === 'upload-documents?a=a' || resp.lastStep === 'choose-document?a=a') {
        this.createAccountService.findAccountTypeByUuid(resp._id).subscribe(({ accountType }) => {
          const typeFlow = accountType.typeFlow.toString();
          this.accountTypeService.temporaryTypeAccount(accountType.typeFlow.toString());

          if (typeFlow === 'PHHYSICAL_PERSON' || typeFlow === 'PHYSICAL_PERSON' || typeFlow === 'MEI') {
            this.navCtrl.navigateRoot('document-type');
          } else if (typeFlow === 'LEGAL_PERSON') {
            this.navCtrl.navigateRoot('partners');
          } else if (typeFlow === 'LIBERAL_PROFESSIONAL') {
            this.navCtrl.navigateRoot('professional-type');
          }
        });
      } else {
        this.createAccountService.findAccountTypeByUuid(resp._id).subscribe(({ accountType }) => {
          if ( accountType) {
            this.accountTypeService.temporaryTypeAccount(accountType.typeFlow.toString());
          } else {
            console.log('accountType: not defined');
          }
        });

        this.navCtrl.navigateRoot(resp.lastStep);
      }
    } else if (resp.status === 'REPROVED') {
      this.navCtrl.navigateRoot('status-account');
    } else {
      this.navCtrl.navigateRoot('status-account');
    }
    this.dismiss();
  }

  checkFlow() {

    document.getElementsByTagName('body')[0].classList.remove('simple-typeflow');
    document.getElementsByTagName('body')[0].classList.remove('multiple-typeflow');

    this.createAccountService.findAccountTypeByUuid(this.response[0]._id).subscribe((res) => {
      /* console.log('checkFlow', res);
      console.log('checkFlow', this.response); */
      if (
        this.response.length === 1 &&
        // this.response[0].status !== 'FINISHED' &&
        res.accountType &&
        res.accountType.typeFlow !== undefined
      ) {
        /* document.getElementsByTagName('body')[0].classList.add('no-typeflow'); */
        document.getElementsByTagName('body')[0].classList.add('multiple-typeflow');
      } else if (this.response.length > 1) {
        /* console.log('checkFlow 2'); */
        document.getElementsByTagName('body')[0].classList.add('multiple-typeflow');
      } else {
        document.getElementsByTagName('body')[0].classList.add('simple-typeflow');
      }
    });
  }

  setFlowPerson(resp, type) {
    this.status = resp.status;

    Promise.all([
      this.createAccountService.loadPreAccount(resp._id),
      this.createAccountService.findAccountTypeByUuid(resp._id).toPromise(),
    ]).then((res) => {
      this.username = res[0].name;
      // this.firstname = this.username.substr(0, this.username.indexOf(' '));
      this.firstname = this.username.split(' ')[0];

      // console.log('setFlowPerson', res[1].accountType, 'type: ', type);
      const accountTypeName = res[1].accountType ? res[1].accountType.name : '';
        if (type === 0) {
          this.typeflow = { nameType: accountTypeName, statusType: resp.status, resp: resp };
        } else if (type === 1) {
          this.typeflowOne = { nameType: accountTypeName, statusType: resp.status, resp: resp };
        } else if (type === 2) {
          this.typeflowTwo = { nameType: accountTypeName, statusType: resp.status, resp: resp };
        }
    });
  }

  setFlowPhysicalPerson() {
    this.accountTypeService.temporaryTypeAccount('PHHYSICAL_PERSON');
    this.dismiss();
  }

  setFlowLegalPerson() {
    this.accountTypeService.temporaryTypeAccount('LEGAL_PERSON');
    this.dismiss();
  }
  setFlowMei() {
    this.accountTypeService.temporaryTypeAccount('MEI');
    this.dismiss();
  }
  setFlowLiberalProfessional() {
    this.accountTypeService.temporaryTypeAccount('LIBERAL_PROFESSIONAL');
    this.dismiss();
  }

  gotoLogin() {
    this.navCtrl.navigateRoot('/');
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list) => (this.listAccountType = list))
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  isContinueOnboarding(status) {
    return (
      status === 'REPROVED' ||
      status === 'IN_ONBORDING' ||
      status === 'APPROVED_TOPAZIO' ||
      status === 'APPROVED_BACKOFFICE' ||
      status === 'APPROVED_COMPLIANCE' ||
      status === 'IN_ANALYSIS_TOPAZIO' ||
      status === 'IN_ANALYSIS_BACKOFFICE' ||
      status === 'IN_ANALYSIS_COMPLIANCE' ||
      status === 'DOCUMENT_LEGAL_PERSON_REPROVED' ||
      status === 'DOCUMENT_PHYSICAL_PERSON_REPROVED' ||
      status === 'REGISTRATION_DATA_LEGAL_PERSON_INVALID' ||
      status === 'REGISTRATION_DATA_PHYSICAL_PERSON_INVALID' ||
      status === 'DOCUMENT_AND_REGISTRATION_DATA_PHYSICAL_PERSON_REPROVED' ||
      status === 'DOCUMENT_AND_REGISTRATION_DATA_LEGAL_PERSON_REPROVED'
    );
  }
}
