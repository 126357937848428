import { Routes, RouterModule } from '@angular/router';
import { GeneralSettingsPage } from './general-settings/general-settings.page';
import { SettingsPage } from './settings.page';

const routes: Routes = [
  {
    path: '',
    component: SettingsPage,
    children: [
      {
        path: 'general-settings',
        component: GeneralSettingsPage,
      },
    ],
  },
];

export const SettingsRoutes = RouterModule.forChild(routes);
