import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'modal-card-document',
  templateUrl: './modal-card-document.component.html',
  styleUrls: ['./modal-card-document.component.scss'],
})
export class ModalCardDocumentComponent {

  @Input() public title: any;
  @Input() public titleBtnConfirm: any;
  @Input() public titleBtnCancel: any;
  @Input() public alertRequest: boolean;
  @Output() public setService = new EventEmitter;
  @Input() public valueCard: any;
  @Input() public tagImg: string;
  @Input() public cardResponse: any;
  @Input() public uuidAccountPayment: string;
  @Input() public accountData: any;
  @Input() public doubleButton: boolean;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
  ) {
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public confirm() {
    this.modalController.dismiss(true);
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
