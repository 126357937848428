import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsPage } from './settings.page';
import { UiCHeaderModule } from '@wlp/ui-c-header';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { SettingsRoutes } from './settings.routing';
import { IonicModule } from '@ionic/angular';
import { UiBsUtilModule } from '@wlp/ui-bs-util';
import { GeneralSettingsPage } from './general-settings/general-settings.page';

@NgModule({
  imports: [SettingsRoutes, IonicModule, CommonModule, UiCHeaderModule, UiBsUtilModule, UiBsTranslateModule],
  declarations: [SettingsPage, GeneralSettingsPage],
})
export class SettingsModule {}
