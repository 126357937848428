import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DigitalAccountConsolidadedPage} from './digital-account-consolidaded/digital-account-consolidaded.page';
import { ListSchedulingPage } from './list-scheduling/list-scheduling.page';

import {CnabConciliationPage} from './cnab-conciliation/cnab-conciliation.page';
import { ExtractDigitalAccountPage } from './digital-account/extract-digital-account.page';
import { ExtractReceiptPage } from './receipt/extract-receipt.page';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'digital-account',
        pathMatch: 'full',
      },
      {
        path: 'receipt/:type',
        component: ExtractReceiptPage,
      },
      {
        path: 'digital-account/:type',
        component: ExtractDigitalAccountPage,
      },
      {
        path: 'consolidaded/:type',
        component: DigitalAccountConsolidadedPage,
      },
      {
        path: 'list-scheduling',
        component: ListSchedulingPage,
      },
      {
        path: 'moviment/conciliation',
        component: CnabConciliationPage,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class ExtractRoutingModule { }
