import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { FormFilter } from './form-filter/form-filter';
import { SearchDays } from '../search-days/search-days';
import { BilletStatusEnum } from '@wlp/ui-bs-cashin';
@Component({
  selector: 'ui-t-filter-lot',
  templateUrl: './filter-lot.html',
  styleUrls: ['./filter-lot.scss'],
})
export class FilterLot implements OnInit {
  private layoutConfigValue: LayoutConfigModel;
  private langDefault: string;

  @Output() handleSubmit = new EventEmitter<string>();
  @Output() handleSearchForDays = new EventEmitter<string>();
  @Input() lotOrBillet: 'lot' | 'billet';
  @Input() public statusSelected: BilletStatusEnum;
  @ViewChild(FormFilter, {static: false}) public formFilter: FormFilter;
  @ViewChild(SearchDays, {static: false}) public searchDays: SearchDays;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private translate: UiBsTranslateService,
  ) {
    moment.locale('pt-br');
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  handleClickSubmit() {
    this.searchDays.resetBtnFilters();
    this.handleSubmit.emit()
  }
  
  handleClickSearchForDays(numberDays: number) {
    this.handleSearchForDays.emit(numberDays.toString())
  }
}
