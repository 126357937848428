import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
name: 'billetDataFilter',
pure: false
})

export class BilletDataFilterPipe implements PipeTransform {

transform(names: any[], terms: string): any[] {
  let AVAILABLE = "AVAILABLE";
  let DRAFT = "DRAFT";
  let IN_PROCESS = "IN_PROCESS";
  let IN_VALIDATION = "IN_VALIDATION";
  let PENDING = "PENDING";

  if (!names) return [];
  if (!terms) return names;

  terms = terms.toLowerCase();
  terms = terms.replace(',', '.');

  if (terms.substr(0, 4) === "pend" && terms.length<=8) {
    terms = PENDING.toLowerCase();
  }
  if (terms.substr(0, 4) === "disp" && terms.length<=10) {
    terms = AVAILABLE.toLowerCase();
  }
  if (terms.substr(0, 5) === "indis" && terms.length<=12) {
    terms = IN_VALIDATION.toLowerCase();
  }
  if (terms.substr(0, 5) === "em pr"  && terms.length<=11 || terms === "processo" && terms.length<=8) {
    terms = IN_PROCESS.toLowerCase();
  }
  if (terms.substr(0, 4) === "rasc" && terms.length<=8) {
    terms = DRAFT.toLowerCase();
  }
  if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])$/.test(terms)) {
    const date = moment(terms, ['DD/MM']);
    console.log('date:', date)
    if (date.isValid()) {
      terms = date.format('MM-DD');
    }
  }
  if (/^(01|[1-9]|0[1-9]|1[0-2])\/(\d{2}|\d{4})$/.test(terms)) {
    const date = moment(terms, ['MM/YY', 'MM/YYYY']);
    console.log('date:', date)
    if (date.isValid()) {
      terms = date.format('YYYY-MM');
    }
  }
  if (/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{2}|\d{4})$/.test(terms)) {
    const date = moment(terms, ['DD/MM/YYYY', 'DD/MM/YY']);
    console.log('date:', date)
    if (date.isValid()) {
      terms = date.format('YYYY-MM-DD');
    }
  }
  else {
    return names.filter((it) => {
    return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

  terms = terms.toLowerCase();

  return names.filter((it) => {
    return JSON.stringify(it).toLowerCase().includes(terms);
  });

}
}
