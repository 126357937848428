import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { BilletLotService, BilletService, LotStatusEnum } from '@wlp/ui-bs-cashin';
import { Paginated, PaginationParams } from '@wlp/ui-bs-parameter';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LotBilletCompletedDto } from '@wlp/ui-px-cashin';
import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalAlertComponent } from 'src/app/views/themes/sentinel/modals/modal-alert/modal-alert.component';
import { ModalBilletAlertCancelComponent } from 'src/app/views/themes/sentinel/modals/modal-billet-alert-cancel/modal-billet-alert-cancel';
import { ModalTokenComponent } from 'src/app/views/themes/sentinel/modals/modal-token/modal-token.component';
import { BilletDataFilterPipe } from './billet-lot-v2-filter.pipe';
import { ModalImportBilletModelComponent } from 'src/app/views/themes/sentinel/modals/modal-import-billet-model/modal-import-billet-model.component';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FilterLot } from '../components/filter-lot/filter-lot';

enum FileFormatEnum {
  XLSX = 'xlsx',
}

@Component({
  selector: 'ui-t-billing-billet-lotV2',
  templateUrl: './billet-lot-v2.page.html',
  styleUrls: ['./billet-lot-v2.page.scss'],
  providers: [BilletDataFilterPipe],
})
export class BilletLotPageV2 implements OnInit {
  @ViewChild(`btnList`, { static: false }) btnList: ElementRef;
  @ViewChild(FilterLot, {static: false}) public filterLot: FilterLot;
  private userProfile: UserInfoProfile;
  private uuidAccountPayment: string;

  private readonly OPERATION: string = '1002';
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  isuuidAccountPayment: boolean = false;
  public maxData: number = 0;

  public pendingValue: any;
  public pendingQuantity: any;
  public availableValue: any;
  public availableQuantity: any;
  public validationValue: any;
  public validationQuantity: any;
  public processValue: any;
  public processQuantity: any;
  public draftValue: any;
  public draftQuantity: any;

  public itemsPerPageOptions: number[] = [10, 20, 30, 50, 100];
  public itemsPerPage: number = 10;
  public iconSkipLast = './assets/icon/icon-material-skip-last.svg';
  public iconNavigateLast = './assets/icon/icon-material-navigate-last.svg';
  public iconNavigateNext = './assets/icon/icon-material-navigate-next.svg';
  public iconSkipNext = './assets/icon/icon-material-skip-next.svg';

  lotsPaginated: Paginated<LotBilletCompletedDto[]> = null;
  FileFormatEnum = FileFormatEnum;
  endDateSelected: string;
  startDateSelected = this.today.subtract(7, 'days').format('YYYY-MM-DD');

  public customPopoverOptionsDownload: any = {
    header: 'Downalod do Modelo'
  };

  constructor(
    protected activatedRoute: ActivatedRoute,
    protected userInfoService: UserInfoService,
    public pipeFilter: BilletDataFilterPipe,
    private translate: UiBsTranslateService,
    private layoutConfigService: LayoutConfigService,
    private billetService: BilletService,
    private billetLotService: BilletLotService,
    public loadingService: LoadingService,
    public modalController: ModalController,
    private navCtrl: NavController,
    private cdr: ChangeDetectorRef
  ) {
    this.pendingValue = 0;
    this.processValue = 0;
    this.availableValue = 0;
    this.validationValue = 0;
    this.draftValue = 0;
    this.pendingQuantity = 0;
    this.processQuantity = 0;
    this.availableQuantity = 0;
    this.validationQuantity = 0;
    this.draftQuantity = 0;
  }

  async ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.loadingService.show();

    try {
      await this.loadUser();

      const paginationParams = new PaginationParams(1, this.itemsPerPage);
      await this.getLotsPaginated(this.startDateSelected, null, null, null, paginationParams);

      this.setCardsValues();
    } catch (error) {
      console.error('## BilletLotPageV2.onStart error: ', error);
    } finally {
      this.loadingService.hide();
    }
  }

  get today() {
    return moment().clone();
  }
  
  private async loadUser(): Promise<UserInfoProfile> {
    try {
      this.userProfile = await this.userInfoService.getInfoProfile();
      this.uuidAccountPayment = this.userProfile.uuidAccount;
      return this.userProfile;
    } catch (error) {
      console.error('Failed to load user details', error);
    }
    this.cdr.detectChanges();
  }

  public async getLotsPaginated(startDate?: string, endDate?: string, status?: LotStatusEnum, searchText?: string, paginationParams?: PaginationParams): Promise<Paginated<LotBilletCompletedDto[]>> {
    this.lotsPaginated = await this.billetLotService.getLotsPaginated(startDate, endDate, status, searchText, paginationParams).toPromise();
    return this.lotsPaginated;
  }

  async handleChangeItemsPerPage(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    const paginationParams = new PaginationParams(0, itemsPerPage);
    const {startDate, endDate, searchKey, status} = this.getValuesFromForm()

    const formattedStartDate: string = this.formatToYYYYMMDD(startDate);
    const formattedEndDate: string = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate || this.startDateSelected, formattedEndDate, status, searchKey, paginationParams);
  }
  async handleGoToFirstPage() {
    const paginationParams = new PaginationParams(1, this.itemsPerPage);
    const {startDate, endDate, searchKey, status} = this.getValuesFromForm()

    const formattedStartDate: string = this.formatToYYYYMMDD(startDate);
    const formattedEndDate: string = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate || this.startDateSelected, formattedEndDate, status, searchKey, paginationParams);

  }
  async handleGoToLastPage() {
    const lastPage = this.lotsPaginated ? this.lotsPaginated.pagination.totalPages : 0;
    const paginationParams = new PaginationParams(lastPage, this.itemsPerPage);
    const {startDate, endDate, searchKey, status} = this.getValuesFromForm()

    const formattedStartDate: string = this.formatToYYYYMMDD(startDate);
    const formattedEndDate: string = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate || this.startDateSelected, formattedEndDate, status, searchKey, paginationParams);
  }
  async handleGoToNextPage() {
    const next = this.lotsPaginated ? this.lotsPaginated.pagination.page + 1 : 0;
    const paginationParams = new PaginationParams(next, this.itemsPerPage);
    const {startDate, endDate, searchKey, status} = this.getValuesFromForm()

    const formattedStartDate: string = this.formatToYYYYMMDD(startDate);
    const formattedEndDate: string = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate || this.startDateSelected, formattedEndDate, status, searchKey, paginationParams);
  }
  async handleGoToPrevPage() {
    const prev = this.lotsPaginated ? this.lotsPaginated.pagination.page - 1 : 0;
    const paginationParams = new PaginationParams(prev, this.itemsPerPage);
    const {startDate, endDate, searchKey, status} = this.getValuesFromForm()

    const formattedStartDate: string = this.formatToYYYYMMDD(startDate);
    const formattedEndDate: string = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate || this.startDateSelected, formattedEndDate, status, searchKey, paginationParams);
  }

  private setCardsValues() {
    const initialValue = { quantity: 0, totalValue: 0 };
    const pending = Object.assign({}, initialValue);
    const process = Object.assign({}, initialValue);
    const available = Object.assign({}, initialValue);
    const validation = Object.assign({}, initialValue);
    const draft = Object.assign({}, initialValue);

    this.lotsPaginated.data.forEach((lot) => {
      const amount = Number(lot.amount);
      const value = Number(lot.totalValue);

      if (lot.status === 'IN_PROCESS') {
        process.quantity += amount;
        process.totalValue += value;
      } else if (lot.status === 'IN_VALIDATION') {
        validation.quantity += amount;
        validation.totalValue += value;
      } else if (lot.status === 'PENDING') {
        pending.quantity += amount;
        pending.totalValue += value;
      } else if (lot.status === 'AVAILABLE') {
        available.quantity += amount;
        available.totalValue += value;
      } else if (lot.status === 'DRAFT') {
        draft.quantity += amount;
        draft.totalValue += value;
      }
    });

    this.pendingValue = String(pending.totalValue);
    this.pendingQuantity = String(pending.quantity);
    this.validationValue = String(validation.totalValue);
    this.validationQuantity = String(validation.quantity);
    this.processValue = String(process.totalValue);
    this.processQuantity = String(process.quantity);
    this.availableValue = String(available.totalValue);
    this.availableQuantity = String(available.quantity);
    this.draftValue = String(draft.totalValue);
    this.draftQuantity = String(draft.quantity);
  }

  async handleClickSearch() {
    this.loadingService.show();
    const {status, searchKey, startDate, endDate} = this.getValuesFromForm();
    const paginationParams = new PaginationParams(0, this.itemsPerPage)

    const formattedStartDate = this.formatToYYYYMMDD(startDate);
    const formattedEndDate = this.formatToYYYYMMDD(endDate);
  
    await this.getLotsPaginated(formattedStartDate, formattedEndDate, status, searchKey, paginationParams);

    this.loadingService.hide();
  }

  formatToYYYYMMDD(dateString: string): string {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month}-${day}`;
    }
    return dateString;
  }
  

  getValuesFromForm() {
    const {filterLotForm} = this.filterLot.formFilter
    const startDate = filterLotForm.get('startDate').value;
    const endDate = filterLotForm.get('endDate').value;
    const status = filterLotForm.get('status').value;
    const searchKey = filterLotForm.get('searchKey').value;
    return { startDate, endDate, status, searchKey }
  }

  resetFromForm() {
    const {filterLotForm} = this.filterLot.formFilter
    filterLotForm.get('status').setValue('');
    filterLotForm.get('searchKey').setValue('');
    filterLotForm.get('startDate').setValue(this.startDateSelected);
    filterLotForm.get('endDate').setValue(this.endDateSelected);
  }

  async handleClickSearchForDays(numberDays: number) {
    this.loadingService.show();
    const end = moment(); 
    let start;
  
    if (numberDays == 7) {
      start = moment().subtract(7, 'days'); 
    } else if (numberDays == 15) {
      start = moment().subtract(15, 'days');
    } else if (numberDays == 30) {
      start = moment().subtract(30, 'days');
    }
    
    const startDate = start.format('DD/MM/YYYY');
    this.startDateSelected = startDate;

    const endFormatted = moment(end).format('DD/MM/YYYY');
    this.endDateSelected = endFormatted;

    const paginationParams = new PaginationParams(0, this.itemsPerPage);

    const formatToYYYYMMDD = (dateString) => {
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const [day, month, year] = parts;
        return `${year}-${month}-${day}`;
      }
      return dateString;
    };

    const formattedStartDate = formatToYYYYMMDD(startDate);
    const formattedEndDate = formatToYYYYMMDD(endFormatted);

    await this.getLotsPaginated(formattedStartDate, formattedEndDate, null, null, paginationParams);
    this.resetFromForm();

    this.loadingService.hide();
  }


  public statusTranslate(status: string) {
    switch (status) {
      case 'PENDING': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.PENDING');
      case 'AVAILABLE': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.AVAILABLE');
      case 'IN_VALIDATION': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.IN_VALIDATION');
      case 'IN_PROCESS': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.IN_PROCESS');
      case 'DRAFT': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.DRAFT');
      case 'CANCELED': return this.translate.applyTranslate('CASH_OUT.BILLET_LOT_DETAILED_PAGE.BILLET_INFORMATION.CANCELED');
      default: return '--';
    }
  }

  public bulletColor(status: string) {
    switch (status) {
      case 'PENDING': return 'text-pending';
      case 'AVAILABLE': return 'text-available';
      case 'IN_VALIDATION': return 'text-in-validation';
      case 'IN_PROCESS': return 'text-in-progress';
      case 'DRAFT': return 'text-in-draft';
      case 'CANCELED':
        return 'text-in-canceled';
      default: return '';
    }
  }

  public billetInLot(quantity: any) {
    const param = {
      amount: quantity > 0 ? quantity : 0,
    };
    return param;
  }

  public action(action: string, uuid?: string) {
    if (action === 'edit') {
      this.navCtrl.navigateRoot([`app/charging/billet/v2/lot/${this.uuidAccountPayment}/${uuid}`]);
    }
  }

  private async removeLotBillets(uuid: string) {
    this.loadingService.show();
    await this.billetService.removeLotBillets(uuid)
      .subscribe(res => {
        if (res.statusCode === 400) {
          this.loadingService.hide();
          this.setCardsValues()
          this.statusNotification('success', 'FAVORITES.MESSAGES.DELETE_SUCCESS', true);
        } else {
          this.loadingService.hide();
          console.error('removeLotBillets() não funfou :`(');
        }
      }, (error) => {
        this.loadingService.hide();
        console.error(error, 'removeLotBillets() não funfou :`(');
      })
  }

  public async cancelAlert(uuid?: string) {
    const modalToken = await this.modalController.create({
      component: ModalBilletAlertCancelComponent,
      cssClass: 'modal-billet-alert-cancel',
      componentProps: {
        validationOnly: true,
        codeOperation: this.OPERATION,
        confirmation: true,
        confirm: () => this.validateToken(uuid),
        title: 'CASH_OUT.MODAL_CANCEL.TITLE',
        description: 'CASH_OUT.MODAL_CANCEL.DESCRIPTION',
        alert1: 'CASH_OUT.MODAL_CANCEL.ALERT1',
        alert2: 'CASH_OUT.MODAL_CANCEL.ALERT2',
        confirmBtn: 'CASH_OUT.MODAL_CANCEL.BUTTON1',
        cancelBtn: 'CASH_OUT.MODAL_CANCEL.BUTTON2'
      },
      backdropDismiss: false,
    });
    return await modalToken.present();
  }

  public async validateToken(uuid?: string) {
    const modalToken = await this.modalController.create({
      component: ModalTokenComponent,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: false,
        codeOperation: this.OPERATION,
        confirmation: true,
        onSuccessValidation: () => this.removeLotBillets(uuid)
      },
      backdropDismiss: false,
    });
    return await modalToken.present();
  }

  private async statusNotification(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: 'CASH_OUT.MODAL_ALERT.ALERT1',
        confirmBtn: 'CASH_OUT.ACCOUNT_CHARGING.CLOSE_BUTTON',
        translate: translate,
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }

  public deleteLotBillet(uuidLotBillet: string, uuidAccountPayment: string, token: string) {
    this.billetLotService.cancelBilletLot({ uuidLotBillet, uuidAccountPayment, token }).subscribe(
      (res) => {
        //to do: fix this bellow
        //@ts-ignore
        if (res.statusCode && res.statusCode !== 200) {
          this.statusNotification('error', 'FAVORITES.MESSAGES.DELETE_ERROR', true);
        } else {
          this.statusNotification('success', 'FAVORITES.MESSAGES.DELETE_SUCCESS', true);
        }
      },
      (err) => {
        console.error(err);
        this.statusNotification('error', 'FAVORITES.MESSAGES.DELETE_ERROR', true);
      }
    );
  }

  public async handleClickDeleteLot(uuidLot: string) {
    const modalToken = await this.modalController.create({
      component: ModalTokenComponent,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: true,
        codeOperation: this.OPERATION,
      },
    });
    modalToken.onDidDismiss().then((data) => {
      if (data['data']) {
        const lot = this.lotsPaginated.data.find(lot => lot.uuid == uuidLot);
        this.deleteLotBillet(lot.uuid, lot.uuidAccountPayment, data.role);
      }
    });
    return await modalToken.present();
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public async openImportBilletModal() {
    const modal = await this.modalController.create({
      component: ModalImportBilletModelComponent,
      cssClass: 'modal-alert-class-file',
      componentProps: {},
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public downloadBatchBillsModel(fileFormat: FileFormatEnum): void {
    switch (fileFormat) {
      case FileFormatEnum.XLSX:
        this.billetService
          .getbatchBillsModel$(fileFormat)
          .pipe(
            tap((blob) => {
              saveAs(
                new Blob([blob]),
                `modelo-boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.xlsx`
              );
            })
          )
          .subscribe();
        break;
    }
  }
}
