import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from '@ionic/angular';

export interface ListData {
  name: string;
  value: string;
}

@Component({
  selector: 'app-modal-transfer-resume',
  templateUrl: './modal-details-dda.component.html',
  styleUrls: ['./modal-details-dda.component.scss'],
})
export class ModalDetailsDdaComponent {
  @Input() public data: ListData[];

  constructor(
    public modalController: ModalController,
  ) {
  }

  public continue() {
    this.modalController.dismiss();
  }
}
