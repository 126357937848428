import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SplitItemDto, StatusStepConclusionEnum } from '@wlp/ui-px-split';
import { TypeOperation } from '../../pages/cp-manager/split/split-auction/split-details/split-details.page';
import { MaskService } from '@wlp/ui-bs-util';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';

@Component({
  selector: 'app-modal-split-details',
  templateUrl: './modal-split-details.component.html',
  styleUrls: ['./modal-split-details.component.scss'],
})
export class ModalSplitDetailsComponent implements OnInit {

  @Input() splitItem: SplitItemDto;
  @Input() operationType: TypeOperation;
  public progressTextStatus: string;

  constructor(
    public modalController: ModalController,
    private maskService: MaskService,
    private translate: UiBsTranslateService,
  ) { }

  ngOnInit() {}

  public closeModal() {
    this.modalController.dismiss()
  }

  public formatCnpjCpf(document: string) {
    return this.maskService.formatCnpjCpf(document);
  }

  public formatTypeableLine(typeableLine: string) {
    if (!typeableLine) return '';
    const formattedLine = typeableLine.substring(0, 5) + '.' + typeableLine.substring(5, 10) + ' ' +
      typeableLine.substring(10, 16) + '.' + typeableLine.substring(16, 22) + ' ' +
      typeableLine.substring(22, 27) + '.' + typeableLine.substring(27, 33) + ' ' +
      typeableLine.substring(33, 34) + ' ' +
      typeableLine.substring(34, 47);
    return formattedLine;
  }

  public setStepByStatus(status: StatusStepConclusionEnum) {
    switch (status) {
      case StatusStepConclusionEnum.EFFECTED:
        this.progressTextStatus = this.translate.applyTranslate("SPLIT.AUCTION.DETAILS_PAGE.MODALS.SPLIT_DETAILS.STEPPER_STATUS.EFFECTED");
        return 'stepItemContent effected';

      case StatusStepConclusionEnum.PENDING:
        this.progressTextStatus = this.translate.applyTranslate("SPLIT.AUCTION.DETAILS_PAGE.MODALS.SPLIT_DETAILS.STEPPER_STATUS.PENDING");
        return 'stepItemContent pending';

      default:
        return 'stepItemContent';
    }
  }
}
