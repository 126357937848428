import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertController, ModalController, NavController, ToastController } from '@ionic/angular';
import { SendCodeService } from '@wlp/ui-bs-device-validation';
import { AccountPaymentTypeResponse, AccountTypeService, CreateAccountService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UiCSendCodeComponent } from '@wlp/ui-c-send-code';
import { interval, of } from 'rxjs';
import { catchError, map, takeWhile } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'ui-t-modal-validation',
  templateUrl: './modal-validation.component.html',
  styleUrls: ['./modal-validation.component.scss'],
})
export class ModalValidationComponent implements OnInit {

  @Input() public form: FormControl;
  @Input() public step: any;
  @Input() public data?: any;

  public status: any;
  public username: any;
  public typeflow: any;
  public typeflowOne: any;
  public typeflowTwo: any;
  public firstname: string;
  public listAccountType: AccountPaymentTypeResponse[];

  @ViewChild('cd', { static: false }) public sendCode: UiCSendCodeComponent;
  formSendCode: FormGroup;
  errorMessages: any;
  deviceService: any;
  isDisabled: boolean = false;
  isCountLimitSms: boolean = false;
  sub: any;
  progressbarValue: number;
  formDevice: any;
  showGroupButtons: boolean;
  count: number = 0;
  countLimitSms: number = 1;
  defaultType = "PHONE"
  typeValidation: any;
  param: { typeValidation: any; };
  paramTime: { time: any } = { time: 60 };
  timeEmail = 10;
  timeShow = 0;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountTypeService: AccountTypeService,
    private serviceCreateAccount: CreateAccountService,
    private sendCodeService: SendCodeService,
    public toastController: ToastController,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
  ) { }

  ngOnInit() {
    this.formSendCode = this.sendCodeService.getForm();
    this.errorMessages = this.sendCodeService.getErrorFormMessage();
    this.startProgress();
    this.accountTypeService.getTemporaryTypeAccount().then(type => {
      this.typeflow = type;
    });

    if (!this.data.isFirtsAccess) {
      this.defaultType = 'EMAIL';
      this.paramTime.time = (60 * this.timeEmail);
      this.typeValidation = this.form.get('email').value;
      this.param = { typeValidation: this.typeValidation }
    } else {
      this.typeValidation = this.form.get('phone').value;
      this.param = { typeValidation: this.typeValidation }
    }
  }

  startProgress() {
    // console.log('isFirtsAccess:: ', this.data.isFirtsAccess, ' - timeShow:: ', this.timeShow, ' - countLimitSms:: ', this.countLimitSms);
    this.progressbarValue = 1;
    this.count = 0;
    if (this.defaultType == 'EMAIL') {
      // console.log('startProgress::email::start ', this.paramTime.time );
      this.paramTime.time = (60 * this.timeEmail);
      // console.log('startProgress::email::end ', this.paramTime.time );
    } else {
      this.paramTime.time = 60;
      // console.log('startProgress::sms::start ', this.paramTime.time );
    }

    this.timeShow = this.paramTime.time;

    this.sub = interval(1000)
      .pipe(
        takeWhile(() => this.paramTime.time > 0),
        map(() => {
          this.paramTime.time--;
          return { time: this.paramTime.time };
        }),
      )
      .subscribe(progress => {
        this.paramTime = progress;
        this.count++;
        // this.progressbarValue = 1 - (this.count * 20) / 1200;
        if (this.paramTime.time === 0) {
          this.sub.unsubscribe();
        }
      });
  }

  public formatedTime(s) {
    const dateTemp = new Date(null);
    dateTemp.setSeconds(s);
    return dateTemp;
  }

  restratProgress() {
    this.countLimitSms++;
    if (this.countLimitSms <= 3 && !this.data.isFirtsAccess) {
      this.typeValidation = this.form.get('phone').value;
      this.param = { typeValidation: this.typeValidation };
      this.sendCodeValidation('PHONE');
      this.defaultType = 'PHONE';
    } else {
      this.resendCode();
    }
    this.sub.unsubscribe();
    this.startProgress();
  }

  resendCode() {
    this.serviceCreateAccount.resendCode(this.data._id, environment.appVersion)
      .subscribe(res => {
        this.presentToast(this.translate.applyTranslate('ONBORDING.MODAL_VALIDATION.MESSAGES.RESEND_CODE'));
      }),
      catchError((err) => {
        this.serviceCreateAccount.getErrorPreAccount(err)
        return of(null);
      });
  }

  formSendCodeEmitter(form) {
    this.serviceCreateAccount.validationCode(this.data._id, this.defaultType, form)
      .subscribe(res => {
        if (res.valid) {
          this.modalController.dismiss(res.valid);
        } else {
          this.presentToast(this.translate.applyTranslate('ONBORDING.MODAL_VALIDATION.MESSAGES.FAILED'))
        }
      });
  }

  sendCodeValidation(type: string) {
    if (this.countLimitSms >= 3 || !this.data.isFirtsAccess) {
      this.defaultType = type;
      this.countLimitSms++;
      // this.isCountLimitSms = true;
      this.serviceCreateAccount.sendCode(this.data._id, type, this.step)
        .subscribe(res => {
          this.presentToast(this.translate.applyTranslate('ONBORDING.MODAL_VALIDATION.MESSAGES.RESEND_CODE'));
          this.sub.unsubscribe();
          this.startProgress();
        }),
        catchError((err) => {
          this.serviceCreateAccount.getErrorPreAccount(err);
          return of(null);
        });
    }
  }

  gotoLogin() {
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  changeText() {
    if (this.data.isFirtsAccess && this.countLimitSms <= 3) {
      return 'ONBORDING.MODAL_VALIDATION.TITLE';
    } else if (this.countLimitSms > 3) {
      this.param = { typeValidation: this.form.get('email').value };
      return 'ONBORDING.MODAL_VALIDATION.TITLE_THIRD_TIME';
    } else {
      this.param = { typeValidation: this.form.get('email').value };
      return 'ONBORDING.MODAL_VALIDATION.TITLE_THIRD_TIME';
    }
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.applyTranslate('ONBORDING.VALIDATION_PHONE.MESSAGES.FAILED_TITLE'),
      message: this.translate.applyTranslate('ONBORDING.VALIDATION_PHONE.MESSAGES.FAILED'),
      buttons: [this.translate.applyTranslate('ONBORDING.VALIDATION_PHONE.MESSAGES.CONFIRM_BUTTON')],
    });
    await alert.present();
  }

}
