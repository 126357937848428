
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { ExtractDigitalAccountResponse, ExtractDigitalAccountService, ExtractService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { ExtractDataFilterPipe } from '../extract-data-filter.pipe';
import { ExtractUtil } from '../extract-util';
import { ExtractUtilInterface } from '../extract-util.interface';
import * as FileSaver from "file-saver";
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ui-t-extract-digital-account',
  templateUrl: './extract-digital-account.page.html',
  styleUrls: ['./extract-digital-account.page.scss'],
  providers: [ExtractDataFilterPipe],
})
export class ExtractDigitalAccountPage extends ExtractUtil implements ExtractUtilInterface, OnInit {

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  description: string;
  title: string;
  tableFuture: boolean;

  private extractColors: any;


  constructor(
    public pipeFilter: ExtractDataFilterPipe,
    public detection: ChangeDetectorRef,
    public modalController: ModalController,
    private extractDigitalAccountService: ExtractDigitalAccountService,
    private dashboardClientService: ExtractService,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    public activatedRoute: ActivatedRoute,
    private translate: UiBsTranslateService,
    private navCtrl: NavController,
    public loadingService: LoadingService,
  ) {
    super(userInfoService, pipeFilter, detection, modalController, loadingService);
  }

   public async ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.prepareColorExtrac();
    await this.loadUser();

    this.listStatment = [];
    this.listFuture = [];
    await this.activatedRoute.params
     .subscribe((params) => {
       if (params.type === 'current') {
         this.getDigitalStatement();
         this.title = 'CASH_OUT.EXTRACT.HEADER.TITLE';
         this.description = 'CASH_OUT.EXTRACT.HEADER.SUBTITLE';
       }
       if (params.type === 'future') {
         this.getDigitalStatementFuture();
         this.tableFuture = true;
         this.title = 'CASH_OUT.EXTRACT.HEADER.TITLE_SCHEDULED';
         this.description = 'CASH_OUT.EXTRACT.HEADER.SUBTITLE_SCHEDULED';
       }

       this.tpVoucher = params.type;
       this.getReceipts(this.tpVoucher);
     })
     .unsubscribe();
  }

  public getDigitalStatement(initial?: Date) {
    if (initial === undefined) {
      initial = this.calInitialDate;
    }
    this.dateExtractInit = moment(initial);
    this.dateExtractEnd = moment(this.calEndDate);
    // console.log('getDigitalStatement::',this.userProfile);
    this.extractDigitalAccountService
      .getStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, initial, this.calEndDate)
      .subscribe(
        (res) => {
          this.listStatment = res;
          // atualiza o front da tabela
          this.setArrayForShow(true);
          this.calcPagination();
        },
        (err) => console.error('Erro', err),
        () => this.loadingService.hide(),
      );
  }

  public getDigitalStatementFuture() {
    this.extractDigitalAccountService
      .getStatementFuture(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .subscribe(
        (res) => {
          this.listFuture = res;
          this.listFuture = this.listFuture.sort((a, b) => {
            return (a.dateRegister > b.dateRegister) ? 1 : ((b.dateRegister > a.dateRegister) ? -1 : 0);
          });
          
          // atualiza o front da tabela
          this.setArrayForShowFuture(true);
          this.calcPaginationFuture();
        },
        (err) => console.error('Erro', err)
      );
  }

  public valueDisplayCreditOrDebit(extractDigitalAccount: ExtractDigitalAccountResponse): number {
    return this.extractDigitalAccountService.valueDisplayCreditOrDebit(extractDigitalAccount);
  }

  showIsBalance(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsBalance(typeStatement);
  }

  showIsCreditOrDebit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsCreditOrDebit(typeStatement);
  }

  isCredit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.isCredit(typeStatement);
  }

  /**
   * apresentacao da data na tabela
   * @param date
   */
  public getHourDate(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }
  public downloadStatement(format) {
    switch (format) {
      case 'xlsx':
        this.dashboardClientService.downloadStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount,
          this.calInitialDate, this.calEndDate, format).subscribe(res => {
          const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
          FileSaver.saveAs(blob, `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.xlsx`);
        });
        break;
      case 'ofx':
        this.dashboardClientService.downloadStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount,
          this.calInitialDate, this.calEndDate, format).subscribe(res => {
          FileSaver.saveAs(new Blob([res]),
            `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.ofx`);
        });
        break;
      case 'pdf':
        this.dashboardClientService.downloadStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount,
          this.calInitialDate, this.calEndDate, format,
          this.extractColors.creditColor.replace('#', ''), this.extractColors.debitColor.replace('#', '')).subscribe(res => {
          this.dashboardClientService.downloadStatementPdf(res.content).subscribe(res => {
            FileSaver.saveAs(new Blob([res]),
              `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
          });
        });
        break;
    }
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  prepareColorExtrac() {
    this.extractColors = {
      creditColor: '#006400',
      debitColor: '#ff0000'
    }
    if (environment.wlThemeParameterization.wlTheme.global.extractColors != undefined) {
      this.extractColors = environment.wlThemeParameterization.wlTheme.global.extractColors;
    }
  }
}
