import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ContractEcService } from '@wlp/ui-bs-ec';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';

@Component({
  selector: 'ui-t-contract-ec',
  templateUrl: './contract-ec.page.html',
  styleUrls: ['./contract-ec.page.scss'],
})
export class ContractEcPage {
  wlName: string;
  wlCompany: string;
  contractEcForm: FormGroup;
  isDisabled: boolean = true;
  agreementAccepted: boolean = false;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private contractEcService: ContractEcService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.isDisabled = false;
    this.agreementAccepted = false;
    this.contractEcForm = this.contractEcService.getContractForm();
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.wlCompany = this.layoutConfigService.getConfig('wlTheme.company');
  }

  goBack() {
    this.navCtrl.navigateRoot('onboarding-ec/comercial-agent-ec');
  }
  continue() {
    // this.contractEcService
    //   .sendContract(this.contractEcForm)
    //   .subscribe(
    //     data => this.navCtrl.navigateRoot('onboarding-ec/status-response-ec'),
    //   );
    this.navCtrl.navigateRoot('onboarding-ec/status-response-ec');
  }

  acceptContract(event) {
    this.isDisabled = event.target.checked;
  }

  goPrivacyPolicy() {
    this.navCtrl.navigateRoot('onboarding-ec/privacy-policy-ec');
  }
}
