import { BilletLotListPage } from './billet-lot-list/billet-lot-list.page';
import { Routes, RouterModule } from '@angular/router';
import { BilletCreatePage } from './billet-create/billet-create.page';
import { BilletEmitPage } from './billet-emit/billet-emit.page';
import { BilletLotPage } from './billet-lot/billet-lot.page';
import { BilletLotPageV2 } from './billet-lot-v2/billet-lot-v2.page';

const routes: Routes = [
  { path: 'v1/billet-lot', component: BilletLotPage },
  { path: 'billet-create/:type', component: BilletCreatePage },
  { path: 'v1/billet-emit', component: BilletEmitPage },
  { path: 'billet-lot-list', component: BilletLotListPage },
  { path: 'v2/lots', component: BilletLotPageV2 },
];

export const BillingRoutes = RouterModule.forChild(routes);
