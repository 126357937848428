import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { MaskService } from '@wlp/ui-bs-util';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-transfer-on-us.component.html',
  styleUrls: ['./modal-transfer-on-us.component.scss'],
})
export class ModalTransferOnUsComponent {

  public accountList: Array<any>;
  public backgroundHeader: string;
  public favorite: boolean;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private maskService: MaskService,
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.backgroundHeader = this.layoutConfigService.getConfig('wlTheme.header.background.image');
  }

  public getInitial(name: string) {
    const parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      } else {
        return '--';
      }
    }
    return initials;
  }

  public formatAccountBank(account: any) {
    if(!!account) {
      return this.maskService.formatAccountBank(account);
    } else {
      return '';
    }
  }

  async dismissModal(item?: any) {
    if (!!item) {
      this.modalController.dismiss(item);
    } else if(item === false) {
      this.modalController.dismiss(false);
    } else {
      this.modalController.dismiss(null);
    }
  }
}
