import {SafeHtml} from '@angular/platform-browser';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-extract-review.component.html',
  styleUrls: ['./modal-extract-review.component.scss'],
})
export class ModalExtractReviewComponent {
  @Input() public confirmationData: any;
  @Input() public title: any;
  @Input() public scheduling: any;
  @Input() public isEmittedVoucher:boolean;
  @Input() public voucherHTML: SafeHtml = '';
  @Input() public redirect: string;

  public confirmation: any;
  public brand: string;
  public voucher: string;

  constructor(
    public modalController: ModalController,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    private navCtrl: NavController,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.billet.brand.logo');
    this.loadingService.show();

    setTimeout(() => {
      this.loadingService.hide();
      this.confirmation = this.confirmationData;
    }, 2000);
  }

  public voucherChoose(code) {
    switch (code) {
      case '112':
      case '122':
      case '123':
      case '902':
        return 'Payment';
      case '905':
        return 'Recharge';
      case '901':
      case '124':
      case '117':
      case '120':
      case '121':
      case '919':
      case '918':
        return 'Transfer';
      default:
        return 'Payment';
    }
  }

  public isShowBrand(code){
    if (!this.isEmittedVoucher) {
      return true;
    } else {
      return code === '902';
    }
  }

  async dismiss() {
    this.modalController.dismiss();

    if (!!this.redirect) {
      this.navCtrl.navigateRoot(this.redirect);
    }
  }

  showLoad(contentToPDF) {
    this.loadingService.show();

    if (contentToPDF) {
      this.loadingService.hide();
    }

  }
}
