import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modal-self-photo',
  templateUrl: './modal-self-photo.component.html',
  styleUrls: ['./modal-self-photo.component.scss']
})
export class ModalSelfPhotoComponent implements OnInit {
  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
  ) {}

  ngOnInit() {}

  public getImageTag() {
    let item: any = this.layoutConfigService.getImagesPages('warning-circle');
    return item.path;
  }

  confirm(confirm: boolean) {
    this.modalController.dismiss(confirm);
  }
}
