import {CurrencyPipe, DatePipe} from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ExtractService, TransactionScheduleService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { Paginated, PaginationParams } from '@wlp/ui-bs-parameter';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import {TransactionConsult, TransactionScheduleDTO, AdvancedFilterTransactionScheduleDTO} from '@wlp/ui-px-cashout';
import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalExtractReviewComponent } from 'src/app/views/themes/sentinel/modals/modal-extract-review/modal-extract-review.component';
import { ModalAlertComponent } from './../../../../../modals/modal-alert/modal-alert.component';
import { ExtractDataFilterPipe } from './../extract-data-filter.pipe';
import { ExtractUtil } from './../extract-util';
import { ExtractUtilInterface } from './../extract-util.interface';
import * as FileSaver from 'file-saver';

interface ExtendedTransactionScheduleDTO extends TransactionScheduleDTO {
  description: string;
  operationDescription: string;
  value: number;
  isItemSelected: boolean;
}
@Component({
  selector: 'ui-t-list-scheduling',
  templateUrl: './list-scheduling.page.html',
  styleUrls: ['./list-scheduling.page.scss'],
  providers: [ExtractDataFilterPipe, CurrencyPipe],
})
export class ListSchedulingPage extends ExtractUtil implements ExtractUtilInterface, OnInit {
  public userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public description: string;
  public scheduling: boolean;

  public dateInit: Date;
  public dateEnd: Date;
  public searchbarValue: string;
  public btnDay: number;

  public itemsPerPageOptions: number[] = [10, 20, 30, 50, 100];
  public itemsPerPage = 10;
  public iconSkipLast = './assets/icon/icon-material-skip-last.svg';
  public iconNavigateLast = './assets/icon/icon-material-navigate-last.svg';
  public iconNavigateNext = './assets/icon/icon-material-navigate-next.svg';
  public iconSkipNext = './assets/icon/icon-material-skip-next.svg';

  private scheduled: ExtendedTransactionScheduleDTO[] = [];
  private scheduledPaginated: Paginated<TransactionScheduleDTO[]> = null;
  public isParentSelected: boolean = false;
  public availableScheduledCounter  : number = 0;
  public selectedSchedulesCounter: number = 0;
  public selectedSchedules: string[] = [];

  constructor(
    public pipeFilter: ExtractDataFilterPipe,
    public pipeCurency: CurrencyPipe,
    public detection: ChangeDetectorRef,
    protected userInfoService: UserInfoService,
    protected extractService: ExtractService,
    public modalController: ModalController,
    private navCtrl: NavController,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    public loadingService: LoadingService,
    private transactionScheduleService: TransactionScheduleService,
  ) {
    super(userInfoService, pipeFilter, detection, modalController, loadingService);
  }
  public async ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    await this.loadUser();
    this.getSchedulingPaginated();
  }

  public receiptSearchbarValue(event: string) {
    this.searchbarValue = event;
  }

  public async receiptDate(event: Event): Promise<void> {
    if (typeof event === 'number') {
      this.btnDay = event;
      if (this.btnDay === 7) {
        this.dateExtractInit = moment().subtract(7, 'days');
        this.dateExtractEnd = moment();
        this.resetSelectedSchedules();
        const paginationParams = new PaginationParams(0, this.itemsPerPage);
        await this.getSchedulingPaginated(
          new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
          new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
          paginationParams);
      }
      if (this.btnDay === 15) {
        this.dateExtractInit = moment().subtract(15, 'days');
        this.dateExtractEnd = moment();
        this.resetSelectedSchedules();
        const paginationParams = new PaginationParams(0, this.itemsPerPage);
        await this.getSchedulingPaginated(
          new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
          new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
          paginationParams);
      }
      if (this.btnDay === 30) {
        this.dateExtractInit = moment().subtract(30, 'days');
        this.dateExtractEnd = moment();
        this.resetSelectedSchedules();
        const paginationParams = new PaginationParams(0, this.itemsPerPage);
        await this.getSchedulingPaginated(
          new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
          new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
          paginationParams);
      }
    } else {
      this.dateExtractInit = this.dateInit !== undefined ? this.dateInit : this.dateExtractInit;
      this.dateExtractEnd = this.dateEnd !== undefined ? this.dateEnd : this.dateExtractEnd;
      this.resetSelectedSchedules();
      const paginationParams = new PaginationParams(0, this.itemsPerPage);
      await this.getSchedulingPaginated(
        new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
        new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
        paginationParams);
    }
  }
  public async getSchedulingPaginated(startDate?: string, endDate?: string, paginationParams?: PaginationParams) {
    this.loadingService.show();

    if (!startDate && !endDate) {
      this.dateExtractInit = moment().subtract(7, 'days');
      this.dateExtractEnd = moment();
      startDate = this.dateExtractInit.format('YYYY-MM-DD');
      endDate = this.dateExtractEnd.format('YYYY-MM-DD');
    }
    
    if (!paginationParams) {
      paginationParams = new PaginationParams(1, this.itemsPerPage);
    }
    try {
      this.scheduledPaginated = await this.extractService.findScheduledPaginated(
        startDate,
        endDate,
        this.searchbarValue,
        paginationParams,
      ).toPromise();
      this.scheduled = this.scheduledPaginated.data as ExtendedTransactionScheduleDTO[];
      this.scheduled.forEach(obj => {
        obj.isItemSelected = false;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.hide();
      this.cdr.detectChanges();
    }
  }

  private async modalNotification(status: string, message: string, confirmation?: boolean, uuid?: string) {
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status,
        message,
        confirmBtn: 'CASH_OUT.LIST_SCHEDULING.MODAL.BTN_YES',
        cancelBtn: 'CASH_OUT.LIST_SCHEDULING.MODAL.BTN_NO',
        confirmation,
        translate: true,
      },
    });
    modal.onDidDismiss().then((callback) => {
      if (callback.data === true) {
        this.deleteScheduling(uuid);
      }
    });
    return await modal.present();
  }

  public confirmDelete(uuid: string) {
    this.modalNotification('warning-circle', 'CASH_OUT.LIST_SCHEDULING.MODAL.TITLE', true, uuid);
  }

  public async deleteScheduling(uuid: string) {
    await this.transactionScheduleService.deleteTransactionSchedule(this.userProfile.uuidAccount, uuid).subscribe((res) => {
      this.getSchedulingPaginated();
      this.cdr.detectChanges();
    });
  }

  public getScheduled(transactionId: string) {
    this.extractService.getTransaction(transactionId).subscribe((res) => {
      if (res.scheduled === true) {
        this.scheduling = true;
        this.showExtract(res, 'CASH_OUT.TRANSFER_RECEIPT.HEADER.TITLE_SCHEDULING', true);
      } else {
        this.showExtract(res, 'CASH_OUT.TRANSFER_RECEIPT.HEADER.SUBTITLE');
      }
    });
  }

  public async showExtract(data: TransactionScheduleDTO | TransactionConsult, title: string, scheduling?: boolean) {
    const modal = await this.modalController.create({
      component: ModalExtractReviewComponent,
      cssClass: 'extract-review',
      componentProps: {
        confirmationData: data,
        title,
        scheduling,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
  private typeTransfer(code) {
    switch (code) {
      case 'SCHEDULED':
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.SCHEDULED';
      case 'PROCESSING':
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.PROCESSING';
      case 'AUTHORIZED':
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.AUTHORIZED';
      case 'NOT_AUTHORIZED':
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.NOT_AUTHORIZED';
      case 'PROCESSED':
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.PROCESSED';
      default:
        return 'CASH_OUT.LIST_SCHEDULING.STATUS.DEFAULT';
    }
  }

  /* pagination begin */
  public async handleChangeItemsPerPage(itemsPerPage: number) {
    this.resetSelectedSchedules();
    this.itemsPerPage = itemsPerPage;
    const paginationParams = new PaginationParams(0, itemsPerPage);
    await this.getSchedulingPaginated(
      new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
      new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
      paginationParams);
  }
  public async handleGoToFirstPage() {
    this.resetSelectedSchedules();
    const paginationParams = new PaginationParams(1, this.itemsPerPage);
    await this.getSchedulingPaginated(
      new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
      new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
      paginationParams);
  }
  public async handleGoToLastPage() {
    this.resetSelectedSchedules();
    const lastPage = this.scheduledPaginated ? this.scheduledPaginated.pagination.totalPages : 0;
    const paginationParams = new PaginationParams(lastPage, this.itemsPerPage);
    await this.getSchedulingPaginated(
      new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
      new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
      paginationParams);
  }
  public async handleGoToNextPage() {
    this.resetSelectedSchedules();
    const next = this.scheduledPaginated ? this.scheduledPaginated.pagination.page + 1 : 0;
    const paginationParams = new PaginationParams(next, this.itemsPerPage);
    await this.getSchedulingPaginated(
      new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
      new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
      paginationParams);
  }
  public async handleGoToPrevPage() {
    this.resetSelectedSchedules();
    const prev = this.scheduledPaginated ? this.scheduledPaginated.pagination.page - 1 : 0;
    const paginationParams = new PaginationParams(prev, this.itemsPerPage);
    await this.getSchedulingPaginated(
      new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd'),
      new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd'),
      paginationParams);
  }
  /* pagination end */
  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public selectAllSchedules () {
    setTimeout(() => {
      this.scheduled.forEach(scheduled => {
        if (scheduled.status != 'AUTHORIZED' && scheduled.isEmittedVoucher) {
          scheduled.isItemSelected = this.isParentSelected;
        }
      });
    }, 1)
  }

  public updateCheckboxState() {
    this.selectedSchedulesCounter = this.scheduled
      .filter(scheduled => scheduled.isEmittedVoucher && scheduled.status !== 'AUTHORIZED' && scheduled.isItemSelected).length;

    this.availableScheduledCounter = this.scheduled
      .filter(scheduled => scheduled.isEmittedVoucher && scheduled.status !== 'AUTHORIZED').length;

    this.isParentSelected = this.selectedSchedulesCounter === this.availableScheduledCounter;

    this.selectedSchedules = this.scheduled
      .filter(scheduled => scheduled.isEmittedVoucher && scheduled.isItemSelected && scheduled.status !== 'AUTHORIZED')
      .map(scheduled => scheduled.transactionId);
  }

  public downloadSchedules(){
    this.loadingService.show();

    if (this.isParentSelected) {

      const startDate: string = new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd');
      const endDate: string = new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd');

      const filter: AdvancedFilterTransactionScheduleDTO = {
        uuidAccountPayment: this.userProfile.uuidAccount,
        startDate: startDate,
        endDate: endDate,
      }

      this.extractService.findScheduledVoucherTemplatesByFilter(filter).subscribe((res) => {
        let allTemplates = res.join('\n\n');
        return this.saveVoucherDownloadedFile(allTemplates);
      });

    } else {

      this.extractService.findTemplateVoucherByTransactionsId(this.selectedSchedules).subscribe((res) => {
        let allTemplates = '';
        for (let i = 0; i < res.length; i++) {
          if (i > 0) {
            allTemplates += '\n\n';
          }
          allTemplates += res[i].emittedVoucher;
        }
        this.saveVoucherDownloadedFile(allTemplates)
      });

    }

  }

  private saveVoucherDownloadedFile(content: string) {
    return this.extractService.downloadStatementPdf(content).subscribe((res) => {
      this.loadingService.hide();
      const currentDate = new Date();
      const fileName = `Comprovante-de-Agendamento-${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getUTCFullYear()}.pdf`;
      FileSaver.saveAs(new Blob([res]), fileName);
    });
  }

  public resetSelectedSchedules() {
    this.selectedSchedules = [];
    this.isParentSelected = false;
    this.availableScheduledCounter = 0;
    this.selectedSchedulesCounter = 0;
  }
}
