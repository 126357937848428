import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billetListDataFilter',
})
export class BilletsListDataFilterPipe implements PipeTransform {

  private PENDING = 'PENDING';
  private AVAILABLE = 'AVAILABLE';
  private IN_VALIDATION = 'IN_VALIDATION';
  private IN_PROCESS = 'IN_PROCESS';
  private DRAFT = 'DRAFT';

  transform(names: any[], terms: string): any[] {
    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    terms = terms.split('/').reverse().join('-');
    terms = terms.replace(',', '.');
    if (terms.length === 8) {
      let split = terms.split('');
      split.splice(0, 2)
      terms = split.join('');
    } else {
      return names.filter((it) => {
        return JSON.stringify(it).toLowerCase().includes(terms);
      });
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

  transformlot(names: any[], terms: string): any[] {
    if (terms.includes("pend")) {      
      terms = this.PENDING.toLowerCase();
    }
    if (terms.includes("disp")) {
      terms = this.AVAILABLE.toLowerCase();
    }
    if (terms.includes("em vali")) {
      terms = this.IN_VALIDATION.toLowerCase();
    };

    if (terms.includes("em pro")) {
      terms = this.IN_PROCESS.toLowerCase();
    };   

    if (terms.includes("ras")) {
      terms = this.DRAFT.toLowerCase();
    };

    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    terms = terms.split('/').reverse().join('-');
    terms = terms.replace(',', '.');
    if (terms.length === 8) {
      let split = terms.split('');
      split.splice(0, 2)
      terms = split.join('');
    } else {
      return names.filter((it) => {
        return JSON.stringify(it).toLowerCase().includes(terms);
      });
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

}
