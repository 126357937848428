import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'modal-account-activation',
  templateUrl: './modal-account-activation.component.html',
  styleUrls: ['./modal-account-activation.component.scss'],
})
export class ModalAccountActivationComponent {

  @Input() title: string;
  @Input() message: string;
  @Input() button: string;
  @Input() isValid: boolean;

  constructor(
    public modalController: ModalController,
    private navCtrl: NavController
  ) { }

  dismiss() { }

  confirm() {
    this.modalController.dismiss().then(
      (res) => {
        if (this.isValid) {
          this.navCtrl.navigateRoot('/').then(
            (res) => {
              // console.log(res);
            },
            (err) => {
              // console.error(err);
            }
          );
        }
      },
      (err) => {
        // console.error(err);
      }
    );
  }
}
