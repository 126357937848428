import { ModalAlertDocsComponent } from './../../../../../../modals/modal-alert-docs/modal-alert-docs.component';
import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { AccountTypeService, FileAccount, PreAccountResponse, UploadService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { Subject } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalViewFileUpload } from 'src/app/views/themes/sentinel/modals/modal-view-file-upload/modal-view-file-upload.component';

@Component({
  selector: 'ui-t-documents-partner-modal',
  templateUrl: './personal-upload.modal.html',
  styleUrls: ['./personal-upload.modal.scss'],
})
export class PersonalUploadModal {
  public listDocuments = {
    data: [],
    requiredFile: false,
  };
  public name: string;
  public title: string;
  public description: string;

  public primaryColor: string;
  public secondaryColor: string;

  public visible: boolean;
  public isPhoto: boolean;
  public isUpload: boolean;
  public fileAccount: FileAccount;
  public isPhysical: boolean = false;
  public isPartnerMei: boolean;

  private file: any;

  private destroy$: Subject<null> = new Subject();

  @Input() public partnerId: string;
  @Input() public partner: any;
  isImage: boolean;

  private isRG: boolean = false;
  private rgFront: boolean = false;

  constructor(
    public alertController: AlertController,
    private navCtrl: NavController,
    private uploadService: UploadService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private activeParams: ActivatedRoute,
    private loadServide: LoadingService,
    private modalActive: ModalController,
  ) { }

  public async ngOnInit() {
    this.loadServide.show();
    this.isPhysical = true;
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.name = await this.service.getName();
    this.title = 'ONBORDING.V2.TEXT.TITLE.MODAL_UPLOAD_PARTNERS_TITLE';
    this.description = 'ONBORDING.V2.TEXT.TITLE.MODAL_UPLOAD_PARTNERS_DESCRIPTION';

    await this.changeListDocumentsToUpload();
    this.loadServide.hide();
  }

  private async changeListDocumentsToUpload(router?: boolean) {
    if (this.isRG) {
      this.listDocuments = {
        data: [{
          document: "RG",
          image: "rg",
          imageTag: "wlp-icon-onboarding-rg",
          isTwoSides: true,
          position: this.rgFront ? 'VERSE' : 'FRONT',
          requiredFile: true,
          text: "ONBORDING.CHOOSE_DOCUMENT.BUTTON_TEXT.RG.MESSAGE"
        }],
        requiredFile: true
      };
    } else {
      try {
        this.listDocuments = await this.uploadService.findDocumentsPartnerToUpload(this.partnerId, '/upload-documents-partner');
      } catch (err) {
        this.listDocuments = {
          data: [],
          requiredFile: false,
        };
      }
    }

    if (this.listDocuments.data.length < 1 && router) {
      this.goContinue();
    }

    this.listDocuments.data.forEach((el) => {
      el.imageTag = this.getImageTag(el.image);

      if (el.items) {
        el.items.forEach((item) => {
          item.forEach((il) => {
            il.imageTag = this.getImageTag(il.image);
          });
        });
      }
    });
    this.visible = true;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public async changeDocument(document: any) {
    if (document.document === "RESIDENCIAL_CERTIFICATE") {      
      const modal = await this.modalActive.create({
        component: ModalAlertDocsComponent,
        cssClass: 'modal-alert-docs',
        backdropDismiss: false,
      });
      await modal.present();
    }
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = document.document;
    this.fileAccount.positionPhoto = document.position;
    this.fileAccount.title = '';

    if (document.document === 'RG' && this.isRG === false) {
      this.isRG = true;
      if (document.position === 'FRONT') {
        this.rgFront = true;
      }
      if (document.position === 'VERSE') {
        this.rgFront = false;
      }
    } else {
      this.isRG = false;
    }
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.class;
  }

  public goBack() {
    this.modalActive.dismiss(null);
  }

  public async goContinue() {
    this.loadServide.show();
    if (this.file && (this.isPhoto || this.isUpload)) {
      this.fileAccount.fileBase64 = this.file.base64;
      this.fileAccount.title = this.file.file.name;

      await this.uploadService.uploadPartnerFile(this.fileAccount, this.partnerId);
      this.isPhoto = false;
      this.isUpload = false;

      await this.changeListDocumentsToUpload(true);
      this.loadServide.hide();
      this.visible = true;
      return;
    }

    this.modalActive.dismiss(true);
  }

  public removeFile() {
    this.file = null;
  }

  public getUploadFile({ data, isPhoto, isUpload }: any) {
    this.isPhoto = isPhoto;
    this.isUpload = isUpload;
    this.file = {
      base64: data && data.base64 ? data.base64 : data,
      file: {
        name: data && data.file && data.file.name ? data.file.name : 'nome_da_foto',
      },
    };
    if (isPhoto) {
      this.goContinue();
    }
  }

  public async alertDocumentLarge(size?: number) {
    if (!size) {
      size = 5;
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.TITLE', { size }),
      message: this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.MESS'),
      buttons: [this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.BUTTON')],
    });
    await alert.present();
  }

  showTypes(): string[] {
    const arrTypes = [
      'ONBORDING.V2.FORM.LABEL.MASTER',
      'ONBORDING.V2.FORM.LABEL.ATTORNEY',
      'ONBORDING.V2.FORM.LABEL.LEGAL_REPRESENTATIVE',
      'ONBORDING.V2.FORM.LABEL.PARTNER',
      'ONBORDING.V2.FORM.LABEL.SIGNATURE'
    ];
    return arrTypes.filter((arr, index) => (this.partner.types[index])).join(',').split(',');
  }

  public closeModal() {
    this.modalActive.dismiss(false);
  }

  public downloadPDF(pdf: any, name: string) {
    this.isImage = false;
    let type = pdf.split('/')[1].substr(0, 3);
    if (type === 'pdf') {
      this.viewImage(pdf, type)
    } else {
      this.isImage = true;
      this.viewImage(pdf, 'img');
    }
  }

  async viewImage(base64: string, type: string) {
    let file = {
      type: this.fileAccount.uploadType,
      nameFile: this.file.file.name,
      positionPhoto: this.fileAccount.positionPhoto
    }
    const modal = await this.modalActive.create({
      component: ModalViewFileUpload,
      cssClass: 'modal-alert-class-file',
      componentProps: {
        file,
        imageUrl: base64,
        type
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }
}
