import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { AccountPaymentFavoriteDTO, AccountPaymentFavoriteService, TypeEnum } from '@wlp/ui-bs-account-payment-favorite';
import { AccountBalanceService, BilletService, StatementService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalAlertComponent } from 'src/app/views/themes/sentinel/modals/modal-alert/modal-alert.component';
import {
  PopoverFavoritesComponent,
} from 'src/app/views/themes/sentinel/modals/popover-favorites/popover-favorites.component';

import { FavoritesDataFilterPipe } from './../../../cash-out/charging/favorites-data-filter.pipe';

@Component({
  selector: 'ui-t-billing-billet-create',
  templateUrl: './billet-create.page.html',
  styleUrls: ['./billet-create.page.scss'],
  providers: [FavoritesDataFilterPipe],
})
export class BilletCreatePage implements OnInit, OnDestroy {

  listExtract: any;
  userProfile: UserInfoProfile;
  public errorMessages: any;
  private favorite: any;
  public formFavorite: FormGroup;
  public listFavoritesShow: any;
  public listFavorites: any;
  public favoriteId: any;
  public reloadIcon: boolean;
  public reloadIconBalance: boolean;
  public debitMonth: number = null;
  public creditMonth: number = null;
  public accountBalance: string = null;
  public bankTransition: string = null;
  public toggleAccountBalance: boolean = false;
  public toggleBankTransition: boolean = false;
  public toggleSpinnerAccountBalance: boolean = false;
  public toggleSpinnerBankTransition: boolean = false;
  public selectedFavorite: boolean = false;
  public switchFavorites: string = 'favorites';
  public billetChargingValue: any;
  public isUp: boolean = false;
  toggleFavorite: any = false;
  public onCheckDocument: any
  public onChangePostalCode: any;
  public isOnUs: boolean;
  isMobile: boolean = false;
  public listUf: Array<any>;
  public step: number = 1;
  public payerData: any;
  public fineAndInterest: boolean = true;
  public discount: boolean = true;
  public discountList: Array<any> = [];

  constructor(
    private accountBalanceService: AccountBalanceService,
    public accountPaymentFavoriteService: AccountPaymentFavoriteService,
    private billetService: BilletService,
    private cdr: ChangeDetectorRef,
    private headerInfoService: UICHeaderUserInfoService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private platform: Platform,
    public popoverController: PopoverController,
    public pipeFilter: FavoritesDataFilterPipe,
    private statementService: StatementService,
    protected userInfoService: UserInfoService,
  ) { }

  public ngOnInit() {
    this.payerData = {
      name: 'Guilherme Henrique Santa Clara Costa',
      document: '132.123.321-32'
    };

    this.discountList.push({
      position: 1,
      type: 0,
      value: 0,
      limit: 0
    });
  }

  public ionViewWillEnter() {
    this.isMobile = this.platform.is('mobile');
    this.loadUser();
    this.headerInfoService.updateValue(true);
    this.formFavorite = this.accountPaymentFavoriteService.createFormFavoriteCharging();
    this.errorMessages = this.accountPaymentFavoriteService.getFormMessageError();
    this.listUf = this.accountPaymentFavoriteService.getUf();
  }

  private getFavorites() {
    this.loadingService.show();
    this.accountPaymentFavoriteService
      .getAllFavoritesType(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, TypeEnum.BILLET)
      .pipe(
        tap((data) => {
          this.loadingService.hide();
          // console.log('getFavorites', data);
          this.listFavoritesShow = this.accountPaymentFavoriteService.sortFavoritesListByName(data);
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
    this.loadingService.hide();
    this.cdr.detectChanges();
  }

  public segmentChanged(favorite: string) {
    if (favorite == 'favorites') this.getFavorites();
    this.switchFavorites = favorite;
  }

  public sortList() {
    this.isUp = !this.isUp;
    this.listFavoritesShow = this.listFavoritesShow.reverse();
  }

  public filterFavorites(value: string) {
    if (value !== '') {
      this.listFavoritesShow = this.pipeFilter.transform(this.listFavorites, value);
    } else {
      this.getFavorites();
    }
  }

  searchPostalCode(data: any) {
    this.accountPaymentFavoriteService.searchPostaCode(data.target.value);
  }

  public resetForm() {
    this.formFavorite = this.accountPaymentFavoriteService.createFormFavoriteCharging();
    this.favoriteId = null;
    this.switchFavorites = 'favorites';
    this.selectedFavorite = false;
  }

  public async editOrDelete(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverFavoritesComponent,
      cssClass: 'popover-class',
      event: ev.event,
      translucent: true,
      mode: 'md',
    });
    popover.onDidDismiss().then((data) => {
      if (data.data == 'exclude') {
        this.deleteFavorite(ev.favorite);
      }
      if (data.data == "edit") {
        this.editFavorite(ev.favorite);
      }
    });
    return await popover.present();
  }

  private deleteFavorite(favorite: any) {
    this.loadingService.show();
    this.accountPaymentFavoriteService.deleteFavorite(favorite).pipe(
      tap((data) => {
        if (data.status) {
          this.resetForm();
          this.getFavorites();
          this.statusNotification('success', 'FAVORITES.MESSAGES.DELETE_SUCCESS', true);
          this.favoriteId = undefined;
          this.switchFavorites = 'favorites';
        } else {
          this.statusNotification('warning-circle', 'FAVORITES.MESSAGES.FAILED', true);
        }
        this.loadingService.hide();
      }),
      catchError((_) => {
        this.loadingService.hide();
        return null;
      }),
    ).subscribe();
  }

  public editFavorite(favorite: any) {
    this.favoriteId = favorite.id;
    this.favorite = favorite;
    // console.log('edit favorite', favorite);
    this.formFavorite.setValue({
      name: favorite.name,
      document: favorite.document,
      email: favorite.email,
      postalCode: favorite.zipCode,
      address: favorite.address,
      numberAddress: favorite.addressNumber,
      complementAddress: favorite.addressComplement,
      neighborhood: favorite.neighborhood,
      cityAddress: favorite.city,
      uf: favorite.state,
      country: favorite.country
    });

    this.selectedFavorite = true;
    this.switchFavorites = 'new';
    // console.log('edit favorite', this.formFavorite);
  }

  private async statusNotification(status: string, message: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: message,
        confirmBtn: 'FAVORITES.BUTTONS.CONFIRM',
        translate: translate,
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }

  public onChangeForm() {
    (this.formFavorite);
  }

  public checkDocument() {
    this.onCheckDocument;
  }

  public onChangePostal(zipcode: any): void {
    this.onChangePostalCode.emit(zipcode);
  }

  changeFavorite() {
    this.toggleFavorite = !this.toggleFavorite;
  }

  public continue() {
    if (!!this.favoriteId && !!this.favorite.uuid) {
      this.favorite = {
        id: this.favoriteId,
        uuid: this.favorite.uuid,
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.formFavorite.controls.name.value,
        document: this.formFavorite.controls.document.value,
        email: this.formFavorite.controls.email.value,
        zipCode: this.formFavorite.controls.postalCode.value,
        address: this.formFavorite.controls.address.value,
        addressNumber: this.formFavorite.controls.numberAddress.value,
        addressComplement: this.formFavorite.controls.complementAddress.value,
        neighborhood: this.formFavorite.controls.neighborhood.value,
        city: this.formFavorite.controls.cityAddress.value,
        state: this.formFavorite.controls.uf.value,
        country: this.formFavorite.controls.country.value,
        type: 'BILLET'
      };
      // console.log('edita um cadastro', this.favorite);
      this.updateFavorite(this.favorite);
    } else {
      let prepare: AccountPaymentFavoriteDTO = {
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.formFavorite.controls.name.value,
        document: this.formFavorite.controls.document.value,
        email: this.formFavorite.controls.email.value,
        zipCode: this.formFavorite.controls.postalCode.value,
        address: this.formFavorite.controls.address.value,
        addressNumber: this.formFavorite.controls.numberAddress.value,
        addressComplement: this.formFavorite.controls.complementAddress.value,
        neighborhood: this.formFavorite.controls.neighborhood.value,
        city: this.formFavorite.controls.cityAddress.value,
        state: this.formFavorite.controls.uf.value,
        country: this.formFavorite.controls.country.value,
        type: TypeEnum.BILLET
      };
      //console.log('salva um novo', prepare);
      this.saveFavorite(prepare, this.toggleFavorite);
    }
  }

  private updateFavorite(favoriteBody: any) {
    this.loadingService.show();
    this.accountPaymentFavoriteService.updateFavorite(favoriteBody).pipe(
      tap((data) => {
        if (data.status) {
          this.statusNotification('success', 'FAVORITES.MESSAGES.SAVE_SUCCESS', true);
          this.getFavorites();
          this.resetForm();
          this.favoriteId = undefined;
          this.switchFavorites = "favorites";
        } else {
          this.statusNotification('warning-circle', 'FAVORITES.MESSAGES.FAILED', true);
        }
        this.loadingService.hide();
      }),
      catchError((_) => {
        this.loadingService.hide();
        this.statusNotification('warning-circle', 'FAVORITES.MESSAGES.FAILED', true);
        return of(null);
      }),
    ).subscribe();
  }

  private saveFavorite(favoriteBody: any, checked: any) {
    this.accountPaymentFavoriteService.saveFavorite(favoriteBody).pipe(
      tap((data) => {
        if (data.status) {
          this.statusNotification('success', 'FAVORITES.MESSAGES.SAVE_SUCCESS', true);
          this.getFavorites();
          this.resetForm();
          this.favoriteId = undefined;
          this.switchFavorites = 'favorites'
        } else {
          this.statusNotification('warning-circle', 'FAVORITES.MESSAGES.FAILED', true);
        }
        this.loadingService.hide();
      }),
      catchError((_) => {
        this.loadingService.hide();
        this.statusNotification('warning-circle', 'FAVORITES.MESSAGES.FAILED', true);
        return of(null);
      }),
    ).subscribe();
  }

  public showAccountValues(data) {
    if (data === 'balance') {
      this.toggleAccountBalance = !this.toggleAccountBalance;
      this.getAccountBalance();
    } else {
      this.toggleBankTransition = !this.toggleBankTransition;
      this.getBankTransition();
    }
  }

  public refreshAccountValues(data) {
    if (data === 'balance') {
      this.toggleSpinnerAccountBalance = true;
      this.getAccountBalance();
    } else {
      this.toggleSpinnerBankTransition = true;
      this.getBankTransition();
    }
  }

  public getAccountBalance(): void {
    this.headerInfoService.loadUser();
    this.accountBalanceService
      .getBalanceSubject()
      .pipe(
        tap((data) => {
          //console.log(data);

          if (this.toggleAccountBalance) this.accountBalance = !!data ? String(data) : '0';
          else this.accountBalance = null;
          setTimeout(() => {
            this.toggleSpinnerAccountBalance = false;
          }, 1500);
        })
      )
      .subscribe();
  }

  public getBankTransition(): void {
    this.statementService
      .getSumMoviments(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((response) => {
        if (this.toggleBankTransition) {
          this.debitMonth = !!response.debit ? response.debit : 0;
          this.creditMonth = !!response.credit ? response.credit : 0;
        } else {
          this.debitMonth = null;
          this.creditMonth = null;
        }
        setTimeout(() => {
          this.toggleSpinnerBankTransition = false;
        }, 1500);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        if (this.userProfile) {
          this.getFavorites();
        }
        this.loadStatement(user.uuidWhiteLabel, user.uuidAccount);
        this.getTaxBillet();
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private getTaxBillet() {
    this.billetService
      .getBilletCreateTax(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, '1002')
      .then((res) => {
        if (res) {
          this.billetChargingValue = res.value;
        }
      });
  }

  private loadStatement(uuidWL: string, uuidAccount: string): void {
    this.statementService
      .getLatestTransaction(uuidWL, uuidAccount, 6)
      .then((res) => {
        this.listExtract = res;
        setTimeout(() => {
          this.reloadIcon = false;
        }, 1500);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  public changeFineAndInterest() {
    this.fineAndInterest = !this.fineAndInterest;
  }

  public changeDiscount() {
    this.discount = !this.discount;
  }

  public addDiscount() {
    if (this.discountList.length < 3) {
      this.discountList.push({
        position: (this.discountList.length + 1),
        type: 0,
        value: 0,
        limit: 0
      });
    };

   // console.log(this.discountList);
  }

  public ngOnDestroy() {
    this.resetForm();
  }
}
