import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { ClientLimitRateService, UiBsCashOutModule } from '@wlp/ui-bs-cashout';
import { HeaderUserInfoModule } from '@wlp/ui-c-header-user-info';
import { UiBsDashboardModule } from '@wlp/ui-bs-dashboard';
import { UiBsLoginModule } from '@wlp/ui-bs-login';
import { UiBsMyDataModule } from '@wlp/ui-bs-my-data';
import { UiBsProsperaModule } from '@wlp/ui-bs-prospera';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiCAccountLimitsModule } from '@wlp/ui-c-account-limits';
import { UiCAsideMenuLeftModule } from '@wlp/ui-c-aside-menu-left';
import { UiCBarInfoModule } from '@wlp/ui-c-bar-info';
import { UiCCardInfoModule } from '@wlp/ui-c-card-info';
import { UiCDashboardHeaderModule } from '@wlp/ui-c-dashboard-header';
import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCModalChangeAccountComponent, UiCModalChangeAccountModule } from '@wlp/ui-c-modal-change-account';
import { UiCModalLimitRateComponent, UiCModalLimitRateModule } from '@wlp/ui-c-modal-limit-rate';
import { UiCMyDataModule } from '@wlp/ui-c-my-data';
import { UiCNotificationsModule } from '@wlp/ui-c-notifications';
import { UiCPixAdvertisementModalComponent, UiCPixAdvertisementModalModule } from '@wlp/ui-c-pix-advertisement-modal';
import { UiCRechargeModule } from '@wlp/ui-c-recharge';
import { UiCUserCardGridModule } from '@wlp/ui-c-user-card-grid';
import { UiCUserCardMenuModule } from '@wlp/ui-c-user-card-menu';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrMaskerModule } from 'br-mask';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';

import { environment } from '../../../../../../environments/environment';
import { InterceptService } from '../../../../../core/intercept/intercept.service';
import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { AccountLimitPage } from './account-limit/account-limit.page';
import { CardsModule } from './cards/cards.module';
import { BilletsListDataFilterPipe } from './cash-in/billets-list/billet-list-data-filter.pipe';
import { BilletsListPage } from './cash-in/billets-list/billets-list.page';
import { RechargePage } from './cash-in/recharge/recharge.page';
import { BilletCreateDataFilterPipe } from './cash-out/charging/billet/billet-create/billet-create-data-filter.pipe';
import { FavoritesDataFilterPipe } from './cash-out/charging/favorites-data-filter.pipe';
import { ExtractModule } from './cash-out/extract/extract.module';

/*
TODO CERTIFICAR DE QUE ESSE MODULO REALMENTE NAO ESTA SENDO USADO DURENTE O DESENVOLVIMENTO DOS BOLETOS
import { TransferModule } from './cash-out/transfer/transfer.module';
*/

import { CpManagerRoutingModule } from './cp-manager-routing.module';
import { CpManagerPage } from './cp-manager.page';
import { DashboardPage } from './dashboard/dashboard.page';
import { DeveloperPage } from './developer/developer.page';
import { MyDataPage } from './my-data/my-data.page';
import { NotificationsPage } from './notifications/notifications.page';
import { OnboardingEcPageModule } from './onboarding-ec/onboarding-ec.module';
import { PixModule } from './pix/pix.module';
import { SettingsModule } from './settings/settings.module';
import { WalletModule } from './wallet/wallet.module';
import { DdaModule } from './cash-out/dda/dda.module';
import { ChangePasswordPage } from './change-password/change-password.page';
import { UiCChangePasswordModule } from '@wlp/ui-c-change-password';
import { BillingModule } from './cash-in/billing/billing.module';
import { uiCModalBankLiquidatorComponent, uiCModalBankLiquidatorModule } from '@wlp/ui-c-modal-bank-liquidator';
import { ModalMigrationComponent } from "../../modals/modal-migration/modal-migration.component";
import { ModalMigrationSuccessComponent } from "../../modals/modal-migration-success/modal-migration-success.component";
@NgModule({
  imports: [
    AngularSvgIconModule.forRoot(),
    AngularSvgIconModule,
    BrMaskerModule,
    // CardsModule,
    CommonModule,
    ComponentsModule,
    CpManagerRoutingModule,
    ExtractModule,
    FormsModule,
    HeaderUserInfoModule,
    IonicModule,
    NgxMaskIonicModule.forRoot(),
    OnboardingEcPageModule,
    CardsModule,
    PixModule,
    ReactiveFormsModule,
    RouterModule,
    ClipboardModule,
    /*
    TODO CERTIFICAR DE QUE ESSE MODULO REALMENTE NAO ESTA SENDO USADO DURENTE O DESENVOLVIMENTO DOS BOLETOS
    TransferModule,
    */
    UiBsCashInModule.forRoot({ device: 'WEB', config: environment }),
    UiBsCashOutModule.forRoot({ device: 'WEB', config: environment }),
    UiBsDashboardModule.forRoot({ device: 'WEB', config: environment }),
    UiBsMyDataModule.forRoot({ device: 'WEB', config: environment }),
    UiBsProsperaModule.forRoot({ device: 'WEB', config: environment }),
    UiBsLoginModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCAccountBalanceModule,
    UiCAccountLimitsModule,
    UiCAsideMenuLeftModule,
    UiCBarInfoModule,
    UiCCardInfoModule,
    UiCDashboardHeaderModule,
    UiCDatePickerModule.forRoot({ environment: 'web' }),
    UiCModalModule,
    UiCMyDataModule,
    UiCNotificationsModule,
    UiCPixAdvertisementModalModule,
    UiCRechargeModule,
    UiCUserCardGridModule,
    HeaderUserInfoModule,
    UiCUserCardMenuModule,
    WalletModule,
    UiCModalLimitRateModule,
    UiCModalChangeAccountModule,
    SettingsModule,
    DdaModule,
    UiCChangePasswordModule,
    BillingModule,
    uiCModalBankLiquidatorModule,
  ],
  declarations: [
    CpManagerPage,
    DashboardPage,
    NotificationsPage,
    MyDataPage,
    RechargePage,
    BilletsListPage,
    BilletsListDataFilterPipe,
    BilletCreateDataFilterPipe,
    FavoritesDataFilterPipe,
    DeveloperPage,
    AccountLimitPage,
    ChangePasswordPage,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    ClientLimitRateService,
    ClipboardService,
  ],
  entryComponents: [
    UiCPixAdvertisementModalComponent,
    UiCModalLimitRateComponent,
    UiCModalChangeAccountComponent,
    uiCModalBankLiquidatorComponent,
    ModalMigrationComponent,
    ModalMigrationSuccessComponent],
  bootstrap: [],
})
export class CpManagerModule { }
