import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { Component, OnInit } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { AlertDocumentService } from '@wlp/ui-bs-alert';
import { UploadEcService } from '@wlp/ui-bs-ec';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-upload-documents-ec',
  templateUrl: './upload-documents-ec.page.html',
  styleUrls: ['./upload-documents-ec.page.scss'],
})
export class UploadDocumentsEcPage implements OnInit {
  public isFile: boolean;
  private file: any;
  public userProfile: UserInfoProfile;
  public isResidencialCertificate: boolean;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private uploadEcService: UploadEcService,
    private loadingService: LoadingService,
    private alertDocumentService: AlertDocumentService,
    private toastController: ToastController,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  public ngOnInit() {
    this.isResidencialCertificate = true;
    this.loadUser();
  }

  public ionViewWillEnter(): void {
    this.isFile = true;
  }

  public continue(): void {
    const uploadData = {
      fileBase64: this.file.base64,
      title: this.file.file.name,
    };

    try {
      this.loadingService.show();
      this.uploadEcService.uploadFile(this.userProfile.uuidAccount, uploadData).subscribe((res) => {
        this.loadingService.hide();
        this.showToast('Upload efetuado com sucesso!');
        // console.log(res)
      });
    } catch (error) {
      this.showToast('Ocorreu um erro no envio do arquivo!');
      console.log(error);
    }
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  public async showToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });
    toast.present();
  }

  public goBack() {
    this.navCtrl.navigateRoot('app/dashboard');
  }

  public getUploadFile(data: any) {
    this.file = data;
    this.isFile = false;
  }

  public removeFile() {
    this.isFile = true;
    this.file = null;
  }

  public isOwnership() {
    // return this.fileAccount.uploadType === 'RESIDENCIAL_CERTIFICATE';
    return false;
  }

  public ownership(event) {}

  burstFile() {
    this.alertDocumentService.documentLarge();
  }
}
