import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {LayoutConfigModel} from 'src/app/core/layout/models/layout-config.model';
import {LayoutConfigService} from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'app-ui-t-modal-cnab-shipping',
  templateUrl: './modal-cnab-shipping.component.html',
  styleUrls: ['./modal-cnab-shipping.component.scss'],
})
export class ModalCnabShippingComponent implements OnInit {
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public fileExtentions = '.rem,.REM,.txt,.TXT';

  @Input()
  public cnabProductsOpts: [];

  @Input()
  public errorMessages: any;

  public fileSelected: {
    name: string;
    type: string;
    size: string;
    lastModified: number
  } = null;

  public FILE: FileList;

  public customPopoverOptionsCodeProductSelect: any;
  public newShippingCodeProduct = new FormControl();

  constructor(
    public modalController: ModalController,
    private translate: UiBsTranslateService,
    private layoutConfigService: LayoutConfigService,
  ) {}

  public onUploadFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];

    this.FILE = input.files;

    const extension = 'kB';

    this.fileSelected = {
      name: file.name,
      type: file.type,
      size: (file.size / 1000).toFixed(2) + extension,
      lastModified: file.lastModified,
    };
  }

  public handleChange(event) {
    // console.log('handleChange::', event.detail.value);
    this.newShippingCodeProduct = event.detail.value;
  }

  public ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.customPopoverOptionsCodeProductSelect = {
      header: this.translate.applyTranslate('CNAB.MANAGEMENT.SELECT_PRODUCT_CODE.HEADER'),
    };
  }

  public dismiss(returnFileValue: boolean) {
    if (returnFileValue) {
      this.modalController.dismiss({
        file: this.FILE,
        codeProduct: this.newShippingCodeProduct,
      });
    }

    this.modalController.dismiss();
  }
}
