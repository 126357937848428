import { StorageService } from '@wlp/ui-bs-oauth';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { environment } from 'src/environments/environment';

import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-success',
  templateUrl: './status-response.page.html',
  styleUrls: ['./status-response.page.scss'],
})
export class StatusResponsePage implements OnInit {
  public dataStep: Step;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private stepsService: StepFlowService,
    private translate: UiBsTranslateService,
    private router: Router,
    private storageService: StorageService
  ) { }

  public async ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    try {
      this.dataStep = await this.stepsService.getSteps(environment, this.router.url);
    } catch (e) {
      /*  this.loadService.hide(); */
    }

    this.storageService.remove('isRetryOnboarding');
  }

  public continue() {
    this.navCtrl.navigateRoot('');
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
