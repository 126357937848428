import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCSwiperModule } from '@wlp/ui-c-swiper';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {IntroService} from '@wlp/ui-bs-intro';
import { InterceptService } from '../../../../../core/intercept/intercept.service';
import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { IntroPageRoutingModule } from './intro-routing.module';
import { IntroPage } from './intro.page';
import { PopoverComponent } from './popover/popover.component';

@NgModule({
  imports: [
    IonicModule,
    TranslateModule,
    UiCSwiperModule,
    UiCContainerBrandModule,
    ComponentsModule,
    IntroPageRoutingModule,
    UiCModalModule,
    CommonModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    IntroPage,
    PopoverComponent,
  ],
  entryComponents: [
    PopoverComponent,
  ],
  providers: [
    IntroService,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class IntroPageModule { }
