import { Component, Input } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'modal-card-notification',
  templateUrl: './modal-card-notification.component.html',
  styleUrls: ['./modal-card-notification.component.scss'],
})
export class ModalCardNotificationComponent {

  @Input() public status: string;
  @Input() public message: string;
  @Input() public btnConfirmTxt: string;
  @Input() public confirmation?: boolean;

  private confirmatedIcon: any;
  private recusadedIcon: any;
  private sendEmailIcon: any;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private navCtrl: NavController,
  ) {
    this.confirmatedIcon = this.layoutConfigService.getImagesPages('confirmated');
    this.sendEmailIcon = this.layoutConfigService.getImagesPages('email-send');
    this.recusadedIcon = this.layoutConfigService.getImagesPages('recused');
  }

  modalImage() {
    if (this.status === 'success') {
      return this.confirmatedIcon.path;
    } else if (this.status === 'sendEmail') {
      return this.sendEmailIcon.path;
    } else if (this.status === 'recused') {
      return this.recusadedIcon.path;
    } else {
      return this.getImageTag(this.status);
    }
  }

  classNotification() {
    if (this.status === 'success') {
      return 'success-text';
    } else if (this.status === 'sendEmail') {
      return 'send-email';
    } else if (this.status === 'recused') {
      return 'failed-text';
    } else {
      return 'message-solicitation';
    }
  }

  confirm(confirm: boolean) {
    if (confirm) {
      this.navCtrl.navigateRoot('app/cards/card-manager');
    } else {
      this.modalController.dismiss(confirm);
    }
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
