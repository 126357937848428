import * as FileSaver from 'file-saver';

import { Component, Inject, OnDestroy, OnInit, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountRechargeService, BilletService, RechargeBilletResponse } from '@wlp/ui-bs-cashin'
import { DOCUMENT } from '@angular/common';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { UserInfoService } from "@wlp/ui-bs-login";
import { UserInfoProfile } from "@wlp/ui-bs-login/lib/dto/user-info-profile";

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-billet-review-charging.component.html',
  styleUrls: ['./modal-billet-review-charging.component.scss'],
})
export class ModalBilletReviewChargingComponent implements OnDestroy, OnInit {

  @Input() billetRequest: boolean;
  @Input() data: any
  @Input() billet: any;
  @Input() billetResponse: any;
  dataRecipient: {};
  // value: any = 40;
  // public billetData: RechargeBilletResponse;
  // public billetValue: any;
  userProfile: UserInfoProfile;

  constructor(
    public modalController: ModalController,
    private accountRechargeService: AccountRechargeService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    protected userInfoService: UserInfoService,
    private billetService: BilletService
  ) {
  }
  ngOnInit() {}

  ngOnDestroy() {
    this.accountRechargeService.unSubscribe();
  }

  dismiss() {
    this.modalController.dismiss().then((res) => { }, (err) => {
      console.error(err);
    });
  }

  printBilletOnPage(msg: string) {
    try {
      this.loadingService.show();
      this.billetService.getBilletDownload(this.billetResponse.transactionId)
        .subscribe(res => {          
          this.loadingService.hide();
          FileSaver.saveAs(new Blob([res]), `Boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
        })
    } catch (error) {
      this.loadingService.hide();
    }
  }

  shareBilletOnPage(msg: string) {
  }

  copyContent() {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.billetResponse.typeableLine;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
}
