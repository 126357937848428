import { StorageService } from '@wlp/ui-bs-oauth';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from "../../../../../../../../environments/environment";
import {AccountTypeService, Step, StepFlowService} from "@wlp/ui-bs-signup";
import { Subject } from "rxjs";
import { LoadingService } from "../../../../../../../core/layout/services/loading.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-account-name',
  templateUrl: './account-name.page.html',
  styleUrls: ['./account-name.page.scss'],
})
export class AccountNamePage implements OnInit, OnDestroy {

  name: string;

  dataStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    public layoutConfigService: LayoutConfigService,
    private navCtrl: NavController,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private loadServide: LoadingService,
    private stepsService: StepFlowService,
    private router: Router,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  public async ngOnInit() {
    try {
      this.loadServide.show();
      await this.cleanParam();

      this.storageService.remove('isRetryOnboarding');

      const layoutConfigValue = this.layoutConfigService.getSavedConfig();
      const langDefault = layoutConfigValue.wlTheme.languageDefault;
      this.translate.setDefaultLang(langDefault);

      this.dataStep = await this.stepsService.getSteps(environment, this.router.url, true, true);
      await this.service.setNameType(null);

      this.loadServide.hide();
    } catch (e) {
      this.loadServide.hide();
      await this.navCtrl.navigateRoot('/');
    }

  }

  async cleanParam() {
      const qParams: Params = {};
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: qParams,
        queryParamsHandling: '',
      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async goContinue(name: string){
    this.loadServide.show();
    await this.service.setName(name);
    await this.stepsService.nextStep(this.navCtrl, this.dataStep);
    this.loadServide.hide();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.dataStep);
  }
}
