import { UiCInfoCardModule } from '@wlp/ui-c-info-card';
import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { BilletLotListPage } from './billet-lot-list/billet-lot-list.page';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsAccountPaymentFavoriteModule } from '@wlp/ui-bs-account-payment-favorite';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCFavoritesListModule } from '@wlp/ui-c-favorites-list';
import { BrMaskerModule } from 'br-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';

import { environment } from './../../../../../../../../environments/environment';
import { InterceptService } from './../../../../../../../core/intercept/intercept.service';
import { BilletCreatePage } from './billet-create/billet-create.page';
import { BilletEmitPage } from './billet-emit/billet-emit.page';
import { BilletLotPage } from './billet-lot/billet-lot.page';
import { BilletLotPageV2 } from './billet-lot-v2/billet-lot-v2.page';
import { BillingPage } from './billing.page';
import { BillingRoutes } from './billing.routing';
import { ModalImportBilletModelComponent } from '../../../../modals/modal-import-billet-model/modal-import-billet-model.component';
import { ModalsModule } from '../../../../modals/modals.module';
import { FilterLot } from './components/filter-lot/filter-lot';
import { SearchDays } from './components/search-days/search-days';
import { FormFilter } from './components/filter-lot/form-filter/form-filter';
import { UiCPaginatorModule } from '@wlp/ui-c-paginator';

@NgModule({
  imports: [
    BillingRoutes,
    BrMaskerModule,
    CommonModule,
    ComponentsModule,
    FormsModule,
    IonicModule,
    BrMaskerModule,
    ReactiveFormsModule,
    TooltipModule,
    UiBsAccountPaymentFavoriteModule,
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiCFavoritesListModule,
    UiCInfoCardModule,
    UiCDatePickerModule,
    ModalsModule,
    UiCPaginatorModule.forRoot({ device: 'WEB', config: environment }),
  ],
  declarations: [
    BillingPage,
    BilletLotPage,
    BilletLotPageV2,
    BilletCreatePage,
    BilletEmitPage,
    BilletLotListPage,
    FilterLot,
    FormFilter,
    SearchDays,
  ],
    exports: [
        FilterLot,
        FormFilter,
        SearchDays,
    ],
  entryComponents: [ModalImportBilletModelComponent],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class BillingModule { }
