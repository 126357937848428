import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { PersonalDataService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from "rxjs";
import { FormGroup } from "@angular/forms";
import { catchError, retry, takeUntil, tap } from "rxjs/operators";
import { LoadingService } from "../../../../../../../core/layout/services/loading.service";
import { environment } from "../../../../../../../../environments/environment";
import { Router } from "@angular/router";
import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-personal-data',
  templateUrl: './personal-data.page.html',
  styleUrls: ['./personal-data.page.scss'],
})
export class PersonalDataPage implements OnInit, OnDestroy {
  form: FormGroup;
  errorFormMessages: any;

  currentStep: any;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private personalDataService: PersonalDataService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private stepsService: StepFlowService,
    private router: Router,
    private loadService: LoadingService,
    private prepareFormDevolution: PreparFormDevolutionService,
    private storage: Storage
  ) {
  }

  async ngOnInit() {
    this.loadService.show();

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.errorFormMessages = this.personalDataService.getFormErrorMessage();
    this.form = this.personalDataService.getPersonalData();
    await this.personalDataService.updateForm();
    this.currentStep = await this.stepsService.getSteps(environment, this.router.url);
  
    await this.prepareFormDevolution.prepareFormDevolution(this.currentStep, this.form);  
    this.loadService.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }

  goContinue() {
    if (this.form.invalid) {
      return;
    }
    this.loadService.show();
    this.personalDataService.sendPersonalData(this.form, 'personal-address')
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res) => {
          this.loadService.hide();
          await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        }),
        retry(2),
        catchError((error) => {
          this.loadService.hide();
          this.personalDataService.getErrorPersonalData(error)
          return of(null);
        }))
      .subscribe();
  }
}
