import { LoadingService } from './../../../../../core/layout/services/loading.service';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TransferService } from '@wlp/ui-bs-cashout';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-search-bank.component.html',
  styleUrls: ['./modal-search-bank.component.scss'],
})
export class ModalSearchBankComponent {

  public bankList: Array<any>;
  public banksFilter: Array<any>;

  constructor(
    public modalController: ModalController,
    private transferService: TransferService,
    private load:LoadingService
  ) {
    this.listBanks();
  }

  async listBanks() {
    this.load.show();
    try {
      await this.transferService.getBankList().subscribe(res => {
        this.load.hide();
        this.bankList = res;
        this.banksFilter = this.bankList;
      });
    } catch (error) {
      this.load.hide();
    }
  }

  filterListBank(value) {
    if (value === undefined || value === '') {
      this.banksFilter = this.bankList;
    } else {
      this.banksFilter = this.bankList.filter((unit) => (unit.code + ' - ' + unit.name.toUpperCase()).indexOf(value.toUpperCase()) > -1);
    }
  }

  async chooseBank(item) {
    this.modalController.dismiss(item);
  }
}
