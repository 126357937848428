import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { PrePixService } from '@wlp/ui-bs-pre-pix';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'app-intro-pix',
  templateUrl: './intro-pix.component.html',
  styleUrls: ['./intro-pix.component.scss'],
})
export class IntroPixComponent implements OnInit {
  userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private prePixService: PrePixService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.prePixService.unSubscribe();
    this.loadingService.show();
    this.userInfoService.getInfoProfile().then((userProfile) => {
      this.userProfile = userProfile;
      this.prePixService.getPrePix(userProfile.uuidWhiteLabel, userProfile.uuidAccount).subscribe((prePixRequest) => {
        this.loadingService.hide();
        if (prePixRequest.user) {
          this.alertController.create({ message: 'Notamos que o seu cadastrado já foi efetuado!' });
          this.navCtrl.navigateRoot(['app/pix/pre-register/pix-conclusion']);
        }
      });
    });
  }

  continue() {
    this.prePixService.setPixByObject({
      uuidUser: this.userProfile.uuidAccount,
      uuidAccountPayment: this.userProfile.uuidAccount,
      uuidWL: this.userProfile.uuidWhiteLabel,
    } as any);
    this.navCtrl.navigateRoot(['app/pix/pre-register/choose-key']);
  }

  goBack() {
    this.navCtrl.navigateRoot(['app/pix/dashboard']);
  }
}
