import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { CardsService } from '@wlp/ui-bs-cards';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import {
  ModalCardNotificationComponent,
} from './../../../../modals/modal-card-notification/modal-card-notification.component';

@Component({
  selector: 'ui-t-card-active',
  templateUrl: './card-active.page.html',
  styleUrls: ['./card-active.page.scss'],
})
export class CardActivePage implements OnInit {
  public activeCardForm: FormGroup;
  public errorMessages: any;
  private userProfile: UserInfoProfile;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public identifier = '';
  constructor(
    private cardService: CardsService,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private navCtrl: NavController,

    private translate: UiBsTranslateService
  ) {
    this.errorMessages = this.cardService.getFormMessageActiveCard();
    this.activeCardForm = this.cardService.createFormActiveCard();
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.loadUser();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private async modalCardNotification(status: string, message: string) {
    const modal = await this.modalController.create({
      component: ModalCardNotificationComponent,
      cssClass: 'modal-card-notification',
      componentProps: {
        status: status,
        message: message,
      },
    });
    modal.onDidDismiss().then(data => {
      if(status == 'success') this.goBackManagerCard();
    });
    return await modal.present();
  }

  public async activeCard() {
    this.loadingService.show();
    await this.cardService
      .sendActivateCardNoName(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, this.activeCardForm)
      .then((res) => {
        this.loadingService.hide();
        
        if (!!res && res.statusCode != 400) {
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.SUCCESS');
        } else {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
      });
  }

  public async checkCnpjAction() {
    await this.cardService.checkIsCNPJ();

  }
  public textBirthDateOrOpenDatePlaceHolder() {
    return  this.cardService.textBirthDateOrOpenDatePlaceHolder();
  }

  public goBackManagerCard() {
    this.navCtrl.navigateRoot('/app/cards/card-manager');
  }

  ionViewDidLeave() {
    this.activeCardForm = this.cardService.createFormActiveCard();
  }
}
