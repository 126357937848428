import { ModalAlertDocsComponent } from './../../../../modals/modal-alert-docs/modal-alert-docs.component';
import { StorageService } from '@wlp/ui-bs-oauth';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { AccountTypeService, FileAccount, Step, StepFlowService, UploadService, PartnersService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { Subject } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from '../../../../../../../../environments/environment';
import { LoadingService } from '../../../../../../../core/layout/services/loading.service';
import { ModalViewFileUpload } from '../../../../modals/modal-view-file-upload/modal-view-file-upload.component';
import { ModalSelfPhotoComponent } from '../../../../modals/modal-self-photo/modal-self-photo.component';

@Component({
  selector: 'ui-t-document-type',
  templateUrl: './document-type.page.html',
  styleUrls: ['./document-type.page.scss'],
})
export class DocumentTypePage {
  listDocuments = {
    data: [],
    requiredFile: false
  };

  currentStep: Step;
  
  isPhysical: boolean = false;
  isLiberalPersonalType: boolean = false;
  isMei: boolean = false;
  

  visible: boolean;
  isPhoto: boolean;
  isUpload: boolean;
  fileAccount: FileAccount;

  isPartnerMei: boolean;
  private ownerMeiID: any;
  private file: any;

  private destroy$: Subject<null> = new Subject();
  isImage: boolean = false;
  private isRG: boolean = false;
  private rgFront: boolean = false;
  isDevolution: any;
  count: number = 0;

  constructor(
    public alertController: AlertController,
    private navCtrl: NavController,
    private uploadService: UploadService,
    private partnersService: PartnersService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private activeParams: ActivatedRoute,
    private stepsService: StepFlowService,
    private router: Router,
    private loadServide: LoadingService,
    private modalController: ModalController,
    private storageService: StorageService
  ) {
  }

  async ngOnInit() {
    this.loadServide.show();
    this.visible = false;
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.currentStep = await this.stepsService.getSteps(environment, this.router.url);

    this.isPartnerMei = false;
    this.activeParams.queryParams.subscribe(async res => {
      if (res.meiPartner !== undefined && res.meiPartner) {
        this.isPartnerMei = true;
        await this.partnersService.updateForm();
      }
    });
    await this.changeListDocumentsToUpload();
    this.visible = true;
    this.storageService.get('isRetryOnboarding').then(res => {
      this.isDevolution = res;
    })
  }

  private async changeListDocumentsToUpload(router?: boolean) {
    const type = await this.service.getTemporaryTypeAccount();
    if (this.isRG) {
      this.count += 1;
      if (this.count != 2) {
        this.listDocuments = {
          data: [{
            document: "RG",
            image: "rg",
            imageTag: "wlp-icon-onboarding-rg",
            isTwoSides: true,
            position: this.rgFront ? 'VERSE' : 'FRONT',
            requiredFile: true,
            text: "ONBORDING.CHOOSE_DOCUMENT.BUTTON_TEXT.RG.MESSAGE"
          }],
          requiredFile: true
        }
      } else {        
        this.isRG = false;
        if (this.count === 2) {
          this.storageService.remove('countDoc');
          this.count = 0;          
          let data = this.listDocuments.data.filter(el => {
            return el.document != "IDENTIFICATION_DOCUMENT"
          });
          this.listDocuments.data = data;        
        }
      }
    } else {
      try {
        if (type.toUpperCase() == 'PHYSICAL_PERSON' || type.toUpperCase() == 'ESCROW_PHYSICAL_PERSON') {
          this.isPhysical = true;
          this.listDocuments = await this.uploadService.findDocumentsToUpload('/upload-documents');          
        } else if (type.toUpperCase() == 'LIBERAL_PROFESSIONAL') {
          this.isLiberalPersonalType  = true;
          this.listDocuments = await this.uploadService.findDocumentsToUpload('/upload-documents');
        } else if (type.toUpperCase() == 'MEI' && this.isPartnerMei) {
          this.isMei = true;
  
          await this.loadMeiOwner();

        } else if (type.toUpperCase() == 'MEI' || type.toUpperCase() == 'LEGAL_PERSON' || type.toUpperCase() == 'ESCROW_LEGAL_PERSON') {
          this.listDocuments = await this.uploadService.findDocumentsToUpload('/upload-documents-company');
        }
      } catch (err) {
        this.listDocuments = {
          data: [],
          requiredFile: false
        };
      }
    }


    if (this.listDocuments.data.length < 1 && router) {
      await this.goContinue();
    }

    this.listDocuments.data.forEach(el => {
      el.imageTag = this.getImageTag(el.image);

      if (el.items) {
        el.items.forEach(item => {
          item.forEach(il => {
            il.imageTag = this.getImageTag(il.image);
          })
        })
      }
    });
    this.loadServide.hide();
  }

  public async loadMeiOwner() {
    const partners: any = await this.partnersService.getListPartners();
    this.ownerMeiID = partners[0].id;

    this.listDocuments = await this.uploadService.findDocumentsPartnerToUpload(this.ownerMeiID, '/upload-documents-partner');
    this.listDocuments.data.forEach((el) => {
      el.imageTag = this.getImageTag(el.image);

      if (el.items) {
        el.items.forEach((item) => {
          item.forEach((il) => {
            il.imageTag = this.getImageTag(il.image);
          });
        });
      }
    });
    this.loadServide.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async changeDocument(document: any) {
    if (document.document === "RESIDENCIAL_CERTIFICATE") {      
      const modal = await this.modalController.create({
        component: ModalAlertDocsComponent,
        cssClass: 'modal-alert-docs',
        backdropDismiss: false,
      });
      await modal.present();
    }

    if (document.document === "SELF_PHOTO") {
      const modal = await this.modalController.create({
        component: ModalSelfPhotoComponent,
        cssClass: 'modal-self-photo',
        backdropDismiss: false,
      })
      await modal.present()
    }

    this.storageService.get('countDoc').then(res => {      
      if (isNaN(res) || res === undefined || res === null) this.count = 0;
      else this.count = res;
    });
    /* console.log(document) */
    this.fileAccount = new FileAccount();
    this.fileAccount.uploadType = document.document;
    this.fileAccount.positionPhoto = document.position;
    this.fileAccount.title = '';

    if (document.document === 'RG') {
      /* this.count += 1; */
      if (document.document === 'RG' && this.isRG === false) {
        this.isRG = true;
        if (document.position === 'FRONT') {
          this.rgFront = true;
        }
        if (document.position === 'VERSE') {
          this.rgFront = false;
        }
      } else {
        this.isRG = false;
      }
    }
  }

  getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.class;
  }

  async goBack() {    
    await this.storageService.set('countDoc', 1);
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }

  async goContinue() {
    this.loadServide.show();
    this.visible = false;
    if (this.file && (this.isPhoto || this.isUpload)) {
      this.fileAccount.fileBase64 = this.file.base64;
      this.fileAccount.title = this.file.file.name;

      if (!!this.ownerMeiID) {
        await this.uploadService.uploadPartnerFile(this.fileAccount, this.ownerMeiID);
      } else {
        await this.uploadService.uploadFile(this.fileAccount);
      }

      this.isPhoto = false;
      this.isUpload = false;

      await this.changeListDocumentsToUpload(true);
      this.loadServide.hide();
      this.visible = true;

      return;
    }

    this.loadServide.hide();
    if (this.listDocuments.data.length < 1 || !this.listDocuments.requiredFile) {
      if (this.currentStep.nextStep === '/onboarding/document-type?meiPartner=true' && this.isDevolution) window.location.href = '/onboarding/document-type?meiPartner=true';
      await this.stepsService.nextStep(this.navCtrl, this.currentStep);
    }

    this.visible = true;
  }

  removeFile() {
    this.file = null;
  }

  getUploadFile({ data, isPhoto, isUpload }: any) {
    this.isPhoto = isPhoto;
    this.isUpload = isUpload;
    this.file = {
      base64: data && data.base64 ? data.base64 : data,
      file: {
        name: data && data.file && data.file.name ? data.file.name : 'nome_da_foto'
      }
    };

    if (isPhoto) {
      this.goContinue();
    }
  }

  async alertDocumentLarge(size?: number) {
    if (!size) {
      size = 5;
    }
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.TITLE', { size: size }),
      message: this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.MESS'),
      buttons: [this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.BUTTON')],
    });
    await alert.present();
  }

  public downloadPDF(pdf: any, name: string) {
    this.isImage = false;
    let type = pdf.split('/')[1].substr(0, 3);
    if (type === 'pdf') {
      this.viewImage(pdf, type)
    } else {
      this.isImage = true;
      this.viewImage(pdf, 'img');
    }
  }

  async viewImage(base64: string, type: string) {
    let file = {
      type: this.fileAccount.uploadType,
      nameFile: this.file.file.name,
      positionPhoto: this.fileAccount.positionPhoto
    }
    const modal = await this.modalController.create({
      component: ModalViewFileUpload,
      cssClass: 'modal-alert-class-file',
      componentProps: {
        file,
        imageUrl: base64,
        type
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

}
