import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import {Component, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {Router} from '@angular/router';
import { NavController } from '@ionic/angular';
import {ProfessionalTypeService, Step, StepFlowService} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import {of, Subject} from 'rxjs';
import {catchError, retry, takeUntil, tap} from 'rxjs/operators';
import {environment} from '../../../../../../../../environments/environment';
import {LoadingService} from '../../../../../../../core/layout/services/loading.service';

@Component({
  selector: 'ui-t-professional-type',
  templateUrl: './professional-type.page.html',
  styleUrls: ['./professional-type.page.scss'],
})
export class ProfessionalTypePage implements OnInit {
  public errorMessages: any;
  public form: FormGroup;
  public listCertified: Array<any>;

  public currentStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private professionalTypeService: ProfessionalTypeService,
    private translate: UiBsTranslateService,
    private stepsService: StepFlowService,
    private router: Router,
    private loadService: LoadingService,
    private prepareFormDevolution: PreparFormDevolutionService,
  ) {
  }

  public async ngOnInit() {
    this.loadService.show();

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.errorMessages = this.professionalTypeService.getErrorMessage();
    console.log(this.errorMessages)
    this.form = this.professionalTypeService.getProfessionalTypeForm();
    this.listCertified = this.professionalTypeService.getCertified();
    await this.professionalTypeService.updateForm();

    this.currentStep = await this.stepsService.getSteps(environment, this.router.url);

    await this.prepareFormDevolution.prepareFormDevolution(this.currentStep, this.form);  

    this.loadService.hide();
  }

  public goContinue(): void {
    if (this.form.invalid) {
      return;
    }

    this.loadService.show();
    this.professionalTypeService.sendProfessionalType()
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res) => {
          this.loadService.hide();
          await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        }),
        retry(2),
        catchError((err) => {
          this.loadService.hide();
          this.professionalTypeService.getErrorProfessionalType(err);
          return of(null);
        }),
      ).subscribe();
  }

  public async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }
}
