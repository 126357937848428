import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { productsAndServicesResponse, ProductsService } from '@wlp/ui-bs-ec';

import { OnboardingEcInterface } from '../../onboarding-ec.interface';
import { OnboardingEcService } from '../../onboarding-ec.service';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';

@Component({
  selector: 'products-and-services',
  templateUrl: './products-and-services-ec.page.html',
  styleUrls: ['./products-and-services-ec.page.scss'],
})
export class ProductsAndServicesEcPage implements OnInit {
  listProductsAndServices: productsAndServicesResponse[];
  public productList: any;
  private icon;
  titleData: OnboardingEcInterface;
  private cardMachineIcon: any;
  private creditCardIcon: any;
  private billetIcon: any;
  private energyIcon: any;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private productsService: ProductsService,
    private OnboardingEcService: OnboardingEcService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.cardMachineIcon = this.layoutConfigService.getImagesPages('card-machine');
    this.creditCardIcon = this.layoutConfigService.getImagesPages('credit-card');
    this.billetIcon = this.layoutConfigService.getImagesPages('billet');
    this.energyIcon = this.layoutConfigService.getImagesPages('energy');
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.productList = this.productsService.getProducts();
    this.titleData = {
      isTitle: true,
      title: 'Produtos & Serviços',
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
    };
    this.OnboardingEcService.setTitleSubject(this.titleData);
  }

  cardMachine() {
    // console.log('machine');
  }
  criditCard() {
    // console.log('criditCard');
  }
  billet() {
    // console.log('billet');
  }
  energy() {
    // console.log('energy');
  }

  goBack() {
    this.navCtrl.back();
  }

  defineFlow(productsAndServices: productsAndServicesResponse): void {
    if (
      productsAndServices.typeProductsAndServices.toString() === 'CARD_MACHINE' ||
      productsAndServices.typeProductsAndServices.toString() === 'CARD_MACHINE'
    ) {
      this.cardMachine();
      return;
    }

    if (productsAndServices.typeProductsAndServices.toString() === 'CREDIT_CARD') {
      this.criditCard();
      return;
    }

    if (productsAndServices.typeProductsAndServices.toString() === 'BILLET') {
      this.billet();
      return;
    }

    if (productsAndServices.typeProductsAndServices.toString() === 'ENERGY') {
      this.energy();
      return;
    }
  }

  loadIcon(productsAndServices) {
    switch (productsAndServices) {
      case '0':
        {
          this.icon = this.cardMachineIcon.path;
        }
        break;
      case '1':
        {
          this.icon = this.creditCardIcon.path;
        }
        break;
      case '2':
        {
          this.icon = this.billetIcon.path;
        }
        break;
      case '3':
        {
          this.icon = this.energyIcon.path;
        }
        break;
      default: {
        this.icon = this.billetIcon.path;
      }
    }
    return this.icon;
  }
}
