import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';

@Component({
  selector: 'modal-alert-billet-taxes',
  templateUrl: './modal-alert-billet-taxes.component.html',
  styleUrls: ['./modal-alert-billet-taxes.component.scss'],
})
export class ModalAlertBilletTaxesComponent implements OnInit {
  @Input() public modalImage?: string;
  @Input() public status: string;
  @Input() public message: string;
  @Input() public description?: string;
  @Input() public alert?: string;
  @Input() public confirmBtn: string;
  @Input() public cancelBtn?: string;
  @Input() public confirmation?: boolean;
  @Input() public param?: any;
  @Input() public notButtons?: boolean = false;
  @Input() public uuidBillet?: string = '';
  private confirmatedIcon: any;
  private warningIcon: any;

  public taxesArrayI18n = []

  constructor(
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private languageService: UiBsTranslateService
  ) {
    this.confirmatedIcon = this.layoutConfigService.getImagesPages('confirmated');
    this.warningIcon = this.layoutConfigService.getImagesPages('warning-circle');
  }

  ngOnInit() {
    this.image();
    this.languageService.get("ASIDE_MENU.PAYMENTS.TAXES").subscribe(res=> {
    this.taxesArrayI18n = res;    
    })
  }

  image() {
    if (this.status === 'success') {
      this.modalImage = this.confirmatedIcon.path;
      return this.confirmatedIcon.path;
    } else if (this.status === 'warning-circle') {
      this.modalImage = this.warningIcon.path;
      return this.warningIcon.path;
    } else {
      this.modalImage = this.getImageTag(this.status);
      return this.getImageTag(this.status);
    }
  }

  confirm(confirm: boolean) {
    this.modalController.dismiss(confirm);

    if (this.uuidBillet) {
      const uuidBillet = this.uuidBillet;
      this.router.navigate(['app/billing/billet-lot'], { state: { uuidBillet } });
    }
  }

  classNotification() {
    if (this.status === 'success') {
      return 'success-text m-0 px-2';
    } else if (this.status === 'warning-circle') {
      return 'failed-text m-0 px-2';
    } else {
      return 'message-solicitation';
    }
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
