import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StepServiceResolver } from '../../../functions/resolver/step-service-resolver';
import { StatusAccountPage } from '../../../notifications/status-account/status-account.page';
import { StatusConfirmationPage } from '../../../notifications/status-confirmation/status-confirmation.page';
import { StatusResponsePage } from '../../../notifications/status-response/status-response.page';
import { CreatePasswordPage } from '../device-validation/create-password/create-password.page';
import { AcceptTermsComponent } from './accept-terms/accept-terms.component';
import { AccountFormPage } from './account-form/account-form.page';
import { AccountNamePage } from './account-name/account-name.page';
import { AccountTypePage } from './account-type/account-type.page';
import { ComercialAgentPage } from './comercial-agent/comercial-agent.page';
import { CompanyAddressPage } from './company-address/company-address.page';
import { CompanyDataPage } from './company-data/company-data.page';
import { ContractPage } from './contract/contract.page';
import { DocumentTypePage } from './document-type/document-type.page';
import { PartnersPage } from './partners/partners.page';
import { PersonalAddressPage } from './personal-address/personal-address.page';
import { PersonalDataPage } from './personal-data/personal-data.page';
import { ProfessionalTypePage } from './professional-type/professional-type.page';
import { ResponsibleMeiPage } from './responsible-mei/responsible-mei.page';
import { SignUpComponent } from './sign-up.component';
import { AccountTypeManualPage } from './account-type-manual/account-type-manual.page';

const routes: Routes = [
  {
    path: 'onboarding',
    component: SignUpComponent,
    children: [
      {
        path: '',
        redirectTo: 'account-type',
        pathMatch: 'full',
      },
      {
        path: 'account-name',
        component: AccountNamePage,
      },
      {
        path: 'account-type',
        component: AccountTypePage,
      },
      {
        path: 'account-type-manual',
        component: AccountTypeManualPage,
      },
      {
        path: 'account-form',
        component: AccountFormPage,
      },
      {
        path: 'comercial-agent',
        component: ComercialAgentPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'company-address',
        component: CompanyAddressPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'company-data',
        component: CompanyDataPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'contract',
        component: ContractPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'accept-terms/:id/:partner',
        component: AcceptTermsComponent,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'validation-email/:validation',
        component: AcceptTermsComponent,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'create-password',
        component: CreatePasswordPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'document-type',
        component: DocumentTypePage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'partners',
        component: PartnersPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'personal-address',
        component: PersonalAddressPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'personal-data',
        component: PersonalDataPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'professional-type',
        component: ProfessionalTypePage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'responsible-mei',
        component: ResponsibleMeiPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'status-response',
        component: StatusResponsePage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'status-account',
        component: StatusAccountPage,
        resolve: { route: StepServiceResolver },
      },
      {
        path: 'status-confirmation',
        component: StatusConfirmationPage,
        resolve: { route: StepServiceResolver },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SignUpRoutingModule { }
