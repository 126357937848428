import { LoadingService } from './../../../../../../../core/layout/services/loading.service';
import { Route } from '@angular/compiler/src/core';
import { AccountTypeService, ContractService, StepFlowService, CreateAccountService } from '@wlp/ui-bs-signup';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from '../../../../../../../../environments/environment';
import { ModalAlertComponent } from '../../../../modals/modal-alert/modal-alert.component';

@Component({
  selector: 'app-accept-terms',
  templateUrl: './accept-terms.component.html',
  styleUrls: ['./accept-terms.component.css']
})
export class AcceptTermsComponent implements OnInit {

  idPartner: string = null;
  id: string = null;
  validation: string = null;

  constructor(
    private activerRouter: ActivatedRoute,
    private stepsService: StepFlowService,
    private contractService: ContractService,
    private accountTypeService: AccountTypeService,
    private serviceCreateAccount: CreateAccountService,
    private laodService: LoadingService,
    private router: Router,
    private storage: Storage,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    this.laodService.show();

    this.id = this.activerRouter.snapshot.paramMap.get('id');
    this.idPartner = this.activerRouter.snapshot.paramMap.get('partner');

    this.validation = this.activerRouter.snapshot.paramMap.get('validation');

    if (this.id != null && this.idPartner != null) {
      await this.storage.set('idBusinessPartner', this.idPartner).then((result) => {
        this.contractService.getStatusPartnersContractSteps(this.id, this.idPartner)
          .then(async res => {
            if (res) {
              this.prepareStepFlow(this.id, res);
            }
          })
      });
    } else if (this.validation != null) {
      (await this.serviceCreateAccount.validationEmailLink(this.validation).toPromise()
        .then(res => {
          if (!res.id) {
            this.notification('warning-circle', res.steps[0].description, false);
          } else {
            this.prepareStepFlow(res.id, res)
          }
        })
      )
    }
    this.laodService.hide();
  }


  async prepareStepFlow(_id: string, steps?: any) {

    let ob: any = {
      _id: _id
    };

    this.serviceCreateAccount.processPreAccount(ob);
    if (steps && steps.name) {
      await this.accountTypeService.setName(steps.name);
    } else {
      this.accountTypeService.setName((await this.serviceCreateAccount.loadPreAccount(_id)).name)
    }

    await this.accountTypeService.setListStep(steps);
    const accountType = await this.accountTypeService.loadRegisteredAccountType();
    await this.accountTypeService.temporaryTypeAccount(accountType.accountType.typeFlow);
    await this.accountTypeService.setNameType(accountType.accountType.name);
    this.laodService.hide()
    this.router.navigate([`/onboarding${steps.steps[0].currentStep}`])
  }


  private async notification(status: string, message: string, confirmation?: boolean) {
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status,
        message,
        confirmBtn: 'OK',
        confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });


    modalSuccess.onWillDismiss()
      .then(async (res) => {
        if (!res.data) {
          this.router.navigate(['/'])
        }
      });
    return await modalSuccess.present();
  }
}
