import { StorageService } from '@wlp/ui-bs-oauth';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ContractService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { environment } from 'src/environments/environment';

import { LoadingService } from '../../../../../core/layout/services/loading.service';
import { ModalAlertComponent } from '../../modals/modal-alert/modal-alert.component';

@Component({
  selector: 'app-status-confirmation',
  templateUrl: './status-confirmation-page.html',
  styleUrls: ['./status-confirmation-page.scss'],
})
export class StatusConfirmationPage implements OnInit {

  public listPartnersContract: any[];
  public dataStep: Step;

  constructor(
    private navCtrl: NavController,
    private contractService: ContractService,
    private stepsService: StepFlowService,
    private router: Router,
    private loadService: LoadingService,
    private modalController: ModalController,
    private translate: UiBsTranslateService,
    private storageService: StorageService
  ) {
  }

  public async ngOnInit() {
    this.loadService.show();
    (await this.contractService.getPartnersContract()).toPromise().then((res) => {
      this.loadService.hide();
      this.listPartnersContract = res;
    });

    try {
      this.dataStep = await this.stepsService.getSteps(environment, this.router.url);
    } catch (e) {
      this.loadService.hide();
    }
    this.storageService.remove('isRetryOnboarding');
  }

  public async goContinue() {
    this.navCtrl.navigateRoot('');
  }

  public resendContractPartners() {
    this.contractService.sendEmailContractPartners()
      .then((res) => {
        if (res.status) {
          this.notification('success', this.translate.applyTranslate('ONBORDING.STATUS_ACCOUNT.MODAL.SUCCESS'));
        } else {
          this.notification('warning-circle', this.translate.applyTranslate('ONBORDING.STATUS_ACCOUNT.MODAL.WARNING'));
        }
      });
  }

  private async notification(status: string, message: string) {
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-notification-class',
      componentProps: {
        status,
        message,
        confirmBtn: this.translate.applyTranslate('ONBORDING.V2.BUTTON.CONFIRM'),
        confirmation: false,
        translate: true,
      },
      backdropDismiss: false,
    });
    return await modalSuccess.present();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.dataStep);
  }
}
