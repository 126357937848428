import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
@Component({
  selector: 'ui-t-search-days',
  templateUrl: './search-days.html',
  styleUrls: ['./search-days.scss'],
})
export class SearchDays implements OnInit {
  private layoutConfigValue: LayoutConfigModel;
  private langDefault: string;
  @ViewChild(`btnList`, { static: false }) btnList: ElementRef;

  @Output() handleSearchForDays = new EventEmitter<string>();

  constructor(
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
  ) {
    moment.locale('pt-br');
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  handleClickItem(event: Event, numberDays: number) {

    this.resetBtnFilters();

    const element: HTMLElement = event.target as HTMLElement;
    element.classList.add('primary');

    this.handleSearchForDays.emit(numberDays.toString())
  }

  public resetBtnFilters() {
    const btnsHolder: HTMLElement = this.btnList.nativeElement as HTMLElement;
    const btns: NodeListOf<HTMLElement> = btnsHolder.querySelectorAll('.btn-filter');
    btns.forEach((btn) => btn.classList.remove('primary'));
  }

}
