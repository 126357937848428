import { PopoverController } from '@ionic/angular';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {

  @Input() items: any;

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  choose(ev: any) {
    this.popoverCtrl.dismiss(ev);
  }
}
