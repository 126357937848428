import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {
  BilletDiscountDetailsRequestDto,
  BilletDiscountRequestDto, BilletDiscountTypeEnum,
  BilletFeeRequestDto, BilletFineRequestDto,
  BilletInterestTypeEnum, BilletProtestRequestDto,
  BilletProtestTypeEnum,
  BilletSpecieTypeEnum, BilletTrafficTicketTypeEnum
} from '@wlp/ui-bs-cashin';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {MaskService} from '@wlp/ui-bs-util';
import * as moment from 'moment/moment';
import {LayoutConfigModel} from '../../../../../core/layout/models/layout-config.model';
import {LayoutConfigService} from '../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'app-modal-billet-juros',
  templateUrl: './modal-billet-juros.component.html',
  styleUrls: ['./modal-billet-juros.component.scss'],
})

export class ModalBilletJurosComponent implements OnInit {
  private layoutConfigValue: LayoutConfigModel;
  private langDefault: string;

  @Input() billetForm: FormGroup;

  @Input() hasProtest: FormControl
  @Input() protestSelected: string;

  @Input() fineValue: any

  @Input() hasInterestAndTrafficTicket: FormControl;
  @Input() billetInterestTypeSelected: string;

  @Input() billetTrafficTicketTypeSelected: string;

  @Input() hasDiscount: FormControl;
  @Input() discounts: FormArray

  @Input() createDiscountForm: () => FormGroup;
  @Input() addDiscount: (hasValidator, discounts, billetForm) => FormGroup;
  @Input() removeDiscount: (discountIndex: number) => void;

  @Input() protestBlockedField: () => boolean;
  @Input() onToggleChange: (event, currentToggleControl, currentToggleName, currentDiscountForm, currentBillet) => void;

  @Input() onChangeProtestSelectField: (event, billetSelected) => void;
  @Input() onChangeInterestTypeSelectField: (event, billetSelected) => void;
  @Input() onChangeTrafficTicketTypeSelectField: (event, billetSelected) => void;
  @Input() onChangeDiscountTypeSelectField: (event, discountSelected, billetSelected) => void;

  hasEnoughDiscounts: boolean = false

  proptestOptions = Object.keys(BilletProtestTypeEnum).map((protest) => ({
    key: protest,
    label: `CASH_OUT.BILLET_DETAILED_PAGE.PROTEST.SELECT_OPTIONS.${protest}`,
  }));

  billetInterestTypeOptions = Object.keys(BilletInterestTypeEnum).map(option => ({
    key: option,
    label: `CASH_OUT.BILLET_DETAILED_PAGE.FEES.SELECT_OPTIONS.${option}`
  }))

  hasEnoughBillets: boolean = false;
  descriptionCounter = 0;

  customPopoverOptionsProtestTypeSelect: any
  customPopoverOptionsBilletInterestTypeSelect: any
  customPopoverOptionsbilletTrafficTicketTypeSelectedSelect: any
  customPopoverOptionsBilletDiscountTypeSelect: any

  @Input() errorMessages: { key: { type: string, message: string }[] }
  @Input() payerData?: { name: string; document: string };

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private translate: UiBsTranslateService,
    private cd: ChangeDetectorRef,
    private maskService: MaskService,
  ) {
    moment.locale('pt-br');
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.customPopoverOptionsProtestTypeSelect = {
      header: this.translate.applyTranslate('CASH_OUT.BILLET_DETAILED_PAGE.PROTEST.TYPE'),
    };

    this.customPopoverOptionsProtestTypeSelect = {
      header: this.translate.applyTranslate('CASH_OUT.BILLET_DETAILED_PAGE.PROTEST.TYPE'),
    };

    this.customPopoverOptionsBilletInterestTypeSelect = {
      header: this.translate.applyTranslate('CASH_OUT.BILLET_DETAILED_PAGE.FEES.TITLE'),
    }

    this.customPopoverOptionsbilletTrafficTicketTypeSelectedSelect = {
      header: this.translate.applyTranslate('CASH_OUT.BILLET_DETAILED_PAGE.TRAFFIC_TICKET.TITLE'),
    }

    this.customPopoverOptionsBilletDiscountTypeSelect = {
      header: this.translate.applyTranslate('CASH_OUT.BILLET_DETAILED_PAGE.DISCOUNT.TITLE'),
    }
  }

  public closeModal() {
    const hasProtestControl = this.billetForm.controls.hasProtest as FormControl
    const hasInterestAndTrafficTicketControl = this.billetForm.controls.hasInterestAndTrafficTicket as FormControl
    const hasDiscountControl = this.billetForm.controls.hasDiscount as FormControl

    hasProtestControl.setValue(this.billetForm.controls.protestType.value !== "")
    hasInterestAndTrafficTicketControl.setValue(this.billetForm.controls.billetInterestType.value !== '' || this.billetForm.controls.billetTrafficTicketType.value !== '')
    hasDiscountControl.setValue((this.billetForm.controls.billetDiscountType.value !== ""))

    if (!hasProtestControl.value) {
      this.billetForm.controls.protestType.setValue('')
      this.billetForm.controls.protestDays.setValue('')

      this.billetForm.controls.protestType.clearValidators()
      this.billetForm.controls.protestDays.clearValidators()
    }

    if (!hasInterestAndTrafficTicketControl.value) {
      this.billetForm.controls.billetInterestType.setValue("")
      this.billetForm.controls.billetInterestValue.setValue("")
      this.billetForm.controls.billetInterestDays.setValue("")

      this.billetForm.controls.billetInterestType.clearValidators()
      this.billetForm.controls.billetInterestValue.clearValidators()
      this.billetForm.controls.billetInterestDays.clearValidators()

      this.billetForm.controls.billetTrafficTicketType.setValue("")
      this.billetForm.controls.billetTrafficTicketValue.setValue("")
      this.billetForm.controls.billetTrafficTicketDays.setValue("")

      this.billetForm.controls.billetTrafficTicketType.clearValidators()
      this.billetForm.controls.billetTrafficTicketValue.clearValidators()
      this.billetForm.controls.billetTrafficTicketDays.clearValidators()
    }

    if (!hasDiscountControl.value) {
      this.discounts.clear()
      this.billetForm.controls.billetDiscountType.setValue('')
      this.addDiscount(false, this.discounts, this.billetForm)
    }

    this.modalController.dismiss();
  }
}
