import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StepFlowService } from '@wlp/ui-bs-signup';

@Injectable({
  providedIn: 'root',
})
export class StepServiceResolver implements Resolve<any> {

  constructor(private stepService: StepFlowService) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = route.url[0].path;
    if(path == 'onboarding') {
      return;
    }
    const data = '/' + path;
    await this.stepService.updateStep(data);
    return;
  }
}
