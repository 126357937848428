import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StepServiceResolver } from '../../../functions/resolver/step-service-resolver';
import { CreatePasswordPage } from './create-password/create-password.page';
import { DevicePage } from './device/device.page';

const routes: Routes = [
  {
    path: 'device',
    component: DevicePage,
    resolve: { route: StepServiceResolver },
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DeviceValidationRoutingModule {}
