import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { TokenCodeService } from '@wlp/ui-bs-token';
import { interval, Observable } from 'rxjs';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-token.component.html',
  styleUrls: ['./modal-token.component.scss'],
})
export class ModalTokenComponent implements OnInit {

  public formToken: FormGroup;
  public errorMessages: any;
  public errorToken: string;
  public loading = false;
  public statusTransaction: string;
  public userProfile: UserInfoProfile;

  public qrCodeData = {
    class: 'qr-code-container',
    colorDark: '#ffffff',
    colorLight: "#000000",
    width: 120,
  };

  @Input() public confirmation?: boolean;
  @Input() validationOnly: boolean;
  @Input() validationCard: boolean;
  @Input() codeOperation?: string;
  @Input() onSuccessValidation?: () => void;
  
  qrData: any;
  data: string;
  sub: any;
  progressbarValue: number;
  tokenCounter: number;
  timer$: any;
  loadCode = true;
  verifySub: any;

  constructor(
    public modalController: ModalController,
    private tokenCodeService: TokenCodeService,
    protected userInfoService: UserInfoService,
  ) {
    // Iniciando form
    this.errorMessages = this.tokenCodeService.getFormMessageError();
    this.formToken = this.tokenCodeService.createFormToken();
  }


  ngOnInit(): void {
    this.data = "";
    this.loadUser()
  }

  ionViewWillLeave() {
    this.sub.unsubscribe();
    if (!!this.verifySub) this.verifySub.unsubscribe();
  }

  resetQrCode() {
    this.progressbarValue = 1;

    this.sub = interval(1500)
    .subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 20) / 1200;

      if (sec > 0 && sec % 5 === 0) {
        this.verifyQrcode();
      }

      if (this.progressbarValue === 0) {
        this.loadCode = true;
        this.sub.unsubscribe();
        this.verifySub.unsubscribe();
        this.generateQrcodeCodeToken();
        this.resetQrCode();
      }
    });
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
        this.generateQrcodeCodeToken();
        /* this.resetQrCode(); */
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }

  }

  public async confirm() {
    this.loading = true;
    const token = this.formToken.get('token').value;
    const validToken = await this.tokenCodeService.confirmToken(
      this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token
    );
    /* console.log('validToken', await validToken); */

    if (await validToken) {
      this.loading = false;
      if (this.validationOnly) {
        if (this.validationCard) {
          this.dismissSuccess(validToken, token);
          return;
        } else {          
          this.dismissSuccess(validToken, token);
          return;
        }
      }

      if(!this.validationOnly) {
        this.dismissSuccess(validToken, token);
        this.onSuccessValidation()
      }

      this.dismissSuccess(validToken, token);
    } else {
      this.loading = false;
      this.errorToken = 'CASH_OUT.MODAL_TOKEN.INVALID';
    }
  }

  async dismiss(status: boolean) {
    this.modalController.dismiss(status);
  }

  async dismissSuccess(status: boolean, token: string) {
    this.resetSub();
    this.verifySub.unsubscribe();
    this.modalController.dismiss(status, token);
  }

  resetSub() {
    if (this.sub !== undefined) {
      this.sub.unsubscribe();
    }
  }

  generateQrcodeCodeToken() {
    try {
      this.tokenCodeService.generateQrcodeCodeToken(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, this.codeOperation)
        .subscribe(res => {
          this.loadCode = false;
          this.qrData = res;
          this.data = res.data;
          this.resetSub()
          this.resetQrCode();
        })
    } catch (error) {
      console.log(error);
    }
  }

  verifyQrcode() {    
    this.verifySub = this.tokenCodeService.verifyQrcode(this.qrData).subscribe((res: any) => {
      this.verifySub.unsubscribe();
      if (res.isUsed) {
        this.resetSub();
        this.dismissSuccess(res.isUsed, res.token);
      }
    })
  }
}
