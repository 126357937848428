import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'card-message-page',
  templateUrl: './card-message.page.html',
  styleUrls: ['./card-message.page.scss'],
})
export class CardMessagePage implements OnInit {
  @Input() card: any;
  public showValueFlag: boolean;
  public showValueIcon: string;
  public brand: string;

  public cards: Array<any>;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private uicMenuApp: UICMenuAppService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private modalCtrl: ModalController,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.cards = [
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 },
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 },
    ];
  }

  showValue(): void {
    this.showValueFlag = this.showValueFlag ? false : true;
    this.showValueIcon = this.showValueFlag ? this.showIcon('ocultarValor') : this.showIcon('verValor');
  }

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/casa-feliz/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  redirectUrl(link: string): void {
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(false);
  }

  closeModal(option): void {
    this.modalCtrl.dismiss({
      dismissed: true,
      option,
    });
  }
}
