import {UserInfoService, UserInfoProfile} from '@wlp/ui-bs-login';

export class CpManagerBasePage  {
  protected userProfile: UserInfoProfile;

  constructor(protected userInfoService: UserInfoService) {
  }

  public async loadUser() {
    try {
      this.userProfile = await this.userInfoService.getInfoProfile();
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }
}
