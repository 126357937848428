import { StorageService } from '@wlp/ui-bs-oauth';
import { Storage } from '@ionic/storage';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  CreateAccountService,
  Step,
  StepFlowService,
} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { LoadingService } from './../../../../../core/layout/services/loading.service';
import { ModalAlertComponent } from './../../modals/modal-alert/modal-alert.component';
import { ModalValidationComponent } from './../../modals/modal-validation/modal-validation.component';
import { Router } from '@angular/router';
import { IntroService } from '@wlp/ui-bs-intro';

@Component({
  selector: 'ui-t-retry-onboarding',
  templateUrl: './retry-onboarding.page.html',
  styleUrls: ['./retry-onboarding.page.scss']
})
export class RetryOnboardingPage implements AfterViewInit, OnInit, OnDestroy {

  public navigationBackground: string;
  public brand: string;
  public typeName: string;
  private account: any;

  nextStep: Partial<Step>;
  form: FormGroup;
  error: any[];
  block = false;
  accountType: string;
  isValid: boolean;
  modal: HTMLIonModalElement;
  step: string;
  currentStep: any;
  accountFound: boolean = false;

  private destroy$: Subject<null> = new Subject();

  private ctlClick = 0;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private accountTypeService: AccountTypeService,
    private navCtrl: NavController,
    private translate: UiBsTranslateService,
    private serviceCreateAccount: CreateAccountService,
    private modalController: ModalController,
    private stepsService: StepFlowService,
    private loadingService: LoadingService,
    private router: Router,
    private storageService: StorageService,
    private introService: IntroService,
  ) {
    this.form = this.serviceCreateAccount.createForm();
    this.error = this.serviceCreateAccount.getFormMessageError();
  }

  public async ngAfterViewInit() {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
    this.typeName = await this.accountTypeService.getNameType();
    this.introService.cleanStorage();
  }

  async ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.form.valueChanges.subscribe(term => {
      this.checkDocument()
    });

  }

  public checkDocument() {
    if (this.form.get('document').invalid) {
      this.ctlClick = 0;
      return;
    }
    this.ctlClick++;
    if (this.ctlClick > 1) {
      return;
    }
    this.loadingService.show();
    const data = {
      uuidWhitelabel: environment.wlp,
      document: this.form.get('document').value
    }

    this.serviceCreateAccount.findAccountsForRetryOnboarding(data)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          this.loadingService.hide();
          if (res.length === 0) {
            this.accountFound = false;
            this.notification(
              'warning-circle',
              'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.TITLE',
              'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.DESCRIPTION');
          } else {
            if (this.validStatus(res[0].status).reprovation == 'DOCUMENT_REPROVED'
              || this.validStatus(res[0].status).reprovation == 'INVALID_DATA'
              || this.validStatus(res[0].status).reprovation == 'INVALID_DATA_AND_DOCS'
            ) {
              this.account = {
                _id: res[0]._id,
                uuidWhiteLabel: res[0].uuidWhiteLabel,
                name: res[0].name,
                document: res[0].cpf,
                email: res[0].email,
                phone: res[0].phone,
                lastStep: res[0].lastStep,
                status: res[0].status
              };
              this.accountTypeService.setName(res[0].name);
              this.accountType = res[0].accountType.typeFlow;
              this.accountFound = true;
              this.accountTypeService.temporaryTypeAccount(this.accountType);
            } else if (res[0].status == 'FINISHED') {
              this.accountFound = false;
              this.notification(
                'warning-circle',
                'RETRY_ONBOARDING.MESSAGES.REGISTRATION_FINISHED.TITLE',
                'RETRY_ONBOARDING.MESSAGES.REGISTRATION_FINISHED.DESCRIPTION');
            } else {
              this.accountFound = false;
              this.notification(
                'warning-circle',
                'RETRY_ONBOARDING.MESSAGES.REGISTRATION_IN_PROCESS.TITLE',
                'RETRY_ONBOARDING.MESSAGES.REGISTRATION_IN_PROCESS.DESCRIPTION');
            }
          }
        }),
        catchError((err) => {
          this.loadingService.hide();
          this.accountFound = false;
          this.notification(
            'warning-circle',
            'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.TITLE',
            'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.DESCRIPTION');
          return of(null);
        })
      )
      .subscribe();
  }

  public async goContinue() {
    this.loadingService.show();
    this.serviceCreateAccount.documentAccount(this.account.document, this.accountType, environment.wlp, this.account.name, environment.appVersion)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          this.loadingService.hide();
          this.storageService.set('isRetryOnboarding', true);

          if ((res.status === 'IN_ONBORDING') && res.currentStep) {
            this.currentStep = '/onboarding' + res.currentStep;
          }
          await this.serviceCreateAccount.processPreAccount(res);
          this.nextStep = await this.stepsService.getSteps(environment, undefined, false, false);

          if (!res.isFirtsAccess) {
            this.block = true;
            this.serviceCreateAccount.prepareFormValidation(res);
            this.modalFlow(res);
          } else {
            this.block = false;
            this.serviceCreateAccount.prepareFormValidation();
          }
        }),
        retry(2),
        catchError((err) => {
          this.loadingService.hide();
          this.serviceCreateAccount.getErrorPreAccount(err);
          this.notification(
            'warning-circle',
            'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.TITLE',
            'RETRY_ONBOARDING.MESSAGES.ACCOUNT_NOT_FOUND.DESCRIPTION');
          return of(null);
        })
      )
      .subscribe();
  }

  private validStatus(status: string): any {
    switch (status) {
      case 'DOCUMENT_PHYSICAL_PERSON_REPROVED':
      case 'DOCUMENT_LEGAL_PERSON_REPROVED':
        return { reprovation: 'DOCUMENT_REPROVED' };
      case 'REGISTRATION_DATA_PHYSICAL_PERSON_INVALID':
      case 'REGISTRATION_DATA_LEGAL_PERSON_INVALID':
        return { reprovation: 'INVALID_DATA' };
      case 'DOCUMENT_AND_REGISTRATION_DATA_LEGAL_PERSON_REPROVED':
      case 'DOCUMENT_AND_REGISTRATION_DATA_PHYSICAL_PERSON_REPROVED':
        return { reprovation: 'INVALID_DATA_AND_DOCS' };
      default:
        return false;
    }
  }

  async modalFlow(response: any) {
    this.modal = await this.modalController.create({
      component: ModalValidationComponent,
      cssClass: 'modal-flow-container-inner-validation',
      componentProps: { data: response, step: this.step, form: this.form },
      backdropDismiss: false,
    });
    await this.modal.present();
    await this.modal.onDidDismiss().then(async (data) => {
      if (data.data) {
        this.isValid = data.data;
        if (response.email && response.phone) {
          this.block = true;
        }
        this.nextStep = {
          nextStep: this.currentStep ? this.currentStep : this.nextStep.currentStep
        };

        await this.stepsService.nextStep(this.navCtrl, this.nextStep);
      }
    });
  }

  private async notification(status: string, message: string, description?: string) {
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-notification',
      componentProps: {
        status: status,
        message: message,
        description: description,
        confirmBtn: 'OK',
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }

  async goBack() {
    this.navCtrl.navigateRoot('');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
