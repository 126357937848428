import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { ExtractService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { TransactionConsult } from '@wlp/ui-px-cashout';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { ModalExtractReviewComponent } from 'src/app/views/themes/sentinel/modals/modal-extract-review/modal-extract-review.component';
import * as moment from 'moment';
import { ExtractDataFilterPipe } from '../extract-data-filter.pipe';
import { ExtractUtil } from '../extract-util';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import * as FileSaver from "file-saver";

@Component({
  selector: 'ui-t-cnab-conciliation',
  templateUrl: './cnab-conciliation.page.html',
  styleUrls: ['./cnab-conciliation.page.scss'],
  providers: [ExtractDataFilterPipe, CurrencyPipe],
})
export class CnabConciliationPage extends ExtractUtil implements OnInit {
  public userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public description: string;
  public descriptionTwo: string;
  public title: string;

  constructor(
    public pipeFilter: ExtractDataFilterPipe,
    public pipeCurency: CurrencyPipe,
    public detection: ChangeDetectorRef,
    protected userInfoService: UserInfoService,
    protected extractService: ExtractService,
    public modalController: ModalController,
    private navCtrl: NavController,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    public loadingService: LoadingService,
  ) {
    super(userInfoService, pipeFilter, detection, modalController, loadingService);
  }

  public async ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    await this.loadUser();

    this.listReceipts = [];
    this.listReceiptsShow = [];

    this.title = 'CASH_OUT.EXTRACT_RECEIPT.CONCILIATION.HEADER.TITLE';
    this.description = 'CASH_OUT.EXTRACT_RECEIPT.CONCILIATION.HEADER.SUBTITLE_ONE';
    this.descriptionTwo = 'CASH_OUT.EXTRACT_RECEIPT.CONCILIATION.HEADER.SUBTITLE_SECOND';

    this.listReceipts = [];

    this.getReceipts(null);
  }

  async ionViewDidEnter() {
    /*   await this.loadUser(); */
  }

  ionViewWillLeave() {
    /*  this.listReceipts = [];
     this.listReceiptsShow = []; */
  }

  public getReceipts(type: string, initial?: Date) {
    if (initial === undefined) {
      initial = this.calInitialDate;
    }
    this.dateExtractInit = moment(initial);
    this.dateExtractEnd = moment(this.calEndDate);

    this.extractService
      .findByUuidAccountPaymentAndBetweenDate(
        new DatePipe('en-US').transform(initial, 'yyyy-MM-dd'),
        new DatePipe('en-US').transform(this.calEndDate, 'yyyy-MM-dd')
      )
      .subscribe(
        (res) => {
          this.listReceipts = res;
          this.setArrayForShowReceipts(true);
          this.calcPaginationReceipts();
          this.loadingService.hide();
        },
        (err) => {
          console.error(err);
          this.loadingService.hide();
        },
        () => this.cdr.detectChanges()
      );

  }

  public downloadFile(data: string, filename: string) {
    this.extractService.downloadExtractConciliation(data).subscribe(res => {
      FileSaver.saveAs(new Blob([res]), filename);
    });
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public changeNameLot(nameFile: string){
    if (nameFile) {
      return nameFile.split('.')[0];
    }

    return nameFile;
  }
}
