import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AccountPaymentFavoriteDTO, AccountPaymentFavoriteService, TypeEnum } from '@wlp/ui-bs-account-payment-favorite';
import { BilletPayerRequestDTO, ChargingService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { of } from 'rxjs';
import { Uf } from '@wlp/ui-bs-util';
import { catchError, retry, tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { PopoverFavoritesComponent } from 'src/app/views/themes/sentinel/modals/popover-favorites/popover-favorites.component';
import { environment } from 'src/environments/environment';
import { FavoritesDataFilterPipe } from '../../pages/cp-manager/cash-out/charging/favorites-data-filter.pipe';

type SwitchFavoritesOption = 'new' | 'favorites'

@Component({
  selector: 'modal-billet-favorites',
  templateUrl: './modal-billet-favorites.component.html',
  styleUrls: ['./modal-billet-favorites.component.scss'],
  providers: [FavoritesDataFilterPipe],
})

export class ModalBilletFavorites implements OnInit {
  public billetChargingForm: FormGroup;
  public errorMessages: any;
  public switchOptions: SwitchFavoritesOption;
  public switchFavorites: SwitchFavoritesOption = 'new';

  responseTransfer: any;
  listReceiptsShow: any;
  saveNewFavorite: any = false;
  realm: boolean;

  userProfile: UserInfoProfile;
  formPersonalAddress: FormGroup;
  formErrorMessages: any;

  isAdress: boolean;
  listUf: Uf[];
  favorite: any = null;

  @Input() onSubmit?: (payer: BilletPayerRequestDTO) => void;

  public billetChargingValue: any;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public service: ChargingService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    protected userInfoService: UserInfoService,
    private navCtrl: NavController,
    public accountPaymentFavoriteService: AccountPaymentFavoriteService,
    private storage: Storage,
    public router: Router,
    public popoverController: PopoverController,
    public pipeFilter: FavoritesDataFilterPipe,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    moment.locale('pt-br');
  }

  async ngOnInit() {
    this.setLanguage();
    this.resetForms();

    this.realm = this.accountPaymentFavoriteService.realmValidation(
      environment.wlThemeParameterization.wlTheme.realm.toUpperCase()
    );
    
    await this.loadUser();
    this.listUf = this.accountPaymentFavoriteService.getUf();
  }
  
  setLanguage() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  async loadUser() {
    try {
      this.userProfile = await this.userInfoService.getInfoProfile();
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  getAllFavorites() {
    this.accountPaymentFavoriteService
      .getAllFavoritesType(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, TypeEnum.BILLET)
      .pipe(
        tap((data) => {
          this.responseTransfer = data;
          this.listReceiptsShow = data;
          this.listReceiptsShow = this.accountPaymentFavoriteService.sortFavoritesListByName(this.listReceiptsShow);
        }),
        retry(2),
        catchError((_) => {
          return of(null);
        })
      )
      .subscribe();
  }

  handleClickContinue() {
    if (!this.validatePersonalFields()) return;
    this.isAdress = true;
  }

  validatePersonalFields() {
    const payerRequiredFields = ['document', 'nameComplete', 'email'] 
    return this.formIsValid(this.billetChargingForm, payerRequiredFields);
  }
  validateAddressFields() {
    const addressRequiredFields = ['postalCode', 'address', 'numberAddress', 'neighborhood', 'cityAddress', 'uf'] 
    return this.formIsValid(this.formPersonalAddress, addressRequiredFields);
  }
  
  formIsValid(form: FormGroup, fields: string[]): boolean {
    let isValid: boolean = true;
    for (var field of fields) {
      form.controls[field].markAsTouched();
      if (!form.controls[field].valid) {
        isValid = false;
      }
    }
    return isValid
  }

  handleClickSubmit() {
    if (!this.validateAddressFields()) return;
    if (!this.validatePersonalFields()) return;

    if (this.saveNewFavorite) this.saveFavorite();

    const {nameComplete, document, email} = this.billetChargingForm.controls;
    const documentType = document.value.trim().replace(/\D/g, '').length > 11 ? 'CNPJ' : 'CPF';
    const { postalCode, address, numberAddress, complementAddress, cityAddress, neighborhood, uf } = this.formPersonalAddress.controls;

    const payer: BilletPayerRequestDTO = {
      name: nameComplete.value,
      document: document.value,
      documentType: documentType,
      email: email.value,
      address: {
        zipCode: postalCode.value,
        street: address.value,
        streetNumber: numberAddress.value,
        streetComplement: complementAddress.value,
        city: cityAddress.value,
        neighborhood: neighborhood.value,
        state: uf.value,
      }
    }
    this.onSubmit(payer);
  }
  
  saveFavorite() {
    if (this.favorite) {
      let prepare: AccountPaymentFavoriteDTO;
      prepare = {
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.billetChargingForm.controls.nameComplete.value,
        document: this.billetChargingForm.controls.document.value,
        email: this.billetChargingForm.controls.email.value,
        codeBank: '',
        bankName: '',
        accountNumber: '',
        agencyNumber: '',
        typeAccount: '',
        zipCode: this.formPersonalAddress.controls.postalCode.value,
        address: this.formPersonalAddress.controls.address.value,
        addressNumber: this.formPersonalAddress.controls.numberAddress.value,
        addressComplement: this.formPersonalAddress.controls.complementAddress.value,
        neighborhood: this.formPersonalAddress.controls.neighborhood.value,
        city: this.formPersonalAddress.controls.cityAddress.value,
        state: this.formPersonalAddress.controls.uf.value,
        country: this.formPersonalAddress.controls.country.value,
        id: this.favorite.id,
        uuid: this.favorite.uuid,
      };

      if (this.favorite.id !== undefined) {
        this.accountPaymentFavoriteService
          .updateFavorite(prepare)
          .pipe(
            tap((data) => {
            }),
            catchError((_) => {
              return of(null);
            })
          )
          .subscribe();
      }
    } else {
      let prepare: AccountPaymentFavoriteDTO;

      prepare = {
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.billetChargingForm.controls.nameComplete.value,
        document: this.billetChargingForm.controls.document.value,
        email: this.billetChargingForm.controls.email.value,
        codeBank: '',
        bankName: '',
        accountNumber: '',
        agencyNumber: '',
        typeAccount: '',
        zipCode: this.formPersonalAddress.controls.postalCode.value,
        address: this.formPersonalAddress.controls.address.value,
        addressNumber: this.formPersonalAddress.controls.numberAddress.value,
        addressComplement: this.formPersonalAddress.controls.complementAddress.value,
        neighborhood: this.formPersonalAddress.controls.neighborhood.value,
        city: this.formPersonalAddress.controls.cityAddress.value,
        state: this.formPersonalAddress.controls.uf.value,
        country: this.formPersonalAddress.controls.country.value,
        type: TypeEnum.BILLET,
      };
      this.accountPaymentFavoriteService
        .saveFavorite(prepare)
        .pipe(
          tap((data) => {
            if (data.status) {}
          }),
          catchError((_) => {
            return of(null);
          })
        )
        .subscribe();
    }
  }

  checkValue(value) {
    value = value.replace(/[^\w\s]/gi, '');
    value = parseFloat(value);
    if (value < 2000) {
      this.billetChargingForm.controls.billetValue.setErrors({ min: true });
    }
  }

  switchChanged(favorite: SwitchFavoritesOption) {
    this.getAllFavorites();
    this.switchFavorites = favorite;
    this.isAdress = false;
  }

  async modelEditOrDeleteFavorite(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverFavoritesComponent,
      cssClass: 'popover-class',
      event: ev.event,
      translucent: true,
      mode: 'md',
    });
    popover.onDidDismiss().then((data) => {
      if (data.data == 'exclude') {
        this.deleteFavorite(ev.favorite);
      }
      if (data.data == 'edit') {
        this.storage.set('favoriteForEdit', ev.favorite).then(() => {
          this.storage.set('favoriteType', 'BILLET');
          this.switchFavorites = 'new';
          this.router.navigate(['/app/charging/favorites/charging']);
        });
      }
    });
    return await popover.present();
  }

  deleteFavorite(favorite) {
    this.loadingService.show();
    this.accountPaymentFavoriteService
      .deleteFavorite(favorite)
      .pipe(
        tap((data) => {
          if (data.status) {
            // this.statusTransaction('success', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.SUCCESS_FAVORITES_EXCLUDE', true);
            this.getAllFavorites();
          } else {
            // this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED_FAVORITES', true);
          }
          this.loadingService.hide();
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  handleFavoriteSelected(favorite) {
    this.favorite = favorite;

    this.billetChargingForm.setValue({
      document: favorite.document,
      nameComplete: favorite.name,
      email: favorite.email,
      dateDue: '',
      orderNumber: '',
      billetBodyObservations: '',
      descripiton: '',
      billetValue: '',
    });


    this.formPersonalAddress.setValue({
      document: favorite.document,
      name: favorite.document,
      email: favorite.email,
      postalCode: favorite.zipCode,
      address: favorite.address,
      numberAddress: favorite.addressNumber,
      complementAddress: favorite.addressComplement,
      neighborhood: favorite.neighborhood,
      cityAddress: favorite.city,
      uf: favorite.state,
      country: favorite.country,
    });

    this.switchFavorites = 'new';
  }

  public filterReceipt(value) {
    if (value) {
      this.listReceiptsShow = this.pipeFilter.transform(this.responseTransfer, value);
    } else {
      this.listReceiptsShow = this.responseTransfer;
    }
  }

  searchPostalCode(data: any) {
    this.accountPaymentFavoriteService.searchPostaCode(data.target.value);
  }

  resetForms() {
    this.billetChargingForm = this.service.createFormEmitBillet();
    this.errorMessages = this.service.getErrorMessages();
    this.formPersonalAddress = this.accountPaymentFavoriteService.createFormFavoriteCharging();
    this.formErrorMessages = this.accountPaymentFavoriteService.getFormMessageError();
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  closeTransferModal() {
    this.modalController.dismiss()
  }
}
