import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { DdaRoutingModule } from './dda-routing.module';

import { ListPage } from './list/list.page';
import {HeaderUserInfoModule} from "@wlp/ui-c-header-user-info";
import {UiBsTranslateModule} from "@wlp/ui-bs-translate";
import {UiCDatePickerModule} from "@wlp/ui-c-date-picker";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    DdaRoutingModule,
    HeaderUserInfoModule,
    UiBsTranslateModule,
    UiCDatePickerModule,
  ],
  declarations: [ListPage],
})
export class DdaModule {}
