import { StorageService } from '@wlp/ui-bs-oauth';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ContractService, StepFlowService, Step } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { environment } from 'src/environments/environment';

import { ModalAlertComponent } from '../../modals/modal-alert/modal-alert.component';
import { LoadingService } from './../../../../../core/layout/services/loading.service';

@Component({
  selector: 'app-status-account',
  templateUrl: './status-account-page.html',
  styleUrls: ['./status-account-page.scss'],
})
export class StatusAccountPage implements OnInit {

  public listPartnersContract: any[];
  public dataStep: Step;

  constructor(
    private navCtrl: NavController,
    private contractService: ContractService,
    private stepsService: StepFlowService,
    private router: Router,
    private toastCtrl: ToastController,
    private loadService: LoadingService,
    private modalController: ModalController,
    private translate: UiBsTranslateService,
    private storageService: StorageService
  ) {
  }

  public async ngOnInit() {
    this.loadService.show();
    (await this.contractService.getPartnersContract()).toPromise().then((res) => {
      this.loadService.hide();
      this.listPartnersContract = res;
    }).catch((e) => {
      // console.log(e);
      setTimeout(() => {
        this.loadService.hide();
        this.navCtrl.navigateRoot('/');
      }, 3000);
    });

    try {
      this.dataStep = await this.stepsService.getSteps(environment, this.router.url);
    } catch (e) {
      this.loadService.hide();
    }
    this.storageService.remove('isRetryOnboarding');
  }

  public goContinue() {
    this.nextStep();
  }

  public resendContractPartners() {
    this.contractService.sendEmailContractPartners()
      .then((res) => {
        if (res.status) {
          this.notification('success', this.translate.applyTranslate('ONBORDING.STATUS_ACCOUNT.MODAL.SUCCESS'));
        } else {
          this.notification('warning-circle', this.translate.applyTranslate('ONBORDING.STATUS_ACCOUNT.MODAL.WARNING'));
        }
      });
  }

  private async notification(status: string, message: string) {
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-notification-class',
      componentProps: {
        status,
        message,
        confirmBtn: this.translate.applyTranslate('ONBORDING.V2.BUTTON.CONFIRM'),
        confirmation: false,
        translate: true,
      },
      backdropDismiss: false,
    });
    return await modalSuccess.present();
  }

  private async nextStep() {
    await this.stepsService.nextStep(this.navCtrl, this.dataStep);
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.dataStep);
  }
}
