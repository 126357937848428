import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from '../../../../../../../../core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-privacy-policy-ec',
  templateUrl: './privacy-policy-ec.page.html',
  styleUrls: ['./privacy-policy-ec.page.scss'],
})
export class PrivacyPolicyEcPage {
  wlName: string;
  wlCompany: any;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.wlCompany = this.layoutConfigService.getConfig('wlTheme.company');
  }

  goBack() {
    this.navCtrl.back();
  }
  continue() {
    this.navCtrl.navigateRoot('onboarding-ec/contract-ec');
  }
}
