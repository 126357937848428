import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
})
export class SettingsPage implements OnInit {
  constructor() {}

  ngOnInit() {}
}
