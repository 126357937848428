import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { UiBsCashOutModule } from '@wlp/ui-bs-cashout';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiCAsideMenuLeftModule } from '@wlp/ui-c-aside-menu-left';
import { UiCCardInfoModule } from '@wlp/ui-c-card-info';
import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { UiCFilterReceiptModule} from '@wlp/ui-c-filter-receipt';
import { HeaderUserInfoModule } from '@wlp/ui-c-header-user-info';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCNotificationsModule } from '@wlp/ui-c-notifications';
import { UiCTransferReceiptModule } from '@wlp/ui-c-transfer-receipt';
import { BrMaskerModule } from 'br-mask';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';
import {DigitalAccountConsolidadedPage} from './digital-account-consolidaded/digital-account-consolidaded.page';
import { ListSchedulingPage } from './list-scheduling/list-scheduling.page';

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {UiCPaginatorModule} from '@wlp/ui-c-paginator';
import {InterceptService} from '../../../../../../../core/intercept/intercept.service';
import { ModalsModule } from '../../../../modals/modals.module';
import {CnabConciliationPage} from './cnab-conciliation/cnab-conciliation.page';
import { ExtractDigitalAccountPage } from './digital-account/extract-digital-account.page';
import { ExtractDataFilterPipe } from './extract-data-filter.pipe';
import { ExtractRoutingModule } from './extract-routing.module';
import { ExtractReceiptPage } from './receipt/extract-receipt.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgxMaskIonicModule.forRoot(),
    UiCAccountBalanceModule,
    UiBsCashInModule.forRoot({ device: 'WEB', config: environment }),
    BrMaskerModule,
    UiCAsideMenuLeftModule,
    RouterModule,
    UiCCardInfoModule,
    UiBsTranslateModule,
    UiCNotificationsModule,
    ExtractRoutingModule,
    UiBsCashOutModule,
    UiCDatePickerModule.forRoot({ environment: 'web' }),
    UiCPaginatorModule.forRoot({ device: 'WEB', config: environment }),
    UiCModalModule,
    HeaderUserInfoModule,
    UiCTransferReceiptModule,
    ModalsModule,
    UiCFilterReceiptModule,
  ],
  declarations: [
    ExtractReceiptPage,
    ExtractDigitalAccountPage,
    ExtractDataFilterPipe,
    ListSchedulingPage,
    CnabConciliationPage,
    DigitalAccountConsolidadedPage,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  entryComponents: [],
  bootstrap: [],
})
export class ExtractModule { }
