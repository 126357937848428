import { AfterViewInit, Component } from '@angular/core';
import { AccountTypeService} from "@wlp/ui-bs-signup";
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['sign-up.component.scss'],
  selector: 'ui-t-sign-up'
})
export class SignUpComponent implements AfterViewInit {
  public navigationBackground: string;
  public brand: string;
  public typeName: string;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private accountTypeService: AccountTypeService,
  ) {
  }

  public async ngAfterViewInit() {
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
    this.typeName = await this.accountTypeService.getNameType();
  }
  
  public chooseTitle(typeAccount: string) {
    switch(typeAccount) {
      case 'Pessoa Física' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PF';
      case 'Pessoa Jurídica' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PJ';
      case 'Profissional Liberal' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PF';
      case 'MEI' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PJ';
      case 'Escrow(PF)' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PF';
      case 'Escrow(PJ)' : return 'ONBORDING.V2.FORM.LABEL.TYPE_ACCOUNT_PJ';
      default : return typeAccount;
    } 
  }

}
