import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from './../../../../../core/layout/services/layout-config.service';
import { Router } from '@angular/router';
import { MaskService } from '@wlp/ui-bs-util';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-detail-dualauth.component.html',
  styleUrls: ['./modal-detail-dualauth.component.scss'],
  providers: [CurrencyPipe],
})
export class ModalDetailDualAuth {
  @Input() public title: string;
  @Input() public typeVoucher: string;
  @Input() public typeTransfer: string;
  @Input() public namePayer: string;
  @Input() public cpfCnpjPayer: string;
  @Input() public bankPayer: string;
  @Input() public agencyPayer: string;
  @Input() public currentAcountPayer: string;
  @Input() public nameDestination: string;
  @Input() public cpfCnpjDestinatario: string;
  @Input() public bankDestination: string;
  @Input() public agencyDestination: string;
  @Input() public accountDestination: string;
  @Input() public totalPay: number;
  @Input() public amountPaid: number;
  @Input() public dateSchedule: string;
  @Input() public dateExpiration: string;
  @Input() public interestValue: number;
  @Input() public fineValue: number;
  @Input() public discount: number;
  @Input() public dateRegister: number;
  @Input() public barCode: string;
  constructor(
    private maskService: MaskService,
    public pipeCurency: CurrencyPipe,
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
  ) {
  }

  typeDocument (document: string) {
    if (document.length > 11) {
      return 'CNPJ:';
    } else {
      return 'CPF:';
    }
  }

  public formatCnpjCpf(document) {
    return this.maskService.formatCnpjCpf(document);
  }
  
  public formatAccountBank(account) {
    return this.maskService.formatAccountBank(account);
  }

  confirm() {
    this.modalController.dismiss(true);
  }
}
