import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import {BilletStatusEnum} from '@wlp/ui-bs-cashin';
import { ExtractService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import {Paginated, PaginationParams} from '@wlp/ui-bs-parameter';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import {PaymentVoucherResponseDto, TemplatePaymentVoucherEmittedDto, TransactionConsult, AdvancedFilterVoucherDTO} from '@wlp/ui-px-cashout';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalExtractReviewComponent } from 'src/app/views/themes/sentinel/modals/modal-extract-review/modal-extract-review.component';
import { ExtractDataFilterPipe } from '../extract-data-filter.pipe';
import { ExtractUtil } from '../extract-util';

interface PaymentVoucherResponseDtoCheckable extends PaymentVoucherResponseDto {
  transactionIdConsult: string;
  isItemSelected: boolean;
}
@Component({
  selector: 'ui-t-extract-receipt',
  templateUrl: './extract-receipt.page.html',
  styleUrls: ['./extract-receipt.page.scss'],
  providers: [ExtractDataFilterPipe, CurrencyPipe],
  encapsulation: ViewEncapsulation.None,
})
export class ExtractReceiptPage extends ExtractUtil implements OnInit {
  public userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  private description: string;
  private title: string;

  public itemsPerPageOptions: number[] = [10, 20, 30, 50, 100];
  public itemsPerPage = 10;
  public iconSkipLast = './assets/icon/icon-material-skip-last.svg';
  public iconNavigateLast = './assets/icon/icon-material-navigate-last.svg';
  public iconNavigateNext = './assets/icon/icon-material-navigate-next.svg';
  public iconSkipNext = './assets/icon/icon-material-skip-next.svg';

  private voucherTEDCodes: number[] = [117, 120, 121];
  private voucherOnUsCodes: number[] = [901, 124];
  private voucherPaymentCodes: number[] = [112, 122, 123, 902];

  private voucherToBeFiltred: PaymentVoucherResponseDtoCheckable[]
  private voucher: PaymentVoucherResponseDtoCheckable[];
  private voucherPaginated: Paginated<PaymentVoucherResponseDto[]> = null;
  public isParentSelected: boolean = false;
  public availableReceiptsCounter  : number = 0;
  public selectedReceiptsCounter: number = 0;
  public selectedReceipts: string[] = [];

  public voucherHTML: SafeHtml = '';

  constructor(
    public pipeFilter: ExtractDataFilterPipe,
    public pipeCurency: CurrencyPipe,
    public detection: ChangeDetectorRef,
    protected userInfoService: UserInfoService,
    protected extractService: ExtractService,
    public modalController: ModalController,
    private navCtrl: NavController,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    public loadingService: LoadingService,
    private sanitizer: DomSanitizer,
  ) {
    super(userInfoService, pipeFilter, detection, modalController, loadingService);
  }

  public async ngOnInit() {
    this.loadingService.show();
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    await this.loadUser();
    this.voucher = [];
    this.voucherToBeFiltred = []

    this.activatedRoute.params
      .subscribe((params) => {
        this.tpVoucher = params.type;
        this.setPageTitleAndDescritpion(params);
        this.getReceipts(this.tpVoucher);
      })
      .unsubscribe();
    this.voucher = [];
    this.voucherToBeFiltred = []
  }

  setPageTitleAndDescritpion(params: Params) {
    switch(params.type) {
      case 'on-us':
      case 'ted':
        this.title = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.TITLE_TED';
        this.description = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.SUBTITLE_ONE';
        break;
      case 'payment':
        this.title = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.TITLE_BILLET';
        this.description = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.SUBTITLE_SECOND';
        break;
      case 'full':
      default:
        this.title = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.TITLE_VOUCHER';
        this.description = 'CASH_OUT.EXTRACT_RECEIPT.HEADER.SUBTITLE_ONE';
        break;
    }
  }

  private getVoucherCodes(): string {
    let typeVoucher: string = undefined;
    switch (this.tpVoucher) {
      case 'ted':
        typeVoucher = this.voucherTEDCodes.join(',');
        break;
      case 'on-us':
        typeVoucher = this.voucherOnUsCodes.join(',');
        break;
      case 'payment':
        typeVoucher = this.voucherPaymentCodes.join(',');
        break;
    }

    return typeVoucher;
  }

  public getReceipts(type: string, initial?: Date, paginationParams?: PaginationParams) {
    if (initial === undefined) {
      initial = this.calInitialDate;
    }
    this.dateExtractInit = moment(initial);
    this.dateExtractEnd = moment(this.calEndDate);

    const typeVoucher: string = this.getVoucherCodes();

    const startDate: string = new DatePipe('en-US').transform(initial, 'yyyy-MM-dd');
    const endDate: string = new DatePipe('en-US').transform(this.calEndDate, 'yyyy-MM-dd');

    const typeVoucherParam = typeVoucher ? encodeURI(typeVoucher) : undefined;

    this.findVoucherPaginated(startDate, endDate, typeVoucherParam, paginationParams)
      .then(() => {
        this.cdr.detectChanges();
        this.loadingService.hide();
      });
  }

  private async findVoucherPaginated(startDate?: string, endDate?: string, type?: string, paginationParams?: PaginationParams) {
    if (!paginationParams) {
      paginationParams = new PaginationParams(1, this.itemsPerPage);
    }
    try {
      this.voucherPaginated = await this.extractService.findVoucherPaginated(
        this.userProfile.uuidAccount,
        startDate,
        endDate,
        type,
        paginationParams,
      ).toPromise();
      this.voucher = this.voucherPaginated.data as PaymentVoucherResponseDtoCheckable[];
      this.voucherToBeFiltred = this.voucher
      this.voucher.forEach(obj => {
        obj.isItemSelected = false;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingService.hide();
      this.cdr.detectChanges();
    }
  }

  public  getTransaction(data: any) {

    this.extractService.findTemplateVoucherByTransactionsId([data.transactionIdConsult]).subscribe((res) => {
      if ( res[0]) {
        const voucherHTML: SafeHtml = !!res[0].emittedVoucher ? this.sanitizer.bypassSecurityTrustHtml(res[0].emittedVoucher) : null;
        this.showReceipt(res[0], 'CASH_OUT.TRANSFER_RECEIPT.HEADER.SUBTITLE', voucherHTML);
      } else {
         this.extractService.getTransaction(data.transactionIdConsult).subscribe((res) => {
          this.showReceipt(res, 'CASH_OUT.TRANSFER_RECEIPT.HEADER.SUBTITLE', null);
        });
      }
    });
  }

  private async showReceipt(data, title: string, voucherHTML) {
    const modal = await this.modalController.create({
      component: ModalExtractReviewComponent,
      cssClass: 'extract-review',
      componentProps: {
        confirmationData: data,
        title,
        isEmittedVoucher: !!voucherHTML,
        voucherHTML: voucherHTML,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public typeVoucher(code) {
    switch (code) {
      case '112':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_112';
      case '902':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_902';
      case '903':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_903';
      case '904':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_904';
      case '905':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_905';
      case '901':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_901';
      case '117':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_117';
      case '213':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_213';
      case '805':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_805';
      case '120':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_120';
      case '209':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_209';
      case '918':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_918';
      case '919':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_919';
      case '121':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_121';
      case '122':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_122';
      case '123':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_123';
      case '124':
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_124';
      default:
        return 'CASH_OUT.EXTRACT_RECEIPT.TYPE_RECEIPT_DEFAULT';
    }
  }

  public selectAllReceipts () {
    setTimeout(() => { 
        this.voucher.forEach(voucher => {
          if (voucher.isEmittedVoucher) {
            voucher.isItemSelected = this.isParentSelected;
          }
        });
    }, 1)  
  }

  public updateCheckboxState() {
    this.selectedReceiptsCounter = this.voucher
      .filter(voucher => voucher.isEmittedVoucher && voucher.isItemSelected).length;

    this.availableReceiptsCounter = this.voucher
      .filter(voucher => voucher.isEmittedVoucher).length;

    this.isParentSelected = this.selectedReceiptsCounter === this.availableReceiptsCounter;

    this.selectedReceipts = this.voucher
      .filter(voucher => voucher.isItemSelected && voucher.isEmittedVoucher)
      .map(scheduled => scheduled.transactionIdConsult);
  }

  public downloadReceipts(){

    this.loadingService.show();

    const startDate: string = new DatePipe('en-US').transform(this.dateExtractInit, 'yyyy-MM-dd');
    const endDate: string = new DatePipe('en-US').transform(this.dateExtractEnd, 'yyyy-MM-dd');

    if (this.isParentSelected) {

      let typeVoucher: string = this.getVoucherCodes();

      const filter: AdvancedFilterVoucherDTO = {
        uuidAccountPayment: this.userProfile.uuidAccount,
        type: typeVoucher,
        startDate: startDate,
        endDate: endDate,
      }

      this.extractService.findVoucherTemplatesByFilter(filter).subscribe((res) => {
        let allTemplates = res.join('\n\n');
        return this.saveVoucherDownloadedFile(allTemplates);
      });
    } else {
      this.extractService.findTemplateVoucherByTransactionsId(this.selectedReceipts).subscribe((res) => {
        let allTemplates = '';
        for (let i = 0; i < res.length; i++) {
          if (i > 0) {
            allTemplates += '\n\n';
          }
          allTemplates += res[i].emittedVoucher;
        }
        return this.saveVoucherDownloadedFile(allTemplates);
      });

    }

  }

  public filterReceipt(value) {
    if (value !== '') {

      this.searchText = value;
      this.voucherToBeFiltred = []
      this.voucherToBeFiltred = this.pipeFilter.transform(this.voucher, this.searchText)

      return;
    }

    this.voucherToBeFiltred = this.voucher
  }

  private saveVoucherDownloadedFile(content: string) {
    return this.extractService.downloadStatementPdf(content).subscribe((res) => {
      this.loadingService.hide();
      const currentDate = new Date();
      const fileName = `Comprovante-${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getUTCFullYear()}.pdf`;
      FileSaver.saveAs(new Blob([res]), fileName);
    });
  }

  public async handleChangeItemsPerPage(itemsPerPage: number) {
    this.resetSelectedReceipts();
    this.itemsPerPage = itemsPerPage;
    const paginationParams = new PaginationParams(0, itemsPerPage);
    await this.getReceipts(this.tpVoucher, this.dateExtractInit, paginationParams);
  }
  public async handleGoToFirstPage() {
    this.resetSelectedReceipts();
    const paginationParams = new PaginationParams(1, this.itemsPerPage);
    await this.getReceipts(this.tpVoucher, this.dateExtractInit, paginationParams);
  }
  public async handleGoToLastPage() {
    this.resetSelectedReceipts();
    const lastPage = this.voucherPaginated ? this.voucherPaginated.pagination.totalPages : 0;
    const paginationParams = new PaginationParams(lastPage, this.itemsPerPage);
    await this.getReceipts(this.tpVoucher, this.dateExtractInit, paginationParams);
  }
  public async handleGoToNextPage() {
    this.resetSelectedReceipts();
    const next = this.voucherPaginated ? this.voucherPaginated.pagination.page + 1 : 0;
    const paginationParams = new PaginationParams(next, this.itemsPerPage);
    await this.getReceipts(this.tpVoucher, this.dateExtractInit, paginationParams);
  }
  public async handleGoToPrevPage() {
    this.resetSelectedReceipts();
    const prev = this.voucherPaginated ? this.voucherPaginated.pagination.page - 1 : 0;
    const paginationParams = new PaginationParams(prev, this.itemsPerPage);
    await this.getReceipts(this.tpVoucher, this.dateExtractInit, paginationParams);
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public resetSelectedReceipts() {
    this.selectedReceipts = [];
    this.isParentSelected = false;
    this.availableReceiptsCounter = 0;
    this.selectedReceiptsCounter = 0;
  }
}
