import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountRechargeService, BilletService, RechargeBilletResponse } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { ModalAlertComponent } from './../modal-alert/modal-alert.component';
import * as FileSaver from 'file-saver';
@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-billet-review.component.html',
  styleUrls: ['./modal-billet-review.component.scss'],
})
export class ModalBilletReviewComponent implements OnDestroy, OnInit {

  @Input() public billetRequest: boolean;
  @Input() public data: any;

  public billetData: any;
  public billetValue: any;
  public userProfile: UserInfoProfile;

  constructor(
    public modalController: ModalController,
    private accountRechargeService: AccountRechargeService,
    private loadingService: LoadingService,
    @Inject(DOCUMENT) private document: Document,
    private navCtrl: NavController,
    protected userInfoService: UserInfoService,
    private billetService: BilletService
  ) { }

  ngOnInit() {
    if (this.billetRequest) {
      this.loadingService.show();
      this.accountRechargeService.getValue().subscribe((obs) => {
        if (!obs) {
          this.loadingService.hide();
          this.navCtrl.navigateRoot('cp-manager/account-recharge');
        } else {
          const userDetails = this.userInfoService.getInfoProfile();
          userDetails.then(user => {
            this.userProfile = user;

            // this.billetValue = Number(obs.valueField.replace(" ", "0").replace('.', '').replace(',', '.'));

            // console.log(parseFloat(obs.valueField.replace('.', '').replace(',', '.')));

            this.billetValue = parseFloat(obs.valueField);

            this.accountRechargeService.generateBillet(parseFloat(this.billetValue), user.uuidAccount, user.uuidWhiteLabel)
              .subscribe((res) => {
                this.loadingService.hide();
                if (res.message) {
                  this.dismiss(true);
                  this.alert('warning-circle', res.message, false);
                  return;
                }
                this.billetData = res;
              });
          });
        }
      });

    } else {
      this.billetData = this.data;
    }
  }

  ngOnDestroy() {
    this.accountRechargeService.unSubscribe();
  }

  dismiss(error?: boolean) {
    this.modalController.dismiss(error).then((res) => { }, (err) => {
      console.error(err);
    });
  }

  printBilletOnPage(msg: string) {
    try {
      this.loadingService.show();
      this.billetService.getBilletDownload(this.billetData.transactionId)
        .subscribe(res => {          
          this.loadingService.hide();
          FileSaver.saveAs(new Blob([res]), `Boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
        })
    } catch (error) {
      this.loadingService.hide();
    }
  }

  shareBilletOnPage(msg: string) {
  }

  copyContent() {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.billetData.typeableLine;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  public async alert(status: string, message: string, confirmation?: boolean) {
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: this.messageTranslate(message),
        confirmBtn: 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.BUTTON.CANCEL',
        confirmation: confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });
    return await modalStatus.present();
  }

  private messageTranslate(message: string): string {
    switch (message) {
      case 'Limite de boleto excedido' : return 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED_LIMIT_EXCEEDED';
      case 'Erro ao gerar boleto!' : return 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED';
      default : return message;
    }
  }
}
