import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ui-t-billing-billet-emit',
  templateUrl: './billet-emit.page.html',
  styleUrls: ['./billet-emit.page.scss']
})
export class BilletEmitPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
