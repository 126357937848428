import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsDeviceValidationModule } from '@wlp/ui-bs-device-validation';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCDeviceModule } from '@wlp/ui-c-device';
import { UiCSendCodeModule } from '@wlp/ui-c-send-code';
import { UiCTimelineModule } from '@wlp/ui-c-timeline';
import { UiCWarningModule } from '@wlp/ui-c-warning';

import { ComponentsModule } from '../../../../../../core/shared/modules/components.module';
import { CreatePasswordPage } from './create-password/create-password.page';
import { DeviceValidationRoutingModule } from './device-validation-routing.module';
import { DevicePage } from './device/device.page';
import {StepServiceResolver} from "../../../functions/resolver/step-service-resolver";
import {InterceptService} from "../../../../../../core/intercept/intercept.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {UiCCreatePasswordModule} from "@wlp/ui-c-create-password";

@NgModule({
  imports: [
    FormsModule,
    IonicModule,
    UiCDeviceModule,
    ComponentsModule,
    UiCSendCodeModule,
    ReactiveFormsModule,
    UiBsDeviceValidationModule,
    DeviceValidationRoutingModule,
    UiCTimelineModule,
    UiCWarningModule,
    UiCContainerBrandModule,
    CommonModule,
    UiBsTranslateModule,
    AngularSvgIconModule.forRoot(),
    UiCCreatePasswordModule,
  ],
  declarations: [DevicePage, CreatePasswordPage],
  providers: [
    StepServiceResolver,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class DeviceValidationModule {}
