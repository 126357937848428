import { StorageService } from '@wlp/ui-bs-oauth';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { PersonalAddressService, ResponsibleMeiService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { Subject } from 'rxjs';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-personal-adress',
  templateUrl: './personal-address.modal.html',
  styleUrls: ['./personal-address.modal.scss'],
})
export class PersonalAddressModal implements OnInit, OnDestroy {
  listUf: Array<any>;
  navigationBackground: string;

  title: string;
  description: string;
  public form: any;

  @Input() partner: any;
  @Input() partnerId: string;
  @Input() applyForm: any
  @Input() step: any;

  private destroy$: Subject<null> = new Subject();

  public personalAddressForm: FormGroup = null;
  public errorPersonalAddressMessages: any;

  constructor(
    private personalAddressService: PersonalAddressService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private modalActive: ModalController,
    private responsibleService: ResponsibleMeiService,
    private prepareFormDevolution: PreparFormDevolutionService,
    private load: LoadingService,
    private storageService: StorageService
  ) {
    this.listUf = [];
    this.listUf = this.personalAddressService.getUf();
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    // terceiro formulario
    this.personalAddressForm = this.responsibleService.getBusinessAddressForm();
    this.errorPersonalAddressMessages = this.responsibleService.getFormBusinessAddressError();

    this.title = 'Estamos quase lá!';
    this.description = 'Precisamos que nos informe seu endereço:';
  }

  ngOnInit(): void {
    this.applyPersonalAddressData(this.applyForm)
  }

  applyPersonalAddressData(form) {
    this.personalAddressForm = this.responsibleService.getBusinessAddressForm();
    this.errorPersonalAddressMessages = this.responsibleService.getFormBusinessAddressError();   

    if (form != null) {
      this.personalAddressForm.get('postalCode').setValue(form.postalCode === null ? "" : form.postalCode);
      this.personalAddressForm.get('address').setValue(form.address === null ? "" : form.address);
      this.personalAddressForm.get('numberAddress').setValue(form.numberAddress === null ? "" : form.numberAddress);
      this.personalAddressForm.get('complementAddress').setValue(form.complementAddress === null ? "" : form.complementAddress);
      this.personalAddressForm.get('country').setValue(form.country === null ? "" : form.country);
      this.personalAddressForm.get('uf').setValue(form.uf === null ? "" : form.uf);
      this.personalAddressForm.get('neighborhood').setValue(form.neighborhood === null ? "" : form.neighborhood);
      this.personalAddressForm.get('cityAddress').setValue(form.cityAddress === null ? "" : form.cityAddress);

    }

    if (this.step != undefined) {
      this.getPersonalDataAddress();
    }
  }

  verifyCep(ev: any) {
    this.responsibleService.searchPostaCode(ev.target.value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goBack() {
    this.modalActive.dismiss(false);
  }

  async goContinue() {
    const form = this.personalAddressForm.getRawValue();
    await this.responsibleService.sendFormBusinessAddress(form, this.partnerId);
    if (this.step != undefined) {
      this.storageService.get(this.partnerId).then(res => {
        let index = res.steps.indexOf("PERSONAL_ADDRESS");
        if (index > -1) {
          res.steps.splice(index, 1);
          this.storageService.set(this.partnerId, { id: this.partnerId, steps: res.steps });
        }
      })
    }


    this.modalActive.dismiss(form);
  }

  showTypes(): string[] {
    const arrTypes = [
      'ONBORDING.V2.FORM.LABEL.MASTER',
      'ONBORDING.V2.FORM.LABEL.ATTORNEY',
      'ONBORDING.V2.FORM.LABEL.LEGAL_REPRESENTATIVE',
      'ONBORDING.V2.FORM.LABEL.PARTNER',
      'ONBORDING.V2.FORM.LABEL.SIGNATURE'
    ];

    return arrTypes.filter((arr, index) => (this.partner.types[index])).join(',').split(',');
  }
  closeModal() {
    this.modalActive.dismiss(null);
  }

  async getPersonalDataAddress() {
    this.load.show();
    let item = this.step.steps.find(element => {
      if (element.name === "PERSONAL_ADDRESS") {
        return element;
      }
    });
    if (item != undefined) await this.prepareFormDevolution.prepareFormDevolutionPartners(item.fields, this.personalAddressForm);
    this.stopLoad();
  }


  stopLoad() {
    setTimeout(() => {
      this.load.hide()
    }, 2000);
  }
}
