import { Component, Input } from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import {ModalMigrationSuccessComponent} from '../modal-migration-success/modal-migration-success.component';
import {CheckMigrationResponseDto, MigrationService} from '@wlp/ui-bs-dashboard';
import {UserInfoProfile} from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import {LoadingService} from "../../../../../core/layout/services/loading.service";

@Component({
  selector: 'modal-migration',
  templateUrl: './modal-migration.component.html',
  styleUrls: ['./modal-migration.component.scss'],
})
export class ModalMigrationComponent {

  @Input() migration: CheckMigrationResponseDto;
  @Input() userProfile: UserInfoProfile;
  @Input() balance: string;
  @Input() uuidAccountPayment: string;

  public isNotification = true;
  public isContract = false;
  public isContractAccept = false;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private navCtrl: NavController,
    private migrationService: MigrationService,
    private loadingService: LoadingService,
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

  }

  public nextStepContract() {
    this.isNotification = false;
    this.isContract = true;
  }

  public async nextStepNewAccount() {
    this.loadingService.show();
    this.migrationService.acceptMigration().subscribe( res => {
      this.modalMigrationNotification(this.migration.bank, this.migration.branch, this.migration.accountNumber, res.status, res.error);
    }, error => {
      this.loadingService.hide();
      console.log('Erro ao realizar processamento: ', error);
      this.modalMigrationNotification(this.migration.bank, this.migration.branch, this.migration.accountNumber, false, true);
    }, () => this.loadingService.hide());

    // this.modalMigrationNotification(this.migration.bank, this.migration.branch, this.migration.accountNumber, true, false);
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  checkedToggle(event) {
    this.isContractAccept = event.detail.checked;
  }

  async modalMigrationNotification(bankLiquidation: string, agency: string, account: string,
                                          isNewAccount: boolean, isNewAccountError: boolean) {

    await this.modalController.dismiss();

    const modal = await this.modalController.create({
      component: ModalMigrationSuccessComponent,
      cssClass: 'modal-migration-success-class',
      componentProps: {
        bankLiquidation: bankLiquidation,
        agency: agency,
        account: account,
        initiails: this.userProfile.nameInitials,
        isNewAccount: isNewAccount,
        isNewAccountError: isNewAccountError,
      },
    });
    return await modal.present();
  }
}
