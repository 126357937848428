import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AccountPaymentFavoriteDTO, AccountPaymentFavoriteService, TypeEnum } from '@wlp/ui-bs-account-payment-favorite';
import {
  AccountBilletRequest,
  AccountRechargeService,
  BilletService,
  BilletTypeEnum,
  ChargingService,
} from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalBilletReviewChargingComponent } from 'src/app/views/themes/sentinel/modals/modal-billet-review-charging/modal-billet-review-charging.component';
import { ModalTokenComponent } from 'src/app/views/themes/sentinel/modals/modal-token/modal-token.component';
import { PopoverFavoritesComponent } from 'src/app/views/themes/sentinel/modals/popover-favorites/popover-favorites.component';
import { environment } from 'src/environments/environment';
import { FavoritesDataFilterPipe } from '../../pages/cp-manager/cash-out/charging/favorites-data-filter.pipe';
import { ModalAlertComponent } from '../modal-alert/modal-alert.component';


@Component({
  selector: 'modal-billet-emit',
  templateUrl: './modal-billet-emit.component.html',
  styleUrls: ['./modal-billet-emit.component.scss'],
  providers: [FavoritesDataFilterPipe],
})

export class ModalBilletEmit implements OnInit {
  public billetChargingForm: FormGroup;
  public errorMessages: any;
  public switchFavorites: any = 'new';

  responseTransfer: any;
  listReceiptsShow: any;
  selectedFavorite: boolean;
  toogleFavorite: any = false;
  realm: boolean;

  userProfile: UserInfoProfile;
  formPersonalAddress: FormGroup;
  formErrorMessages: any;

  isAdress: boolean;
  textButton: string;
  listUf: Array<any>;
  formBillet: any;
  favoriteId: any;
  favorite: any;

  private readonly OPERATION: string = '1002';

  public billetChargingValue: any;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public service: ChargingService,
    private serviceBillet: BilletService,
    private modalController: ModalController,
    private accountRechargeService: AccountRechargeService,
    private loadingService: LoadingService,
    protected userInfoService: UserInfoService,
    private navCtrl: NavController,
    public accountPaymentFavoriteService: AccountPaymentFavoriteService,
    private storage: Storage,
    public router: Router,
    public popoverController: PopoverController,
    public pipeFilter: FavoritesDataFilterPipe,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    moment.locale('pt-br');
  }

  ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.resetForms();

    this.realm = this.accountPaymentFavoriteService.realmValidation(
      environment.wlThemeParameterization.wlTheme.realm.toUpperCase()
    );
    this.loadUser();

    this.favoriteId = undefined;

    this.isAdress = false;
    this.textButton = 'Continuar';
    this.listUf = this.accountPaymentFavoriteService.getUf();
  }

  closeTransferModal() {
    this.modalController.dismiss()
  }

  continueToAdress() {
    this.isAdress = true;
  }

  confirm(body) {
    this.formBillet = body;  
    if (this.favorite != undefined) {
      this.formPersonalAddress.setValue({
        document: this.favorite.document,
        name: this.favorite.document,
        email: this.favorite.email,
        postalCode: this.favorite.zipCode,
        address: this.favorite.address,
        numberAddress: this.favorite.addressNumber,
        complementAddress: this.favorite.addressComplement,
        neighborhood: this.favorite.neighborhood,
        cityAddress: this.favorite.city,
        uf: this.favorite.state,
        country: this.favorite.country,
      })
    } else {
      this.formPersonalAddress.setValue({
        document: body.document,
        name: body.nameComplete,
        email: body.email,
        address: "",
        cityAddress: "",
        complementAddress: "",
        country: "",
        neighborhood: "",
        numberAddress: "",
        postalCode: "",
        uf: "",
      });
    }
    this.isAdress = true;
  }

  confirmEmit() {
    console.log(this.billetChargingForm)
    this.switchFavorites = "favorites"
    // this.token(this.formBillet);
  }

  async showBilletReviewChargingModal(body, billetResponse) {
    const modal = await this.modalController.create({
      component: ModalBilletReviewChargingComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        billetRequest: true,
        billet: body,
        billetResponse: billetResponse,
      },
      backdropDismiss: false,
    });

    modal.onDidDismiss().then((data) => {
      this.navCtrl.navigateRoot('app');
    });
    return await modal.present();
  }

  public async token(body) {
    const modalToken = await this.modalController.create({
      component: ModalTokenComponent,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: true,
        codeOperation: this.OPERATION,
      },
    });
    modalToken.onDidDismiss().then((data) => {
      if (data['data']) {
        this.validateOperation(body, data.role);
        /* this.switchFavorites = "favorites"; */
      }
    });
    return await modalToken.present();
  }

  checkValue(value) {
    value = value.replace(/[^\w\s]/gi, '');
    value = parseFloat(value);
    if (value < 2000) {
      this.billetChargingForm.controls.billetValue.setErrors({ min: true });
    }
  }

  prepairBodyObser(value) {
    if (value.length > 60) {
      const partOne = value.slice(0, 60);
      const partTwo = value.slice(60, 120);
    }
  }

  generateBillet(prepare: AccountBilletRequest, body) {
    this.loadingService.show();
    this.serviceBillet
      .generateBillet(prepare)
      .pipe(
        tap((data) => {
          if (data.message == null && data.isExecuted) {
            this.loadingService.hide();
            console.log(body, data)
            this.showBilletReviewChargingModal(body, data);
          } else {
            this.loadingService.hide();
            if (data.message === 'Erro ao gerar boleto!') {
              this.statusTransaction(
                'warning-circle',
                'CASH_OUT.ACCOUNT_RECHARGE_REVIEW_CHARGING.MESSAGES.FAILED_BILLET_GENERATION',
                false
              );
              this.navCtrl.navigateRoot('app/dashboard');
            } else {
              this.statusTransaction('warning-circle', data.message, false);
              this.navCtrl.navigateRoot('app/dashboard');
            }
          }
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();

    try {
      this.saveFavorite(this.toogleFavorite);
    } catch (e) {
      console.error('Não foi possível salvar favoritos');
    }
  }

  public validateOperation(body, token) {
    this.loadingService.show();
    this.accountRechargeService.saveValue(this.billetChargingForm.value);

    this.serviceBillet
      .validateOperation(
        this.userProfile.uuidWhiteLabel,
        this.userProfile.uuidAccount,
        this.billetChargingForm.value.billetValue
      )
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('warning-circle', 'CASH_OUT.ACCOUNT_RECHARGE_REVIEW_CHARGING.MESSAGES.FAILED', false);
          return;
        } else {
          if (!res.isAllowed) {
            this.statusTransaction(
              'warning-circle',
              'CASH_OUT.ACCOUNT_RECHARGE_REVIEW_CHARGING.MESSAGES.FAILED_BILLET_GENERATION',
              false,
            );
            return;
          } else {
            const date = body.dateDue.split('/');
            const dataValidation = new Date(Number(date[2]), Number(date[1]) - 1, Number(date[0]));
            let value = parseFloat(body.billetValue.replace('.', '').replace(',', '.'))/100;
            const prepare = {
              uuidWhitelabel: this.userProfile.uuidWhiteLabel,
              uuidAccountPaymet: this.userProfile.uuidAccount,
              value: value,
              emailPayer: body.email,
              documentPayer: body.document,
              namePayer: body.nameComplete.toUpperCase(),
              observation: body.billetBodyObservations.toUpperCase(),
              requestNumber: body.orderNumber,
              requestDescription: body.descripiton.toUpperCase(),
              billetDueTime: dataValidation.toISOString(),
              type: BilletTypeEnum.CHARGING,
              billetHeader: 'Boleto de Cobrança',
              codeAuthorization: token,
              address: {
                zipCode: this.formPersonalAddress.controls.postalCode.value,
                street: this.formPersonalAddress.controls.address.value,
                streetNumber: this.formPersonalAddress.controls.numberAddress.value,
                streetComplement: this.formPersonalAddress.controls.complementAddress.value,
                neighborhood: this.formPersonalAddress.controls.neighborhood.value,
                city: this.formPersonalAddress.controls.cityAddress.value,
                state: this.formPersonalAddress.controls.uf.value,
                country: this.formPersonalAddress.controls.country.value,
              },
            };
            this.generateBillet(prepare, body);
            // this.formPersonalAddress
            return;
          }
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.accountRechargeService.getErrorAccountRecharge(err);
        this.statusTransaction('warning-circle', 'CASH_OUT.ACCOUNT_RECHARGE_REVIEW_CHARGING.MESSAGES.FAILED', false);
      });
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.getTaxBillet();
        // console.log('PROFILE', this.userProfile);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private getTaxBillet() {
    this.serviceBillet
      .getBilletCreateTax(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, '701')
      .then((res) => {
        if (res) {
          this.billetChargingValue = res.value;
        }
      });
  }

  public async statusTransaction(status: string, message: string, confirmation?: boolean) {
    this.loadingService.hide();
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: message,
        confirmBtn: 'CASH_OUT.ACCOUNT_CHARGING.CLOSED_BUTTON',
        confirmation: confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then((data) => {
      if (data['data'] === true) {
        // this.navCtrl.navigateRoot('app/transfer/receipt');
      } else {
        /* this.navCtrl.navigateRoot('app'); */
      }
    });
    return await modalSuccess.present();
  }

  segmentChanged(favorite) {
    this.getAllFavorites();
    this.switchFavorites = favorite;
    if (this.isAdress) {
      this.isAdress = false;
    }
  }

  getAllFavorites() {
    this.accountPaymentFavoriteService
      .getAllFavoritesType(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, TypeEnum.BILLET)
      .pipe(
        tap((data) => {
          this.responseTransfer = data;
          this.listReceiptsShow = data;
          this.listReceiptsShow = this.accountPaymentFavoriteService.sortFavoritesListByName(this.listReceiptsShow);
        }),
        retry(2),
        catchError((_) => {
          return of(null);
        })
      )
      .subscribe();
  }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: PopoverFavoritesComponent,
      cssClass: 'popover-class',
      event: ev.event,
      translucent: true,
      mode: 'md',
    });
    popover.onDidDismiss().then((data) => {
      if (data.data == 'exclude') {
        this.deleteFavorite(ev.favorite);
      }
      if (data.data == 'edit') {
        this.storage.set('favoriteForEdit', ev.favorite).then(() => {
          this.storage.set('favoriteType', 'BILLET');
          this.switchFavorites = 'new';
          this.router.navigate(['/app/charging/favorites/charging']);
        });
      }
    });
    return await popover.present();
  }

  deleteFavorite(favorite) {
    this.loadingService.show();
    this.accountPaymentFavoriteService
      .deleteFavorite(favorite)
      .pipe(
        tap((data) => {
          if (data.status) {
            // this.statusTransaction('success', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.SUCCESS_FAVORITES_EXCLUDE', true);
            this.getAllFavorites();
          } else {
            // this.statusTransaction('failed', 'CASH_OUT.TRANSFER_ON_US.MESSAGES.FAILED_FAVORITES', true);
          }
          this.loadingService.hide();
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  optionsFavorites(favorite) {
    this.favoriteId = favorite.id;
    this.favorite = favorite;

    this.billetChargingForm.setValue({
      document: favorite.document,
      nameComplete: favorite.name,
      email: favorite.email,
      dateDue: '',
      orderNumber: '',
      billetBodyObservations: '',
      descripiton: '',
      billetValue: '',
    });


    this.formPersonalAddress.setValue({
      document: favorite.document,
      name: favorite.document,
      email: favorite.email,
      postalCode: favorite.zipCode,
      address: favorite.address,
      numberAddress: favorite.addressNumber,
      complementAddress: favorite.addressComplement,
      neighborhood: favorite.neighborhood,
      cityAddress: favorite.city,
      uf: favorite.state,
      country: favorite.country,
    });

    this.textButton = '';
    this.selectedFavorite = true;
    this.switchFavorites = 'new';
  }

  saveFavorite(checked) {
    if (this.favorite) {
      let prepare: AccountPaymentFavoriteDTO;
      prepare = {
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.billetChargingForm.controls.nameComplete.value,
        document: this.billetChargingForm.controls.document.value,
        email: this.billetChargingForm.controls.email.value,
        codeBank: '',
        bankName: '',
        accountNumber: '',
        agencyNumber: '',
        typeAccount: '',
        zipCode: this.formPersonalAddress.controls.postalCode.value,
        address: this.formPersonalAddress.controls.address.value,
        addressNumber: this.formPersonalAddress.controls.numberAddress.value,
        addressComplement: this.formPersonalAddress.controls.complementAddress.value,
        neighborhood: this.formPersonalAddress.controls.neighborhood.value,
        city: this.formPersonalAddress.controls.cityAddress.value,
        state: this.formPersonalAddress.controls.uf.value,
        country: this.formPersonalAddress.controls.country.value,
        id: this.favorite.id,
        uuid: this.favorite.uuid,
      };

      if (this.favorite.id !== undefined) {
        // this.loadingService.show();
        this.accountPaymentFavoriteService
          .updateFavorite(prepare)
          .pipe(
            tap((data) => {
              if (data.status) {
                this.getAllFavorites();
              }
              // this.loadingService.hide();
            }),
            catchError((_) => {
              // this.loadingService.hide();
              return of(null);
            })
          )
          .subscribe();
      }
    } else if (checked) {
      // this.loadingService.show();
      let prepare: AccountPaymentFavoriteDTO;

      prepare = {
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        name: this.billetChargingForm.controls.nameComplete.value,
        document: this.billetChargingForm.controls.document.value,
        email: this.billetChargingForm.controls.email.value,
        codeBank: '',
        bankName: '',
        accountNumber: '',
        agencyNumber: '',
        typeAccount: '',
        zipCode: this.formPersonalAddress.controls.postalCode.value,
        address: this.formPersonalAddress.controls.address.value,
        addressNumber: this.formPersonalAddress.controls.numberAddress.value,
        addressComplement: this.formPersonalAddress.controls.complementAddress.value,
        neighborhood: this.formPersonalAddress.controls.neighborhood.value,
        city: this.formPersonalAddress.controls.cityAddress.value,
        state: this.formPersonalAddress.controls.uf.value,
        country: this.formPersonalAddress.controls.country.value,
        type: TypeEnum.BILLET,
      };
      this.accountPaymentFavoriteService
        .saveFavorite(prepare)
        .pipe(
          tap((data) => {
            if (data.status) {
              this.resetForms();
              this.getAllFavorites();
            }
            // this.loadingService.hide();
          }),
          catchError((_) => {
            // this.loadingService.hide();
            return of(null);
          })
        )
        .subscribe();
    }
  }

  public filterReceipt(value) {
    if (value !== '') {
      this.listReceiptsShow = this.pipeFilter.transform(this.responseTransfer, value);
      return;
    } else {
    }
  }

  checkListAccount(account) {
    return account.codeBank.length >= 1 && account.accountNumber.length >= 1 && account.agencyNumber.length >= 1;
  }

  searchPostalCode(data: any) {
    this.accountPaymentFavoriteService.searchPostaCode(data.target.value);
  }

  resetForms() {
    this.billetChargingForm = this.service.createFormEmitBillet();
    this.errorMessages = this.service.getErrorMessages();
    this.formPersonalAddress = this.accountPaymentFavoriteService.createFormFavoriteCharging();
    this.formErrorMessages = this.accountPaymentFavoriteService.getFormMessageError();   
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
}
