import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalController, NavController} from '@ionic/angular';
import {AccountBalanceService, BilletStatusEnum, StatementService} from '@wlp/ui-bs-cashin';
import {
  ExtractDigitalAccountResponse,
  ExtractDigitalAccountService,
  TypeView,
  ViewResumeExtractAccountDto,
} from '@wlp/ui-bs-cashout';
import {UserInfoService} from '@wlp/ui-bs-login';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {UICHeaderUserInfoService} from '@wlp/ui-c-header-user-info';
import * as moment from 'moment';
import {tap} from 'rxjs/operators';
import {LayoutConfigModel} from 'src/app/core/layout/models/layout-config.model';
import {LayoutConfigService} from 'src/app/core/layout/services/layout-config.service';
import {LoadingService} from 'src/app/core/layout/services/loading.service';
import {environment} from 'src/environments/environment';
import {ModalExportExtractComponent} from '../../../../../modals/modal-export-extract/modal-export-extract.component';

import {ExtractDataFilterPipe} from '../extract-data-filter.pipe';
import {ExtractUtil} from '../extract-util';
import {ExtractUtilInterface} from '../extract-util.interface';
import {Paginated} from '@wlp/ui-bs-parameter';
import { OrderDateEnum } from '@wlp/ui-bs-cashout/lib/enum/order-date.enum';

type TypeOrigin = 'billet';

@Component({
  selector: 'ui-t-extract-digital-account-consolidaded',
  templateUrl: './digital-account-consolidaded.page.html',
  styleUrls: ['./digital-account-consolidaded.page.scss'],
  providers: [ExtractDataFilterPipe],
})
export class DigitalAccountConsolidadedPage extends ExtractUtil implements ExtractUtilInterface, OnInit {

  public accountBalance: string = null;
  public toggleAccountBalance = false;
  public toggleBankTransition = false;
  public toggleSpinnerAccountBalance = false;
  public toggleSpinnerBankTransition = false;
  public debitMonth: number = null;
  public creditMonth: number = null;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public description: string;
  public title: string;
  public tableFuture: boolean;

  private extractColors: any;

  public transactions: Paginated<ViewResumeExtractAccountDto[]> = null;
  public operationTypeT: any[];
  public operationTypeD: any[];
  public operationTypeC: any[];
  public selectedView: TypeView;
  public selectedOperation: string;
  private uuidOperationToReceiveBillet = '911fe94a-bcb8-29ca-7557-4c4f53fb0877';
  public allowedUuids = [this.uuidOperationToReceiveBillet]; // Operação: 802 - Receber Boleto para terceiro
  public typeOrigin: TypeOrigin = null;
  public dateStartFromQuery: Date = null;
  public dateEndFromQuery: Date = null;

  public customPopoverOptionsOperation: any;
  public customPopoverOptionsTypeView: any;

  // paginator
  public page = 1;
  public itemsPerPageOptions: number[] = [10, 20, 30, 50, 100];
  public itemsPerPage = 10;
  public itemsDisplayed = 0;
  public iconSkipLast = './assets/icon/icon-material-skip-last.svg';
  public iconNavigateLast = './assets/icon/icon-material-navigate-last.svg';
  public iconNavigateNext = './assets/icon/icon-material-navigate-next.svg';
  public iconSkipNext = './assets/icon/icon-material-skip-next.svg';

  public dateRegisterOrderBy: OrderDateEnum = "DESC" as OrderDateEnum
  constructor(
    private router: Router,
    private statementService: StatementService,
    private headerInfoService: UICHeaderUserInfoService,
    private accountBalanceService: AccountBalanceService,
    public pipeFilter: ExtractDataFilterPipe,
    public detection: ChangeDetectorRef,
    public modalController: ModalController,
    private extractDigitalAccountService: ExtractDigitalAccountService,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    public activatedRoute: ActivatedRoute,
    private translate: UiBsTranslateService,
    private navCtrl: NavController,
    public loadingService: LoadingService,
  ) {
    super(userInfoService, pipeFilter, detection, modalController, loadingService);
  }

  public async ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.transactions = null;
    this.listStatment = [];
    this.operationTypeT = [];
    this.operationTypeD = [];
    this.operationTypeC = [];
    this.listFuture = [];
    this.prepareColorExtrac();
    await this.loadUser();
    await this.getTypesOperation();

    this.customPopoverOptionsOperation = {
      header: this.translate.applyTranslate('CASH_OUT.EXTRACT.SELECT.OPERATION.LABEL'),
    };
    this.customPopoverOptionsTypeView = {
      header: this.translate.applyTranslate('CASH_OUT.EXTRACT.SELECT.VIEW.LABEL'),
    };

    await this.activatedRoute.params
      .subscribe((params) => {
        if (params.type === 'current') {

          this.checkTypeStatementViewFilter();
          this.title = 'CASH_OUT.EXTRACT.HEADER.TITLE';
          this.description = 'CASH_OUT.EXTRACT.HEADER.SUBTITLE';
          this.tableFuture = false;
        }

        this.tpVoucher = params.type;
        this.getReceipts(this.tpVoucher);
      })
      .unsubscribe();
  }

  private async checkTypeStatementViewFilter() {
    await this.activatedRoute.queryParams.subscribe(async (queryParams) => {
        this.dateStartFromQuery = queryParams['startDate'];
        this.dateEndFromQuery = queryParams['endDate'];
        this.typeOrigin = queryParams['typeOrigin'];

        if (this.dateStartFromQuery && this.dateEndFromQuery) {
          await this.filterBillet(this.dateStartFromQuery, this.dateEndFromQuery);
        } else {
          await this.getDigitalStatement();
          this.selectedOperation = 'All';
          this.selectedView = TypeView.CONSOLIDADED;
        }
      }, error => console.error('Ocorreu erro para pegar a lista de parametro ' + error),
      () => this.detection.detectChanges(),
    );
  }
  public viewDetail(dataSelected: any) {
    this.selectedView = TypeView.DETAILADED;
    this.selectedOperation = dataSelected.uuidOperation;
    const customDate = dataSelected.dateRegister ? dataSelected.dateRegister.split('T')[0] : dataSelected.dateRegister;
    this.dateExtractInit = moment(customDate);
    this.dateExtractEnd = moment(customDate);
  }
  public redirectToBillet(dataSelected: any) {
    const customDate = dataSelected.dateRegister ? dataSelected.dateRegister.split('T')[0] : dataSelected.dateRegister;
    const typeOrigin = 'extract';
    const startDate = customDate;
    const endDate = customDate;
    const status: BilletStatusEnum = BilletStatusEnum.COMPENSATED;
    this.router.navigate(['/app/charging/billet/v2/list'], {
      queryParams: { typeOrigin, startDate, endDate, status },
    });
  }
  public async filterSelect() {
    const startDate = this.dateExtractInit.format('YYYY-MM-DD');
    const endDate = this.dateExtractEnd.format('YYYY-MM-DD');
    const operation = this.selectedOperation === 'All' ? undefined : this.selectedOperation;
    await this.searchExtractPaginator(startDate, endDate, operation);
  }
  
  public onSelectChangeValue() {
    this.page = 1
    this.filterSelect()
  }

  public async orderByDateRegister() {
    this.loadingService.show();
    this.dateRegisterOrderBy === "DESC" 
      ? this.dateRegisterOrderBy = "ASC" as OrderDateEnum
      : this.dateRegisterOrderBy =  "DESC" as OrderDateEnum
      
    const startDate = this.dateExtractInit.format('YYYY-MM-DD');
    const endDate = this.dateExtractEnd.format('YYYY-MM-DD');
    const operation = this.selectedOperation === 'All' ? undefined : this.selectedOperation;

    await this.searchExtractPaginator(startDate, endDate, operation);
  }

  private async searchExtractPaginator(startDate: string, endDate: string, operation: string): Promise<void> {

    if (this.selectedView) {
      await this.extractDigitalAccountService.findExtractByTypeViewPagination(
        this.userProfile.uuidAccount, startDate, endDate, this.selectedView, this.page, this.itemsPerPage, this.dateRegisterOrderBy, operation).subscribe((res) => {
          this.transactions = res;
          this.maxData = this.transactions.data.length;
        },
        (err) => console.error('Erro', err),
        () => {
          this.detection.detectChanges();
          this.loadingService.hide();
        },
      );
    }

  }

  private async getTypesOperation() {
    await this.extractDigitalAccountService.findByTypeOperation(this.userProfile.uuidAccount, 'D,C,T').subscribe(res => {
        this.operationTypeD = res.filter( op => op.type === 'D');
        this.operationTypeC = res.filter( op => op.type === 'C');
        this.operationTypeT = res.filter( op => op.type === 'T');
    }, error => console.log('Erro ao tentar carregar operacoes ' + error),
      () => this.detection.detectChanges());
  }

  public async modalExport() {
    const modalExport = await this.modalController.create({
      component: ModalExportExtractComponent,
      cssClass: 'modal-export',
      componentProps: {
        selectedView: this.selectedView,
        selectedOperation: this.selectedOperation,
        startDate: this.dateExtractInit.format('YYYY-MM-DD'),
        endDate: this.dateExtractEnd.format('YYYY-MM-DD'),
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccount: this.userProfile.uuidAccount,
        extractColors: this.extractColors,
      },
      backdropDismiss: false,
    });
    return await modalExport.present();
  }

  public async getAccountBalance() {
    this.headerInfoService.loadUser();
    this.accountBalanceService
      .getBalanceSubject()
      .pipe(
        tap((data) => {
          if (this.toggleAccountBalance) this.accountBalance = !!data ? String(data) : '0';
          else this.accountBalance = null;
          setTimeout(() => {
            this.toggleSpinnerAccountBalance = false;
          }, 1500);
        })
      )
      .subscribe();
  }
  public async showAccountValues(data) {
    if (data === 'balance') {
      this.toggleAccountBalance = !this.toggleAccountBalance;
      await this.getAccountBalance();
      this.detection.detectChanges();
    } else {
      this.toggleBankTransition = !this.toggleBankTransition;
      await this.getBankTransition();
      this.detection.detectChanges();
    }
  }
  public async getDigitalStatement(initial?: Date) {

    if (initial === undefined) {
      initial = this.calInitialDate;
    }
    this.dateExtractInit = moment(initial);
    this.dateExtractEnd = moment(this.calEndDate);
    this.page = 1
    
    await this.filterSelect();
  }

  public async getBankTransition() {
    await this.statementService
      .getSumMoviments(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((response) => {
        if (this.toggleBankTransition) {
          this.debitMonth = !!response.debit ? response.debit : 0;
          this.creditMonth = !!response.credit ? response.credit : 0;
        } else {
          this.debitMonth = null;
          this.creditMonth = null;
        }
        setTimeout(() => {
          this.toggleSpinnerBankTransition = false;
        }, 1500);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  public async getDigitalStatementFuture() {
    await this.extractDigitalAccountService
      .getStatementFuture(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .subscribe(
        (res) => {
          this.listFuture = res;
          this.listFuture = this.listFuture.sort((a, b) => {
            return (a.dateRegister > b.dateRegister) ? 1 : ((b.dateRegister > a.dateRegister) ? -1 : 0);
          });

          // atualiza o front da tabela
          this.setArrayForShowFuture(true);
          this.calcPaginationFuture();
        },
        (err) => console.error('Erro', err),
        () => this.detection.detectChanges(),
      );
  }

  public refreshAccountValues(data) {
    if (data === 'balance') {
      this.toggleSpinnerAccountBalance = true;
      this.getAccountBalance();
    } else {
      this.toggleSpinnerBankTransition = true;
      this.getBankTransition();
    }
  }

  public valueDisplayCreditOrDebit(extractDigitalAccount: ExtractDigitalAccountResponse): number {
    return this.extractDigitalAccountService.valueDisplayCreditOrDebit(extractDigitalAccount);
  }

  public showIsBalance(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsBalance(typeStatement);
  }

  public showIsCreditOrDebit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsCreditOrDebit(typeStatement);
  }

  public isCredit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.isCredit(typeStatement);
  }

  /**
   * apresentacao da data na tabela
   * @param date
   */
  public getHourDate(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('HH:mm:ss')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length >= 6) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }

  public transformDateFormattedConsolidated(date: string) {
    if (date && date.includes('T')) {
      return date.split('T')[0];
    }

    return date;
  }

  public goBack() {
    if (this.typeOrigin === 'billet') {
      this.router.navigate(['/app/charging/billet/v2/list'], {
        queryParams: {
          typeOrigin: '', 
          startDate: this.dateStartFromQuery, 
          endDate: this.dateEndFromQuery, 
        },
      });
    } else {
      this.navCtrl.navigateRoot('/app/dashboard');
    }
  }

  public prepareColorExtrac() {
    this.extractColors = {
      creditColor: '#006400',
      debitColor: '#ff0000',
    };
    if (environment.wlThemeParameterization.wlTheme.global.extractColors != undefined) {
      this.extractColors = environment.wlThemeParameterization.wlTheme.global.extractColors;
    }
  }

  private async filterBillet(startDate: Date, endDate: Date) {
    const uuidOperationBillet = this.uuidOperationToReceiveBillet;
    this.selectedView = TypeView.DETAILADED;
    this.selectedOperation = uuidOperationBillet;

    const startDateFormatted = moment(startDate).format('YYYY-MM-DD');
    const endDateFormatted = moment(endDate).format('YYYY-MM-DD');
    this.dateExtractInit = moment(startDate);
    this.dateExtractEnd = moment(endDate);

    await this.searchExtractPaginator(startDateFormatted, endDateFormatted, null);

  }

  public getSelectedOperationDescription() {
    switch (this.selectedOperation) {
      case '':
        return this.translate.applyTranslate('CASH_OUT.EXTRACT.SELECT.OPERATION.LABEL');
      case 'All':
        return this.translate.applyTranslate('CASH_OUT.EXTRACT.SELECT.OPERATION.ALL');
      default:
        const selectedOption = this.findSelectedOperation();
        return selectedOption ? `${selectedOption.code} - ${selectedOption.shortDescription}` : '';
    }
  }

  private findSelectedOperation() {
    const allOperationTypes = [...this.operationTypeT, ...this.operationTypeC, ...this.operationTypeD];
    return allOperationTypes.find(operation => operation.uuid === this.selectedOperation);
  }

  /* pagination begin */
  public async handleChangeItemsPerPage(itemsPerPage: number) {
    this.itemsPerPage = itemsPerPage;
    this.page = 1;
    await this.filterSelect();
  }
  public async handleGoToFirstPage() {
    this.page = 1;
    await this.filterSelect();

  }
  public async handleGoToLastPage() {
    this.page = this.transactions ? this.transactions.pagination.totalPages : 0;
    await this.filterSelect();
  }

  public async handleGoToNextPage() {
    this.page = this.transactions ? this.transactions.pagination.page + 1 : 0;
    await this.filterSelect();
  }

  public async handleGoToPrevPage() {
    this.page = this.transactions ? this.transactions.pagination.page - 1 : 0;
    await this.filterSelect();
  }
  /* pagination end */
}
