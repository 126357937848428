import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  CreateAccountService,
  Step, StepFlowService,
} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from '../../../../../../../../environments/environment';
import { ModalAlertComponent } from './../../../../modals/modal-alert/modal-alert.component';
import { ModalValidationComponent } from '../../../../modals/modal-validation/modal-validation.component';
import { Router } from "@angular/router";

@Component({
  selector: 'ui-t-account-type',
  templateUrl: './account-form.page.html',
  styleUrls: ['./account-form.page.scss'],
})
export class AccountFormPage implements OnInit, OnDestroy {
  dataStep: Step;
  nextStep: Partial<Step>;

  form: FormGroup;
  error: any[];

  block = false;

  accountType: string;

  listAccountType: AccountPaymentTypeResponse[];

  isFirtsacess: boolean = true;
  isValid: boolean;
  typeFlow: string;
  modal: HTMLIonModalElement;
  step: string;
  currentStep: string;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private serviceCreateAccount: CreateAccountService,
    private modalController: ModalController,
    private stepsService: StepFlowService,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    this.dataStep = await this.stepsService.getSteps(environment, this.router.url, false, true);
    
    this.form = this.serviceCreateAccount.createForm(this.dataStep.isPF);
    this.error = this.serviceCreateAccount.getFormMessageError();

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.accountType = await this.service.getTemporaryTypeAccount();
    this.loadAccountType();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.dataStep);
  }

  async goContinue() {
    if (this.isValid) {
      this.nextStep.nextStep = this.currentStep ? this.currentStep : this.nextStep.currentStep
      await this.stepsService.nextStep(this.navCtrl, this.nextStep);
      return;
    }

    this.serviceCreateAccount
      .documentAccount(this.form.get('document').value, this.accountType, environment.wlp, this.dataStep.name, environment.appVersion)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          this.serviceCreateAccount.processPreAccount(res);
          if (res.isFirtsAccess) {
            await this.serviceCreateAccount.dataAccount(this.form.value, environment.appVersion);
            this.modalFlow(res)
          }
        }),
        retry(2),
        catchError((err) => {
          this.serviceCreateAccount.getErrorPreAccount(err)
          return of(null);
        })
      ).subscribe();
  }

  checkCpf(data: any) {
    if (this.form.get('document').invalid) {
      return;
    }
    this.serviceCreateAccount.documentAccount(data.target.value, this.accountType, environment.wlp, this.dataStep.name, environment.appVersion)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res: any) => {
          if (res.status === 'IN_ONBORDING' && res.currentStep) {
            this.currentStep = '/onboarding' + res.currentStep;
          }
          await this.serviceCreateAccount.processPreAccount(res);
          this.nextStep = await this.stepsService.getSteps(environment);
          this.isFirtsacess = res.isFirtsAccess;
          if (!res.isFirtsAccess) {
            if (res.status === 'FINISHED') {
              this.notification('warning-circle', this.translate.applyTranslate('ONBORDING.EXISTING_ACCOUNT.TEXT_10'));
            } else {
              this.block = true;
              this.serviceCreateAccount.prepareFormValidation(res);
              this.modalFlow(res);
            }
          } else {
            this.block = false;
            this.serviceCreateAccount.prepareFormValidation();
          }
        }),
        retry(2),
        catchError((err) => {
          this.serviceCreateAccount.getErrorPreAccount(err)
          return of(null);
        })
      )
      .subscribe()
  };

  async modalFlow(response: any) {
    this.modal = await this.modalController.create({
      component: ModalValidationComponent,
      cssClass: 'modal-flow-container-inner-validation',
      componentProps: { data: response, step: this.step, form: this.form },
      backdropDismiss: false,
    });
    await this.modal.present();
    await this.modal.onDidDismiss().then(async (data) => {
      if (data.data) {
        this.isValid = data.data;
        if (response.email && response.phone) {
          this.block = true;
        }
        this.nextStep = {
          nextStep: this.currentStep ? this.currentStep : this.nextStep.currentStep
        };

        await this.stepsService.nextStep(this.navCtrl, this.nextStep);
      }
    });
  }

  private async notification(status: string, message: string) {
    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-notification-class',
      componentProps: {
        status,
        message,
        confirmBtn: this.translate.applyTranslate('ONBORDING.V2.BUTTON.CONFIRM'),
        confirmation: false,
        translate: true,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then((callback) => {
      this.navCtrl.navigateRoot('welcome');
    });
    return await modalSuccess.present();
  }

  private async loadAccountType() {
    this.listAccountType = await this.service.loadTypeAccount(environment.wlp);
  }
}
