import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';

import { CardLoadingPage } from '../modals/card-loading/card-loading.page';
import { CardsService } from '@wlp/ui-bs-cards';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
// import { PremiumInfosService } from '@wlp/ui-bs-nsc';
import { Router } from '@angular/router';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
// import { UiCCsfelizMessageComponent } from '@wlp/ui-c-csfeliz-message';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UserInfoService } from '@wlp/ui-bs-login';
import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'edit-card-page',
  templateUrl: './edit-card-page.component.html',
  styleUrls: ['./edit-card-page.component.scss'],
})
export class EditCardPage implements OnInit {
  public showValueFlag: boolean;
  public showValueIcon: string;
  public brand: string;
  public userProfile: UserInfoProfile;
  flagCard: string = '';
  formIcons: any;
  public cards: Array<any>;
  private produtionUrl: string = 'https://casafeliz.app/';
  private homologUrl: string = 'https://casafeliz.plataformadesorteio.com.br/';
  public registerNscPlanPaymentForm: FormGroup;
  public errorMessagesRegister: any;
  ButtonClick: boolean;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private uicMenuApp: UICMenuAppService,
    private layoutConfigService: LayoutConfigService,
    private router: Router,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    // private premiumService: PremiumInfosService,
    private navCtrl: NavController,
    private walletService: CardsService,
    protected userInfoService: UserInfoService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private translate: UiBsTranslateService
  ) {
    this.formIcons = {
      cardLogo: this.showIcon('cartao'),
      cardIcon: this.showIcon('visa-logo', 'png'),
      nameIcon: this.showIcon('nome'),
      bornDateIcon: this.showIcon('data'),
      cvcIcon: this.showIcon('senhaEcvc'),
      amex: this.showIcon('amex', 'png'),
      visa: this.showIcon('visa-logo', 'png'),
      elo: this.showIcon('elo', 'png'),
      mastercard: this.showIcon('mastercard', 'png'),
    };
  }

  ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    //this.registerNscPlanPaymentForm = this.premiumService.getFormPaymentInfo();
    //this.errorMessagesRegister = this.premiumService.getFormPlanPaymentInfoErrorMessage();

    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.cards = [
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 },
      { flag: 'visa', bankName: 'Banco', lastDigits: 4567 },
    ];
    this.loadUser();
  }
  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();

      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  showValue(): void {
    this.showValueFlag = this.showValueFlag ? false : true;
    this.showValueIcon = this.showValueFlag ? this.showIcon('ocultarValor') : this.showIcon('verValor');
  }
  public checkFooterEnv(link) {
    switch (link) {
      case 'regulamento':
        window.location.href = environment.production
          ? `${this.produtionUrl}regulamento`
          : `${this.homologUrl}regulamento`;
        break;
      case 'politica-de-privacidade':
        window.location.href = environment.production
          ? `${this.produtionUrl}politica-de-privacidade`
          : `${this.homologUrl}politica-de-privacidade`;
        break;
      case 'termos-de-uso':
        window.location.href = environment.production
          ? `${this.produtionUrl}termos-de-uso`
          : `${this.homologUrl}termos-de-uso`;
        break;
    }
  }

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/casa-feliz/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  redirectUrl(link: string): void {
    if (link !== '') {
      this.router.navigate([link]);
    }
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(false);
  }

  closeModal(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
  goBack() {
    this.router.navigate(['/app/wallet/manager']);
  }
  async createWallet() {
    if (!this.ButtonClick) {
      this.loadingService.show();
      let date = this.registerNscPlanPaymentForm.controls.validDate.value;
      let prepare = {
        cardNumber: this.registerNscPlanPaymentForm.controls.cardNumber.value,
        brand: this.registerNscPlanPaymentForm.controls.flagCard.value,
        cardholderName: this.registerNscPlanPaymentForm.controls.cardName.value,
        expirationMonth: date.substring(0, 2),
        expirationYear: date.substring(3, 7),
        verifyCard: 1,
        securityCode: this.registerNscPlanPaymentForm.controls.cardCode.value,
      };
      await this.walletService
        .createWallet(environment.wlp, this.userProfile.uuidAccount, prepare)
        .then((res) => {
          if (res) {
            this.loadingService.hide();
            /* this.message(
              'success',
              'Cadastro concluído!',
              'Seu cadastro foi realizado com sucesso.',
              './assets/images/casa-feliz/success.png'
            ); */
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }
  async callModalLoading() {
    return await this.modalController.create({
      component: CardLoadingPage,
      cssClass: 'cardMessagePage',
    });
  }
  /* private async message(status: string, title: string, message: string, image?: string, svg?: string) {
    const modal = await this.modalController.create({
      component: UiCCsfelizMessageComponent,
      cssClass: 'ui-c-csfeliz-message',
      componentProps: {
        status: status,
        image: image,
        svg: svg,
        title: title,
        message: message,
        confirmation: false,
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data === 'confirm') {
        this.router.navigate(['wallet/edit-cards']);
      }
    });
    return await modal.present();
  } */
}
