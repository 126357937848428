import { LoadingService } from './../../../../../../core/layout/services/loading.service';
import { FormGroup } from '@angular/forms';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { NavController, ModalController, ToastController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { ChangePasswordService, UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { ModalAlertComponent } from '../../../modals/modal-alert/modal-alert.component';
import { ModalTokenChangePasswordComponent } from '../../../modals/modal-token-change-password/modal-token-change-password.component';

import { environment } from 'src/environments/environment';
import { UserInfoService } from '@wlp/ui-bs-login';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.page.html',
  styleUrls: ['./change-password.page.scss']
})
export class ChangePasswordPage implements OnInit {

  backgroundImage: string;
  iconName: string;
  iconNameConfirm: string;
  passwordType: string;
  confirmPasswordType: string;
  passwordShow: boolean;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  public formCreatePassword: FormGroup;
  public errorMessages: any;
  public stepOne: boolean = true;
  public stepTwo: boolean = false;
  modal: any;
  private count: number = 3;
  isResend: boolean = false;

  private clientId = environment.keycloakConfig.clientId;
  private realm = environment.keycloakConfig.realm;
  userProfile: any;
  userInfo: any;
  toast: HTMLIonToastElement;
  private tokenCode: any;

  constructor(
    private navCtrl: NavController,
    private translate: UiBsTranslateService,
    private layoutConfigService: LayoutConfigService,
    private changePasswordService: ChangePasswordService,
    private menuService: UICMenuAppService,
    private modalController: ModalController,
    private toastController: ToastController,
    protected userInfoService: UserInfoService,
    private myDataService: UiBsMyDataService,
    private load: LoadingService
  ) {
    this.iconName = 'eye';
    this.iconNameConfirm = 'eye';
    this.passwordShow = false;
    this.passwordType = 'password';
    this.confirmPasswordType = 'password'
    this.backgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
    this.menuService.applyStatus(false)
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.loadUser();

    this.resetForm();
  }

  goBack() {
    this.navCtrl.navigateRoot(`/app/my-data`);
  }

  togglePassword(ev) {
    if (this.passwordShow) {
      this.passwordShow = false;
      if (ev === 'confirmPassword') {
        this.iconNameConfirm = 'eye';
        this.confirmPasswordType = 'password';
      } else {
        this.passwordType = 'password';
        this.iconName = 'eye';
      }
    } else {
      this.passwordShow = true;
      if (ev === 'confirmPassword') {
        this.iconNameConfirm = 'eye-off';
        this.confirmPasswordType = 'text';
      } else {
        this.passwordType = 'text';
        this.iconName = 'eye-off';
      }

    }
  }


  async enterPassword(event: FormGroup) {
    if (event.controls.password.status) {
      this.load.show();
      let body = {
        "realm": this.realm,
        "clientId": this.clientId,
        "email": this.userProfile.email,
        "password": event.controls.password.value,
        "uuidWhiteLabel": this.userProfile.uuidWhiteLabel,
        "uuidAccountPayment": this.userProfile.uuidAccount
      };

      this.changePasswordService.validationPassword(body, this.userProfile.uuidAccount).subscribe(async res => {
        this.load.hide();
        if (res.validation) {
          this.modal = await this.modalController.create({
            component: ModalTokenChangePasswordComponent,
            cssClass: 'modal-alert-token',
            componentProps: {
              email: this.userProfile.email
            }
          })
          this.modal.present();

          this.modal.onDidDismiss().then((data) => {
            if (data.data != undefined) {
              this.tokenCode = data.role;
              this.modalNotification('accept-token', this.translate.applyTranslate('MY_DATA.CHANGE_PASSWORD.INFOS.TOKEN_ACCEPT'), '', true, false);
              this.resetForm();
              setTimeout(() => {
                this.modalController.dismiss();
              }, 3000);
              this.stepOne = false;
              this.stepTwo = true;
            } else this.isResend = true;
          });
        } else {
          this.load.hide();
          this.count -= 1;
          if (this.count != 0) {
            this.presentToast(this.translate.applyTranslate('MY_DATA.CHANGE_PASSWORD.INFOS.PASSWORD_ERROR_ATTEMPT', { count: this.count }));
          } else {
            this.presentToast(this.translate.applyTranslate('MY_DATA.CHANGE_PASSWORD.INFOS.PASSWORD_ERROR'));
            setTimeout(() => {
              let redirectUrl = document.location.protocol + '//' + document.location.hostname;
              if (document.location.hostname.includes('localhost')) {
                redirectUrl += ':' + document.location.port;
              }
              this.userInfoService.onLogout(redirectUrl);
            }, 2000);
          }
        }
      });
    }
  }

  changePassword() {
    let body = {
      "realm": this.realm,
      "email": this.userProfile.email,
      "newPassword": this.formCreatePassword.controls.password.value,
      "uuidWhiteLabel": this.userProfile.uuidWhiteLabel,
      "uuidAccountPayment": this.userProfile.uuidAccount,
      "code": this.tokenCode
    };
    this.changePasswordService.updateValidationPassword(body, this.userProfile.uuidAccount).subscribe(res => {
      if (res.validation) {
        this.modalNotification('change-password', this.translate.applyTranslate('MY_DATA.CHANGE_PASSWORD.INFOS.CHANGE_PASSWORD'), '', true, false);
        setTimeout(() => {
          this.modalController.dismiss();
          this.navCtrl.navigateRoot(['/app/dashboard']);
        }, 2000);
      } else {
        this.presentToast(this.translate.applyTranslate('MY_DATA.CHANGE_PASSWORD.INFOS.CHANGE_PASSWORD_ERROR'));
        /* this.modalNotification('warning-circle', 'Erro', '', true, true); */
      }
    })
  }


  async modalNotification(status: string, message: string, cancelText?: string, notButtons?: boolean, confirmation?: boolean) {
    this.modal = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class-token',
      componentProps: {
        status,
        message,
        confirmBtn: cancelText,
        confirmation,
        notButtons,
        translate: true,
      },
    });
    this.modal.present();
  }

  async presentToast(message: string) {
    this.toast = await this.toastController.create({
      message,
      duration: 4000,
      position: 'top',
      color: 'secondary',
      translucent: false
    });
    this.toast.present();
  }


  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  resetForm() {
    this.formCreatePassword = this.changePasswordService.getForm();
    this.errorMessages = this.changePasswordService.getErrorFormMessage();
  }

}
