import { StorageService } from '@wlp/ui-bs-oauth';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { BilletService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import * as FileSaver from 'file-saver';
import { BilletsListDataFilterPipe } from '../../billets-list/billet-list-data-filter.pipe';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { ModalImportBilletModelComponent } from 'src/app/views/themes/sentinel/modals/modal-import-billet-model/modal-import-billet-model.component';
import { tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';

enum FileFormatEnum {
  XLSX = 'xlsx',
}
@Component({
  selector: 'ui-t-billing-billet-lot',
  templateUrl: './billet-lot.page.html',
  styleUrls: ['./billet-lot.page.scss'],
  providers: [BilletsListDataFilterPipe],
})
export class BilletLotPage implements OnInit {
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public uuidLoteBillet: string;
  public listLots: Array<any> = [];
  public listLotsShow: Array<any> = [];
  private userProfile: UserInfoProfile;
  public toggleSpinnerUpdate: boolean = false;
  FileFormatEnum = FileFormatEnum;

  constructor(
    protected userInfoService: UserInfoService,
    private accountChargingService: BilletService,
    private cdr: ChangeDetectorRef,
    private navCtrl: NavController,
    private loadingService: LoadingService,
    private router: Router,
    private storage: StorageService,
    private billetService: BilletService,
    public pipeFilter: BilletsListDataFilterPipe,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    public modalController: ModalController,
  ) {}

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  ionViewWillEnter() {
    this.loadUser();
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.getLotBillets(user.uuidAccount);
        /* this.getLotBillets(this.userProfile.uuidAccount); */
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
    this.cdr.detectChanges();
  }

  private getLotBillets(uuidAccountPayment: string) {
    this.loadingService.show();
    this.accountChargingService.getLotBillets(uuidAccountPayment).subscribe(
      (res) => {
        this.loadingService.hide();
        this.listLots = res;
        this.listLotsShow = this.listLots;
        this.toggleSpinnerUpdate = false
      },
      (err) => {
        console.error(err);
        this.loadingService.hide();
      },
      () => {
        this.loadingService.hide();
      }
    );
    this.cdr.detectChanges();
  }

  public bulletColor(status: string) {
    switch (status) {
      case 'PENDING': {
        return 'text-pending';
      }
      case 'AVAILABLE': {
        return 'text-available';
      }
      case 'IN_VALIDATION': {
        return 'text-in-validation';
      }
      case 'IN_PROCESS': {
        return 'text-in-progress';
      }
      case 'DRAFT': {
        return 'text-in-draft';
      }
      default: {
        return '';
      }
    }
  }

  public statusTranslate(status: string) {
    switch (status) {
      case 'PENDING': {
        return 'Pendente';
      }
      case 'AVAILABLE': {
        return 'Disponível';
      }
      case 'IN_VALIDATION': {
        return 'Em validação';
      }
      case 'IN_PROCESS': {
        return 'Em processamento';
      }
      case 'DRAFT': {
        return 'Rascunho';
      }
      default: {
        return '--';
      }
    }
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public updateTable(){
    this.toggleSpinnerUpdate = true;
    setTimeout(() => {
      this.getLotBillets(this.userProfile.uuidAccount);
    }, 1000);
  }

  public action(lotNumber: string, action: string, uuidLotBillet?: string, status?: string) {
    if (action === 'Configurar') {
      this.storage.set('uuidLotBillet', uuidLotBillet);
      this.storage.set('statusLotBillet', status);
      this.router.navigate(['/app/billing/billet-lot-list']);
    }
    if (action === 'Visualizar') {
      this.storage.set('uuidLotBillet', uuidLotBillet);
      this.router.navigate(['/app/charging/billet/create']);
    }
    if (action === 'download') {
      try {
        this.loadingService.show();
        this.billetService.getBilletDownloadLot(uuidLotBillet).subscribe((res) => {
          this.loadingService.hide();
          FileSaver.saveAs(
            new Blob([res]),
            `lote-${lotNumber}-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`
          );
        });
      } catch (error) {
        this.loadingService.hide();
      }
    }
  }

  public filterLots(searchValue: string) {
    this.listLotsShow = this.pipeFilter.transformlot(this.listLots, searchValue);
    /* if (!!searchValue) {
      this.listLotsShow = this.listLots.filter((item) => {
        return item.lotNumber == searchValue || item.value.toString() == searchValue;
      });
    } else {
      this.listLotsShow = this.listLots;
    } */

    this.cdr.detectChanges();
  }

  public async openImportBilletModal() {
    const modal = await this.modalController.create({
      component: ModalImportBilletModelComponent,
      cssClass: 'modal-alert-class-file',
      componentProps: {},
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public downloadBatchBillsModel(fileFormat: FileFormatEnum): void {
    switch (fileFormat) {
      case FileFormatEnum.XLSX:
        this.billetService
          .getbatchBillsModel$(fileFormat)
          .pipe(
            tap((blob) => {
              saveAs(
                new Blob([blob]),
                `modelo-boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.xlsx`
              );
            })
          )
          .subscribe();
        break;
    }
  }
}
