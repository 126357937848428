import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsCardsModule } from '@wlp/ui-bs-cards';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCActiveCardModule } from '@wlp/ui-c-active-card';
import { UiCActiveCardModalFormModule } from '@wlp/ui-c-active-card-modal-form';
import { UiCButtonModule } from '@wlp/ui-c-button';
import { UiCCardRedefinePasswordComponent, UiCCardRedefinePasswordModule } from '@wlp/ui-c-card-redefine-password';
import { UiCCardsManagerModule } from '@wlp/ui-c-cards-manager';
import { UiCHeaderModule } from '@wlp/ui-c-header';
import { UiCRequestCardModule } from '@wlp/ui-c-request-card';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BrMaskerModule } from 'br-mask';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';

import { CardActivePage } from './card-active/card-active.page';
import { CardIntroPage } from './card-intro/card-intro.page';
import { CardManagerPage } from './card-manager/card-manager.page';
import { CardsRoutingModule } from './cards-routing.module';
import { InterceptService } from "../../../../../../core/intercept/intercept.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { DataFilterPipe } from './data-filter.pipe';

@NgModule({
  declarations: [
    CardIntroPage,
    CardManagerPage,
    CardActivePage,
    DataFilterPipe
  ],
  imports: [
    BrMaskerModule,
    CommonModule,
    CardsRoutingModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    AngularSvgIconModule.forRoot(),
    ReactiveFormsModule,
    UiBsCardsModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule,
    UiCButtonModule,
    UiCHeaderModule,
    UiCCardsManagerModule,
    UiCCardRedefinePasswordModule,
    UiCRequestCardModule,
    UiCActiveCardModalFormModule,
    UiCActiveCardModule,
    UiCDatePickerModule.forRoot({ environment: "web" })
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  entryComponents: [
    UiCCardRedefinePasswordComponent,
  ],
})
export class CardsModule { }
