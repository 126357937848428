import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AccountTypeService, PersonalAddressService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from "rxjs";
import { catchError, retry, takeUntil, tap } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { LoadingService } from "../../../../../../../core/layout/services/loading.service";
import { Router } from "@angular/router";
import { environment } from "../../../../../../../../environments/environment";

@Component({
  selector: 'ui-t-personal-adress',
  templateUrl: './personal-address.page.html',
  styleUrls: ['./personal-address.page.scss'],
})
export class PersonalAddressPage implements OnInit, OnDestroy {

  form: FormGroup;
  formErrorMessages: any;

  listUf: Array<any>;
  navigationBackground: string;

  currentStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private personalAddressService: PersonalAddressService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private service: AccountTypeService,
    private stepsService: StepFlowService,
    private router: Router,
    private loadServide: LoadingService,
    private prepareFormDevolution: PreparFormDevolutionService
  ) {
    this.listUf = this.personalAddressService.getUf();
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  async ngOnInit() {
    this.loadServide.show();
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.form = this.personalAddressService.getFormPersonalAddress();
    this.formErrorMessages = this.personalAddressService.getFormErrorMessage();
    
    await this.personalAddressService.updateForm();
    
    this.currentStep = await this.stepsService.getSteps(environment, this.router.url);      
    await this.prepareFormDevolution.prepareFormDevolution(this.currentStep, this.form);
    this.listUf = this.personalAddressService.getUf();
    this.loadServide.hide();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchPostalCode(data: any) {
    this.personalAddressService.searchPostaCode(data.target.value);
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }

  goContinue() {
    if (this.form.invalid) {
      return;
    }

    this.loadServide.show();
    this.personalAddressService.sendPersonalAddress()
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res) => {
          this.loadServide.hide();
          await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        }),
        retry(2),
        catchError((err) => {
          this.loadServide.hide();
          this.personalAddressService.getErrorPersonalAddress(err);
          return of(null);
        })
      ).subscribe();
  }
}
