import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { ComercialAgentService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';

@Component({
  selector: 'comercial-agent-ec',
  templateUrl: './comercial-agent-ec.page.html',
  styleUrls: ['./comercial-agent-ec.page.scss'],
})
export class ComercialAgentEcPage implements OnInit {
  formComercialAgent: FormGroup;
  errorMessages: any;
  param: object;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private comercialAgentService: ComercialAgentService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.errorMessages = this.comercialAgentService.getMessageError();
    this.formComercialAgent = this.comercialAgentService.getFormComercialAgent();
    this.comercialAgentService.updateForm();
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  continue(): void {
    // this.comercialAgentService
    //   .sendComercialAgentData(this.formComercialAgent)
    //   .subscribe(
    //     data =>
    this.navCtrl.navigateRoot('/app/onboarding-ec/contract-ec');
    //   error => this.comercialAgentService.getErrorComercialAgentData(error)
    // );
  }
}
