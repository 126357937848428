import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService, UserAccountInfoResponse } from '@wlp/ui-bs-my-data';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-my-data',
  templateUrl: './my-data.page.html',
  styleUrls: ['./my-data.page.scss'],
})
export class MyDataPage implements OnInit {
  formMyData: FormGroup;
  errorMessages: any;
  typeAccount: string;
  successChange: boolean;
  dataForm: UserAccountInfoResponse;
  userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private myDataService: UiBsMyDataService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    protected userInfoService: UserInfoService,
    private navCtrl: NavController,
  ) {
    this.formMyData = this.myDataService.getFormMyData();
    this.errorMessages = this.myDataService.getFormMessageError();    
    this.typeAccount = 'MEI';
    this.successChange = false;
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.myDataService.getPersonalData(this.userProfile.uuidAccount)
          .pipe(
            tap((data) => {              
              this.dataForm = data;
            })
          )
          .subscribe();
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  changePassword() {
    this.navCtrl.navigateRoot(`app/change-password`);
  }
}
