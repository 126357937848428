import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { ToastController } from '@ionic/angular';
import { BilletService, AccountPaymentRechargeBilletResponse } from '@wlp/ui-bs-cashin'
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Observable, of } from 'rxjs';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-billet-view-detail.html',
  styleUrls: ['./modal-billet-view-detail.scss'],
  providers: [AccountPaymentRechargeBilletResponse],
})
export class ModalBilletViewDetailComponent implements OnInit {


  @Input() billetResponse: any;
  @Input() uuid: string;
  billetInfo = {}
  maskedBilletInfo = {};

  constructor(
    private billetService: BilletService,
    public modalController: ModalController,
    public clipboard: Clipboard,
    public toastController: ToastController,
    private loadingService: LoadingService,
  ) {
  }

  ngOnInit() {
    this.getBillets();
  }

  dismiss() {
    this.modalController.dismiss().then((res) => { }, (err) => {
      console.error(err);
    });
  }

  getCode() {
    const billetCode = document.getElementById("billetCode");
    const textBilletCode = billetCode.textContent;
    return textBilletCode
  }

  public copyContent() {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = this.getCode();
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);

    this.presentToast('bottom');
  }

  async presentToast(position: 'bottom') {
    const toast = await this.toastController.create({
      message: 'Dados copiados!',
      duration: 1500,
      position: position
    });

    await toast.present();
  }

  public getBilletsByUuid(uuid: string): Observable<AccountPaymentRechargeBilletResponse> {
    try {
      const billet = this.billetService.getBilletsByUuid(uuid);
      if (!billet) {
        throw new Error('getBilletsByUuid retornou null');
      }
      return billet;
    } catch (error) {
      console.error(error);
      return of(null);
    }
  }

  private getBillets() {
    try {
      this.loadingService.show();
      this.getBilletsByUuid(this.uuid).subscribe(billet => {
        this.billetInfo = billet;

        Object.entries(this.billetInfo).forEach(([key, value]: [string, string]) => {
          this.maskedBilletInfo[key] = value;
          if (key === 'typeableLine' && value) {
            let typeableLine = value;
            let formattedLine = typeableLine.substring(0, 5) + '.' + typeableLine.substring(5, 10) + ' ' +
              typeableLine.substring(10, 16) + '.' + typeableLine.substring(16, 22) + ' ' +
              typeableLine.substring(22, 27) + '.' + typeableLine.substring(27, 33) + ' ' +
              typeableLine.substring(33, 34) + ' ' +
              typeableLine.substring(34, 47);
            this.maskedBilletInfo[key] = formattedLine;
          }

          if (key === 'namePayer') {
            let namePayer = value.toLowerCase();
            let formattedName = namePayer.replace(/\b(\w)/g, firstLetter => firstLetter.toUpperCase());
            this.maskedBilletInfo[key] = formattedName;

          }

          if (key === 'value') {

            let valueNumber = parseFloat(value);
            let formattedValue = valueNumber.toFixed(2).toString().replace('.', ',');
            this.maskedBilletInfo[key] = formattedValue;
          }
          if (key === 'requestDescription' && value) {
            let requestDescription = value.toLowerCase();
            let formattedDescription = requestDescription.replace(/\b(\w)/g, firstLetter => firstLetter.toUpperCase());
            this.maskedBilletInfo[key] = formattedDescription;
          }
          if (key === 'dateRegister' || key === 'billetDueTime') {
            let date = value;
            let day = ('0' + date[2]).slice(-2);
            let month = ('0' + date[1]).slice(-2);
            let formattedDate = day.concat('/', month, '/', date[0]);
            this.maskedBilletInfo[key] = formattedDate;
          }
          this.loadingService.hide()
        });
      });
    } catch (error) {
      this.loadingService.hide();
    }
  }

  printBilletOnPage(transactionId: string) {
    this.billetService.getBilletDownload(transactionId).
      pipe(
        tap((blob) => {
          saveAs(new Blob([blob]), `Boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
        }))
      .subscribe();
  }

}
