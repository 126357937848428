import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billetCreateDataFilter',
})
export class BilletCreateDataFilterPipe implements PipeTransform {

  private PENDING = "PENDING";
  private COMPENSATED = "COMPENSATED";
  private CANCELED = "CANCELED";

  transform(names: any[], terms: string): any[] {
    if (!names) return [];
    if (!terms) return names;

    terms = terms.toLowerCase();
    terms = terms.replace(',', '.');

    if (terms.includes("pend")) {
      terms = this.PENDING.toLowerCase();
    }
    if (terms.includes("canc")) {
      terms = this.CANCELED.toLowerCase();
    }
    if (terms.includes("com")) {
      terms = this.COMPENSATED.toLowerCase();
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
      // if (it.name.toLowerCase() === terms) {
      //   return false;
      // } else {
      //   return JSON.stringify(it).toLowerCase().includes(terms);
      // }
    });
  }

  transformReceipts(data: any[], search) {
    if (!search) return data;    
    return data.filter(info => {
      if (info.requestDescription || info.namePayer || info.documentPayer || info.requestNumber) {
        let requestDescription = info.requestDescription.toLowerCase() ? info.requestDescription.toLowerCase() : '';
        let namePayer = info.namePayer.toLowerCase() ? info.namePayer.toLowerCase() : '';
        let documentPayer = info.documentPayer.toLowerCase() ? info.documentPayer.toLowerCase() : '';
        let requestNumber = info.requestNumber.toLowerCase() ? info.requestNumber.toLowerCase() : '';
        return requestDescription.indexOf(search.toLowerCase()) > -1 || namePayer.indexOf(search.toLowerCase()) > -1
          || documentPayer.indexOf(search.toLowerCase()) > -1
          || requestNumber.indexOf(search.toLowerCase()) > -1;
      }
    });
  }

  public transformPipeLabel(names: any[], terms: string, labels?: string[]): any[] {
    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    return names.filter((it) => {
      /* return it[label].toLowerCase().includes(terms); */
      if (labels.length > 0) {
        labels.forEach(label => {
          return it[label].toLowerCase().includes(terms);
        });
      } else {
        return JSON.stringify(it).toLowerCase().includes(terms);
      }
    });
  }
}
