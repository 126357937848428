import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCSwiperModule } from '@wlp/ui-c-swiper';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { InterceptService } from '../../../../../core/intercept/intercept.service';
import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { DownloadAppPageRoutingModule } from './download-app-routing.module';

import { DownloadAppPage } from './download-app.page';
import { RedirectPage } from './redirect/redirect.page';

@NgModule({
  imports: [
    IonicModule,
    TranslateModule,
    UiCSwiperModule,
    UiCContainerBrandModule,
    ComponentsModule,
    DownloadAppPageRoutingModule,
    CommonModule,
    NgxQRCodeModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    DownloadAppPage,
    RedirectPage
  ],
  entryComponents: [
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class DownloadAppModule { }
