import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { NavController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import {of, Subject} from "rxjs";
import {AccountTypeService, ComercialAgentService, CreateAccountService, Step, StepFlowService} from "@wlp/ui-bs-signup";
import {FormGroup} from "@angular/forms";
import {catchError, retry, takeUntil, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {environment} from "../../../../../../../../environments/environment";
import {LoadingService} from "../../../../../../../core/layout/services/loading.service";

@Component({
  selector: 'ui-t-comercial-agent',
  templateUrl: './comercial-agent.page.html',
  styleUrls: ['./comercial-agent.page.scss'],
})
export class ComercialAgentPage implements OnInit, OnDestroy {
  form: FormGroup;
  errorMessages: any;

  currentStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private navCtrl: NavController,
    private comercialAgentService: ComercialAgentService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private stepsService: StepFlowService,
    private router: Router,
    private loadService: LoadingService,
    private service: AccountTypeService,
    private createAccountService: CreateAccountService
  ) { }

  async ngOnInit() {
    try {
      this.loadService.show();
      const layoutConfigValue = this.layoutConfigService.getSavedConfig();
      const langDefault = layoutConfigValue.wlTheme.languageDefault;
      this.translate.setDefaultLang(langDefault);

      this.errorMessages = this.comercialAgentService.getMessageError();
      this.form = this.comercialAgentService.getFormComercialAgent();

      await this.comercialAgentService.updateForm();
      this.loadService.hide();
      this.currentStep = await this.stepsService.getSteps(environment, this.router.url);
    } catch (e) {
      console.error(e)
      this.loadService.hide();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goContinue(): void {
    if(this.form.invalid) {
      return;
    }
    this.loadService.show();
    this.comercialAgentService.sendComercialAgentData(this.form)
      .pipe(
        takeUntil(this.destroy$),
        tap(async (res) => {
          this.loadService.hide();
          await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        }),
        retry(2),
        catchError((error) => {
          this.loadService.hide();
          this.comercialAgentService.getErrorComercialAgentData(error)
          return of(null);
        })
      ).subscribe();
  }

  goBack() {
    if(this.currentStep && this.currentStep.previousStep) {
      this.navCtrl.navigateRoot(this.currentStep.previousStep);
    }
  }
}
