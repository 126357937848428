import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { BilletService, AccountPaymentRechargeBilletResponse } from '@wlp/ui-bs-cashin'
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'modal-billet-view-detail-v2',
    templateUrl: './modal-billet-view-detail-v2.component.html',
    styleUrls: ['./modal-billet-view-detail-v2.component.scss']
})

export class ModalBilletViewDetailV2Component implements OnInit {
    @Input() uuid: string;
    billet: AccountPaymentRechargeBilletResponse;

    constructor(
        private billetService: BilletService,
        public modalController: ModalController,
        public toastController: ToastController,
        private loadingService: LoadingService
    ) {
        moment.locale('pt-br');
    }

    ngOnInit() {
        this.getBilletsByUuid();
    }

    getBilletsByUuid() {
        this.loadingService.show();
        this.billetService.getBilletsByUuid(this.uuid).subscribe(
            (res) => {
                this.billet = res;
                this.loadingService.hide();
            },
            (error) => {
                this.loadingService.hide();
            }
        )
    }

    closeModal() {
        this.modalController.dismiss(true);
    }

    copyTypeableLine() {
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = this.billet.typeableLine;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);

        this.presentToast('bottom');
    }

    async presentToast(position: 'bottom') {
        const toast = await this.toastController.create({
            message: 'Dados copiados!',
            duration: 1500,
            position: position
        });

        await toast.present();
    }

    printBilletOnPage() {
        this.billetService.getBilletDownload(this.billet.transactionId).
            pipe(
                tap((blob) => {
                    saveAs(new Blob([blob]), `Boleto-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
                }))
            .subscribe();
    }

    calculateDiscountDeadline(billetDiscountDays: number) {
        const billetDueTimeMoment = moment(this.formatDate(this.billet.billetDueTime), "DD/MM/YYYY").add(billetDiscountDays, 'days');
        return billetDueTimeMoment.format("DD/MM/YYYY");
    }

    formatDate(date: Date) {
        const dateValue = date;
        const day = ('0' + date[2]).slice(-2);
        const month = ('0' + date[1]).slice(-2);
        const formattedDate = day.concat('/', month, '/', dateValue[0]);
        return formattedDate;
    }

    formatTypeableLine(typeableLine: string) {
        const typeableLineValue = typeableLine;
        const formattedLine = typeableLineValue.substring(0, 5) + '.' + typeableLineValue.substring(5, 10) + ' ' +
            typeableLineValue.substring(10, 16) + '.' + typeableLineValue.substring(16, 22) + ' ' +
            typeableLineValue.substring(22, 27) + '.' + typeableLineValue.substring(27, 33) + ' ' +
            typeableLineValue.substring(33, 34) + ' ' +
            typeableLineValue.substring(34, 47);
        return formattedLine;
    }
}
