import { CardsService } from '@wlp/ui-bs-cards';
import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { NotificationsController } from '../../notifications/notifications.controller';

@Component({
  selector: 'modal-expense-control',
  templateUrl: './modal-expense-control.component.html',
  styleUrls: ['./modal-expense-control.component.scss'],
})
export class ModalExpenseControlComponent {

  public expenseForm: FormGroup;
  public errorMessages: any;
  public activeOnShop: boolean;
  public isUpOrEqual: boolean;
  @Input() public card: any;

  constructor(
    public modalController: ModalController,
    private cardService: CardsService,
  ) {
    this.errorMessages = this.cardService.getFormMessageErrorExpense();
    this.expenseForm = this.cardService.createExpenseControlForm();
  }

  ionViewDidEnter() {
    this.loadData();
  }

  public confirm() {
    let form = this.expenseForm.getRawValue();
    let prepare = {
      uuidPaymentCard: this.card.uuid,
      dailyLimit: form.expenseDaily == '' ? null : form.expenseDaily.replace('.', '').replace(',', '.'),
      monthlyLimit: form.expenseMonthly == '' ? null : form.expenseMonthly.replace('.', '').replace(',', '.'),
      onlineAllowed: this.activeOnShop ? this.activeOnShop : false,
    }
    this.modalController.dismiss(prepare);
  }

  public resetForm() {
    this.errorMessages = this.cardService.getFormMessageErrorExpense();
    this.expenseForm = this.cardService.createExpenseControlForm();
  }

  private loadData() {
    this.expenseForm.setValue({
      expenseDaily: (this.card.dailyLimit == -1 || !!!this.card.dailyLimit) ? 'R$ 100,00' : this.formatReal(this.card.dailyLimit),
      expenseMonthly: (this.card.monthlyLimit == -1 || !!!this.card.monthlyLimit) ? 'R$ 1.000,00' : this.formatReal(this.card.monthlyLimit)
    })
    this.activeOnShop = this.card.onlineAllowed;
  }

  private formatReal(expense: any) {
    if (!!expense) {
      let value = expense.toFixed(2);
      value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
      value = value.toString().replace('R$', '');
      return value.replace(/\s/g, '');
    } else {
      return 'R$ 0,00';
    }
  }

  public compareValue() {
    let form = this.expenseForm.getRawValue();
    const day = parseFloat(form.expenseDaily.replace('.', '').replace(',', '.'));
    const month = parseFloat(form.expenseMonthly.replace('.', '').replace(',', '.'));
    if (month !== null) {
      this.isUpOrEqual = month >= day;
      if (!this.isUpOrEqual) {
        this.expenseForm.controls.expenseMonthly.setErrors({});
      } else if (month === null || '') {
        this.isUpOrEqual = true;
      }
    }
  }

  public close() {
    this.modalController.dismiss();
  }

  public formatNumber(number) {
    return `•••• •••• •••• ${number.substr(2)}`;
  }
}
