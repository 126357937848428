import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { CompanyAddressService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { of, Subject } from 'rxjs';
import { catchError, retry, takeUntil, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'ui-t-company-address',
  templateUrl: './company-address.page.html',
  styleUrls: ['./company-address.page.scss'],
})
export class CompanyAddressPage {
  companyAddressForm: FormGroup;
  errorMessages: any;
  listUf: Array<any>;

  nextStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private companyAddressService: CompanyAddressService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private stepsService: StepFlowService,
    private router: Router,
    private prepareFormDevolution: PreparFormDevolutionService
  ) {
  }

  async ngOnInit() {
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.companyAddressForm = this.companyAddressService.getCompanyAddressForm();
    this.errorMessages = this.companyAddressService.getErrorMessage();
    await this.companyAddressService.updateForm();
    this.listUf = this.companyAddressService.getUf();

    this.nextStep = await this.stepsService.getSteps(environment, this.router.url);

    await this.prepareFormDevolution.prepareFormDevolution(this.nextStep, this.companyAddressForm);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goContinue() {
    this.companyAddressService.sendCompanyAddress()
      .pipe(
        takeUntil(this.destroy$),
        tap(async res => {
          await this.stepsService.nextStep(this.navCtrl, this.nextStep);
        }),
        retry(2),
        catchError((err) => {
          this.companyAddressService.getErrorCompanyAddress(err);
          return of(null);
        })
      ).subscribe();
  }

  searchPostalCode(data: any) {
    this.companyAddressService.searchPostaCode(data.target.value);
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.nextStep);
  }
}
