import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class LoadingService {

  public isLoading = false;

  constructor(public loadingController: LoadingController) {}

  public async show() {
    this.isLoading = true;

    return await this.loadingController.create({
      spinner: 'circular',
      cssClass: 'ion-loading',
    }).then((a) => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss();
        }
      });
    });
  }

  public async hide() {
    // console.log('## LoadingService.hide isLoading: ', this.isLoading);
    if (this.isLoading) {
      this.isLoading = false;
      return await this.loadingController.dismiss().then((res) => {})
        .catch((error) => {
          console.log('## LoadingService.hide error: ', error);
          // console.log('error', error);
        });
    }
    return null;
  }
}
