import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';

@Component({
  selector: 'modal-view-file-upload',
  templateUrl: './modal-view-file-upload.component.html',
  styleUrls: ['./modal-view-file-upload.component.scss']
})
export class ModalViewFileUpload implements OnInit {

  @Input() public imageUrl?: string;
  @Input() public type?: string;
  @Input() public file?: any;


  pdfContent: any;
  @ViewChild('pdfview', { static: true }) pdfview: ElementRef;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private load: LoadingService,
    private translate: UiBsTranslateService,
  ) {
  }

  ngOnInit() {    
    if (this.type === 'pdf') {
      this.showData(this.imageUrl.split(',')[1])
    }
  }


  confirm(confirm: boolean) {
    this.modalController.dismiss();
  }

  showData(content: any) {
    this.load.show();
    this.pdfContent =
      URL.createObjectURL(this.b64toBlob(content, 'application/pdf')) +
      '#toolbar=0&navpanes=0&scrollbar=0&view=FitH';

    this.load.hide();
    this.pdfview.nativeElement.setAttribute('data', this.pdfContent);
  }

  b64toBlob(b64Data, contentType) {
    var byteCharacters = window.atob(b64Data);

    var byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      var slice = byteCharacters.slice(offset, offset + 512),
        byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  download() {
    const linkSource = `${this.imageUrl}`;
    const downloadLink = document.createElement("a");
    const fileName = new Date().toDateString();
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  prepareTitle(documentTpe) {
    /* this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.ALERTS.MESS') */
    switch (documentTpe) {
      case "RG":
        return `${this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.RG')} - ${this.translate.applyTranslate('ONBORDING.V2.FORM.LABEL.'+this.file.positionPhoto)} ` ;        
      case "SELF_PHOTO":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.SELFIE');        
      case "CNH":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.CNH');        
      case "RESIDENCIAL_CERTIFICATE":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.RESIDENTIAL_CERTIFICATE');        
      case "PROFESSIONAL_CERTIFICATE":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.PROFESSIONAL_CERTIFICATE');        
      case "SOCIAL_CONTRACT":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.SOCIAL_CONTRACT');        
      case "MINUTES_MEETING":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.MINUTES_MEETING');        
      case "CNPJ":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.CNPJ');        
      case "ATTORNEY_LETTER":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.ATTORNEY_LETTER');        
      case "RESIDENCIAL_CERTIFICATE_LEGAL_PERSON":
        return this.translate.applyTranslate('ONBORDING.UPLOAD_DOCUMENTS_WEB.MODAL_VIEW_DOC.RESIDENCIAL_CERTIFICATE_LEGAL_PERSON');        
      default:
        break;
    }
  }
}
