import { Component } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { AccountBalanceService, StatementService } from '@wlp/ui-bs-cashin';
import { ClientLimitRateService } from '@wlp/ui-bs-cashout';
import {
  AccountsBenefitsService,
  CheckMigrationResponseDto,
  MigrationService,
  ShortcutsService,
  StatusStepMigrationEnum,
} from '@wlp/ui-bs-dashboard';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import { UiCModalLimitRateComponent } from '@wlp/ui-c-modal-limit-rate';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { environment } from 'src/environments/environment';

import { ModalMigrationComponent } from '../../../modals/modal-migration/modal-migration.component';
import { NotificationsPage } from '../notifications/notifications.page';

@Component({
  selector: 'ui-t-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage {
  realm: string;
  modalPixBackgroundImage: string;
  balanceSubject$: any;
  balance: string;
  balanceResponse: any;
  userResponse: any;
  nameInitials: string;
  flagDisplayCard: boolean;
  flagDisplayNotification: boolean;
  listExtract: any;
  userProfile: UserInfoProfile;
  slideSquare: object;
  public slideFull: object;
  public reloadIcon: boolean;
  public reloadIconBalance: boolean;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  public shortcuts: any = [];
  public debitMonth: number = null;
  public creditMonth: number = null;
  public accountBalance: string = null;
  public bankTransition: string = null;
  public toggleAccountBalance: boolean = false;
  public toggleBankTransition: boolean = false;
  public toggleSpinnerAccountBalance: boolean = false;
  public toggleSpinnerBankTransition: boolean = false;
  isMobile: boolean = false;
  public typeAccount: string = null;
  public listAccounts: boolean = false;


  public customHeaderMigration = '';

  private checkMigration: CheckMigrationResponseDto;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountBalanceService: AccountBalanceService,
    private accountsBenefitsService: AccountsBenefitsService,
    private statementService: StatementService,
    private headerInfoService: UICHeaderUserInfoService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private loadingService: LoadingService,
    protected userInfoService: UserInfoService,
    private clientLimitRate: ClientLimitRateService,
    private shortcutsService: ShortcutsService,
    private platform: Platform,
    private migrationService: MigrationService,
  ) {
    moment.locale('pt-br');
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
    this.slideSquare = this.layoutConfigService.getImagesPages('swiper-square', true);
    this.slideFull = this.layoutConfigService.getImagesPages('swiper-full', true);
    this.modalPixBackgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
  }

  ionViewWillEnter() {
    this.isMobile = this.platform.is('mobile');
    this.loadUser();
    this.headerInfoService.updateValue(true);
  }

  public getAllShortcuts(uuidWhiteLabel, uuidAccount) {
    this.shortcutsService.getAllShortcuts(uuidWhiteLabel, uuidAccount, 'WEB').subscribe(
      (response) => {
        this.shortcuts = response;
      },
      (error) => console.error(error)
    );
  }

  public getNewValueUrl(uuidAccount: string, benefitShortcut: any): Observable<any> {
    return new Observable(observer => {
      this.accountsBenefitsService.newValue(uuidAccount).subscribe(
        (response: any) => {
          benefitShortcut.url = response.url;
          observer.next(response);
          observer.complete();
        },
        (error: any) => {
          console.error('Ocorreu um erro ao buscar a nova URL:', error);
          benefitShortcut.url = "https://www.newvalue.com.br/activate_cpf?uuid=f8855863-3514-46a0-8b8e-25d581464f2e";
          observer.error(error);
        }
      );
    });
  }

  public goToShortcut(url: string) {
    const oldReturnUrl = 'https://www.newvalue.com.br/activate_cpf?uuid=f8855863-3514-46a0-8b8e-25d581464f2e';

    if (url === oldReturnUrl) {
      const uuidAccount = this.userProfile.uuidAccount;
      const benefitShortcut = { url: oldReturnUrl };
      this.loadingService.show();
      this.getNewValueUrl(uuidAccount, benefitShortcut).subscribe(
        () => {
          this.openUrl(benefitShortcut.url);
          this.loadingService.hide();
        }
      );
    } else {
      this.openUrl(url);
    }
  }

  public openUrl(url: string) {
    if (url.includes('http:') || url.includes('https:')) {
      window.open(url, '_blank');
    } else {
      return this.navCtrl.navigateRoot([url]);
    }
  }

  public showAccountValues(data) {
    if (data === 'balance') {
      this.toggleAccountBalance = !this.toggleAccountBalance;
      this.getAccountBalance();
    } else {
      this.toggleBankTransition = !this.toggleBankTransition;
      this.getBankTransition();
    }
  }

  public refreshAccountValues(data) {
    if (data === 'balance') {
      this.toggleSpinnerAccountBalance = true;
      this.getAccountBalance();
    } else {
      this.toggleSpinnerBankTransition = true;
      this.getBankTransition();
    }
  }

  public getAccountBalance(): void {
    this.headerInfoService.loadUser();
    this.accountBalanceService
      .getBalanceSubject()
      .pipe(
        tap((data) => {
          if (this.toggleAccountBalance) this.accountBalance = !!data ? String(data) : '0';
          else this.accountBalance = null;
          setTimeout(() => {
            this.toggleSpinnerAccountBalance = false;
          }, 1500);
        })
      )
      .subscribe();
  }

  public getBankTransition(): void {
    this.statementService
      .getSumMoviments(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((response) => {
        if (this.toggleBankTransition) {
          this.debitMonth = !!response.debit ? response.debit : 0;
          this.creditMonth = !!response.credit ? response.credit : 0;
        } else {
          this.debitMonth = null;
          this.creditMonth = null;
        }
        setTimeout(() => {
          this.toggleSpinnerBankTransition = false;
        }, 1500);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  public redirectExtractDetail() {
    if (this.userProfile.isRoleMenu('EXTRACT_CONSOLIDADED')) {
      this.navCtrl.navigateRoot('/app/extract/consolidaded/current');
    } else {
      this.navCtrl.navigateRoot('/app/extract/digital-account/current');
    }
  }

  private loadStatusMigration() {
    this.migrationService.getCheckMigration().subscribe(res => {
      this.checkMigration = res;

      if (this.checkMigration && this.checkMigration.migrated && this.checkMigration.status == StatusStepMigrationEnum.ITAU_OPENED) {
        this.modalMigrationNotification(this.checkMigration, this.balance);
      }

    }, error => {
      console.log('loadStatusMigration:: Não foi possivel carregar informacoes de migracao - ', error);
      this.checkMigration = null;
    });
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadStatement(user.uuidWhiteLabel, user.uuidAccount);
        const limitNotifications = this.layoutConfigService.getSavedConfig();
        if (!this.platform.is('mobile')) {

          if (user.codeBank.includes('633')) {
            this.customHeaderMigration = 'Rendimento - ';
            this.listAccounts = this.userProfile.listAccounts.length > 1;
            this.loadStatusMigration();
          } else {

            setTimeout(() => {
              this.listAccounts = this.userProfile.listAccounts.length > 1;
              if (limitNotifications.wlTheme.limitNotification && user.listAccounts[0].accountType !== 'Usuário Escrow') {
                this.loadClientLimitRate(user.uuidWhiteLabel, user.uuidAccount);
              }
            }, 1500);

          }
          // Get name account type
          this.userProfile.listAccounts.forEach((typeAccount) => {
            if (this.userProfile.uuidAccount === typeAccount.uuidAccount) {
              this.typeAccount = typeAccount.accountType;
              switch (this.typeAccount) {
                case 'Pessoa Física':
                  this.typeAccount = 'PF';
                  break;
                case 'Pessoa Jurídica':
                  this.typeAccount = 'PJ';
                  break;
                case 'Profissional Liberal':
                  this.typeAccount = 'PL';
                  break;
                case 'Profissional MEI':
                  this.typeAccount = 'MEI';
                  break;
                case 'Escrow(PF)':
                  this.typeAccount = 'Escrow PF';
                  break;
                case 'Escrow(PJ)':
                  this.typeAccount = 'Escrow PJ';
                  break;
                default:
                  this.typeAccount = typeAccount.accountType;
              }
            }
          });
        }
        this.getAllShortcuts(user.uuidWhiteLabel, user.uuidAccount);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private loadStatement(uuidWL: string, uuidAccount: string): void {
    this.statementService
      .getLatestTransaction(uuidWL, uuidAccount, 6)
      .then((res) => {
        this.listExtract = res;
        setTimeout(() => {
          this.reloadIcon = false;
        }, 1500);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  public onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagDisplayCard = this.flagDisplayCard ? false : true;
  }

  public onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = this.flagDisplayNotification ? false : true;
  }

  public iconGrid() {
    this.navCtrl.navigateRoot('#');
  }

  async showNotifications() {
    const modal = await this.modalController.create({
      component: NotificationsPage,
      cssClass: 'modal-custom-class',
    });
    return await modal.present();
  }

  public getInitial(name) {
    const parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  public checkValue(value: number): boolean {
    return value > 0 ? true : false;
  }

  public excerptText(text: string): string {
    return `${text.substr(0, 25)} ...`;
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  public isVisibleBox() {
    return !environment.production;
  }

  public isVisibleCardBox() {
    return false; // this.realm === 'psxbank' || this.realm === 'urbanobank' || this.realm === 'prospera';
  }

  public isVisibleCardBoxProducts() {
    return this.realm === 'prospera' || this.realm === 'psxbank';
  }

  public reloadStatmentByClick() {
    this.reloadIcon = true;

    if (this.userProfile) {
      this.loadStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
    }
  }

  public async modalLimitRate(response: any, config: LayoutConfigModel) {
    const modal = await this.modalController.create({
      component: UiCModalLimitRateComponent,
      cssClass: 'ui-c-modal-limit-rate',
      componentProps: {
        response,
        config,
        limitRoute: 'app/account-limits',
        version: environment.appVersion,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public loadClientLimitRate(uuidWl: string, uuidAccountPayment: string) {
    const config = this.layoutConfigService.getSavedConfig();
    this.clientLimitRate.getClientLimitRate(uuidWl, uuidAccountPayment).subscribe((arg) => {
      this.modalLimitRate(arg, config);
    });
  }

  formatDate(date) {
    return moment(date).format('L  hh:mm:ss');
  }

  async modalMigrationNotification(checkMigration: CheckMigrationResponseDto, balance: string) {
    const modal = await this.modalController.create({
      component: ModalMigrationComponent,
      cssClass: 'modal-migration-class',
      componentProps: {
        migration: checkMigration,
        balance: balance,
        userProfile: this.userProfile
      },
    });
    return await modal.present();
  }
}
