import { Component, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { IntroService } from '@wlp/ui-bs-intro';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { LoadingService } from './../../../../../core/layout/services/loading.service';
import { PopoverComponent } from '../intro/popover/popover.component';

@Component({
  selector: 'ui-t-download-app',
  templateUrl: './download-app.page.html',
  styleUrls: ['./download-app.page.scss'],
})
export class DownloadAppPage implements OnInit {

  public brand: string;
  public background: string;
  public brandFooter: string;
  public phoneImage : string;
  public linkGooglePlay: string;
  public linkAppStore: string;
  public isMobile: boolean;
  public linkRedirectQrcode: string;
  public env: any;
  public realm: string;
  public language: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public isProd: boolean = environment.production;

  constructor(
    private introService: IntroService,
    private layoutConfigService: LayoutConfigService,
    private platform: Platform,
    private translate: UiBsTranslateService,
    public popoverController: PopoverController,
    private loadingService: LoadingService,
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.brandFooter = this.layoutConfigService.getConfig('wlTheme.intro.footer.brand');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');
    this.phoneImage = '/assets/images/download-app/phone-circle.png';
    this.linkAppStore = 'https://apps.apple.com/us/app/urbano-bank/id1608974889';
    this.linkGooglePlay = 'https://play.google.com/store/apps/details?id=com.urbanobank.app.bank';

    this.env = environment.production;
    
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
    this.language = this.translate.setLanguage(this.langDefault);
    this.introService.cleanStorage();
    this.isMobile = this.platform.is('mobile');
    sessionStorage.removeItem(environment.keyStorage.userEscrow);
    sessionStorage.removeItem(environment.keyStorage.menu);

    if(this.layoutConfigValue.wlTheme.company.appUrl){
      this.linkRedirectQrcode = this.layoutConfigValue.wlTheme.company.appUrl;
    }

  }

  public goTo(link:string){
    window.open(link, '_blank');
  }
  

  public pathFlag() {
    return this.translate.getFlag(this.language);
  }

  async presentPopover(ev: any) {
    const languageOptions = this.translate.getListLanguage();
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      cssClass: 'my-custom-class',
      event: ev,
      componentProps: {
        items: languageOptions
      },
      translucent: true
    });
    popover.onDidDismiss().then((res) => {
      if (res.data !== undefined) {
        this.language = res.data.language;
        this.languageChange(res.data.value)
      }
    });
    await popover.present();
  }

  public languageChange(languageData) {
    this.loadingService.show();

    this.langDefault = languageData;
    this.layoutConfigService.setConfig({ wlTheme: { languageDefault: this.langDefault } }, true);
    this.translate.use(this.langDefault);
    this.language = this.translate.setLanguage(this.langDefault);
    setTimeout(() => {
      this.loadingService.hide();
    }, 1500);
  }
}
