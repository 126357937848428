import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'ui-t-redirect',
  templateUrl: './redirect.page.html'
})
export class RedirectPage implements OnInit {


  public androidStoreLink: string;
  public iosStoreLink: string;
  public isMobile: boolean;

  public isProd: boolean = environment.production;

  constructor(
  ) {
  }

  

  ngOnInit() {
    this.iosStoreLink = 'https://apps.apple.com/us/app/urbano-bank/id1608974889';
    this.androidStoreLink = 'https://play.google.com/store/apps/details?id=com.urbanobank.app.bank';
    this.redirectBasedOnOS();
  }


 redirectBasedOnOS() {
    var userAgent = navigator.userAgent || navigator.vendor
    var androidAppLink = "yourapp://home";  // Link deep do app Android
    var iosAppLink = "yourapp://home";      // Link deep do app iOS


    // Verifica se o dispositivo é Android
    if (/android/i.test(userAgent)) {
        window.location.href = androidAppLink;
        setTimeout(function() {
            window.location.href = this.androidStoreLink;
        }, 2000);
    }
    // Verifica se o dispositivo é iOS
    else if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href = iosAppLink;
        setTimeout(function() {
            window.location.href = this.iosStoreLink;
        }, 2000);
    }
    // Caso não seja Android ou iOS, redireciona para uma página padrão
    else {
        window.location.href = this.androidStoreLink;
    }
}
  
}
