import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { PrePixService } from '@wlp/ui-bs-pre-pix';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-contract-pix',
  templateUrl: './contract-pix.component.html',
  styleUrls: ['./contract-pix.component.scss'],
})
export class ContractPixComponent implements OnInit {
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private loadingService: LoadingService,
    private prePixService: PrePixService,
    private alertController: AlertController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  acceptPix() {
    this.loadingService.show();
    this.prePixService.getPrePixFormFromBehavior().subscribe((prePix) => {
      prePix = { ...prePix, accepted: 'TRUE', dateRegister: new Date().toISOString() };
      this.prePixService.createPrePix(prePix).subscribe((prePixResponse) => {
        this.loadingService.hide();
        const { user, message } = prePixResponse;
        this.alertController.create({ message });
        if (user) {
          this.navCtrl.navigateRoot(['app/pix/pre-register/pix-conclusion']);
          return;
        }
      });
    });
  }

  ngOnInit() {}

  goBack() {
    this.navCtrl.navigateRoot(['app/pix/pre-register/choose-key']);
  }
  continue() {
    this.acceptPix();
  }
}
