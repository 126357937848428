import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { ClientLimitRateService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { LoadingService } from './../../../../../../core/layout/services/loading.service';
import { ModalAlertComponent } from './../../../modals/modal-alert/modal-alert.component';


@Component({
  selector: 'app-account-limit',
  templateUrl: './account-limit.page.html',
  styleUrls: ['./account-limit.page.scss']
})
export class AccountLimitPage implements OnInit {

  private userProfile: UserInfoProfile;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  public registeredLimits: any;
  public avaiableLimits: any;
  public form: FormGroup;
  public errorMessages: any;
  public dateMonthly: any;

  constructor(
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private clientLimitRateService: ClientLimitRateService,
    private navCtrl: NavController,

    private cdr: ChangeDetectorRef,
    public modalController: ModalController,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.errorMessages = this.clientLimitRateService.getFormMessageError();
    this.form = this.clientLimitRateService.buildForm();
  }
  
  ionViewWillEnter() {
    this.loadUser();
    // this.loadClientLimitRate(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
    // this.loadClientLimitAvaiable(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadClientLimitRate(user.uuidWhiteLabel, user.uuidAccount);
        this.loadClientLimitAvaiable(user.uuidWhiteLabel, user.uuidAccount);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private loadClientLimitRate(uuidWl: string, uuidAccountPayment: string) {
    this.clientLimitRateService.getClientLimitRate(uuidWl, uuidAccountPayment)
      .subscribe((res) => {
        this.registeredLimits = res;
        if (res) {
          this.form.setValue({
            amountDailyLimit: res ? res.amountDailyLimit : '',
            amountThirtyLimit: res ? res.amountThirtyLimit : '',
            valueDailyLimit: res ? this.formatReal(res.valueDailyLimit) : '',
            valueThirtyLimit: res ? this.formatReal(res.valueThirtyLimit) : ''
          })
        }
        this.cdr.detectChanges();
      });
  }

  formatReal(vl) {
    let value = vl
    value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    value = value.toString().replace('R$', '')
    return value.replace(/\s/g, '')
  }

  private loadClientLimitAvaiable(uuidWl: string, uuidAccountPayment: string) {
    this.clientLimitRateService.getClientLimitAvaiable(uuidWl, uuidAccountPayment)
      .subscribe((res) => {
        this.avaiableLimits = res;
        this.dateMonthly = res.dateMonthly;
        this.cdr.detectChanges();
      });
  }

  public async notification(status: string, message: string, confirmBtn: string, description?: string, confirmation?: boolean) {
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: confirmation ? 'modal-confirmation' : 'modal-notification',
      componentProps: {
        status: status,
        message: message,
        description: description,
        confirmBtn: confirmBtn,
        cancelBtn: 'LIMITS_RATE.BUTTONS.CANCEL_BUTTON',
        confirmation: confirmation,
      },
      backdropDismiss: false,
    });
    modalStatus.onDidDismiss().then((callback) => {
      if (callback.data === true) {
        this.requestLimitRate();
      }
      if (status === 'success') {
        this.navCtrl.navigateRoot('/app/dashboard');
      }
    });
    return await modalStatus.present();
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public request() {
    if ((this.formatValue(this.form.get('amountDailyLimit').value) >= this.formatValue(this.form.get('amountThirtyLimit').value)) || (this.formatValue(this.form.get('valueDailyLimit').value) >= this.formatValue(this.form.get('valueThirtyLimit').value))) {
      this.notification(
        'warning-circle',
        'LIMITS_RATE.MESSAGES.INVALID_VALUES',
        'LIMITS_RATE.BUTTONS.CLOSE_BUTTON',
        'LIMITS_RATE.MESSAGES.DETAILS_INVALID_VALUES',
        false
      );
    } else {
      this.notification('warning-circle', 'LIMITS_RATE.MESSAGES.REQUEST_CONFIRMATION', 'LIMITS_RATE.BUTTONS.CONFIRM_BUTTON', undefined, true);
    }
  }

  private formatValue(value: any) {
    let aValue = value.replace(/\./g, '');
    let bValue = aValue.replace(',', '.');
    return parseFloat(bValue);
  }

  private requestLimitRate() {
    this.loadingService.show();
    const requestBody = {
      uuidAccountPayment: this.userProfile.uuidAccount,
      uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
      amountDailyLimit: this.form.get('amountDailyLimit').value,
      amountThirtyLimit: this.form.get('amountThirtyLimit').value,
      valueDailyLimit: this.form.get('valueDailyLimit').value,
      valueThirtyLimit: this.form.get('valueThirtyLimit').value,
    }
    this.clientLimitRateService
      .requestLimitRate(requestBody)
      .then((res) => {
        this.loadingService.hide();
        if (res.requested === true) {
          // Modal para sucesso
          this.notification(
            'success',
            'LIMITS_RATE.MESSAGES.SUCCESS',
            'LIMITS_RATE.BUTTONS.CONCLUDE_BUTTON',
            'LIMITS_RATE.MESSAGES.DETAILS_SUCCESS',
            false
          );
        } else if (res.requested === false && !!res.reasonCode) {
          // Modal para retentativa
          this.notification(
            'warning-circle',
            this.checkReasonRefusal(res.reasonCode, res.reason).message,
            'LIMITS_RATE.BUTTONS.CLOSE_BUTTON',
            this.checkReasonRefusal(res.reasonCode, res.reason).details,
            false,
          );
        } else {
          // Modal para erro
          this.notification(
            'warning-circle',
            'LIMITS_RATE.MESSAGES.FAILED',
            'LIMITS_RATE.BUTTONS.CLOSE_BUTTON',
            'LIMITS_RATE.MESSAGES.DETAILS_FAILED',
            false
          );
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        console.error(err);
        // Modal para erro
        this.notification(
          'warning-circle',
          'LIMITS_RATE.MESSAGES.FAILED',
          'LIMITS_RATE.BUTTONS.CLOSE_BUTTON',
          'LIMITS_RATE.MESSAGES.DETAILS_FAILED',
          false
        );
      });
  }

  private checkReasonRefusal(reasonCode: string, reason: string) {
    switch (reasonCode) {
      case 'REQ-LIM-002': return {
        message: 'LIMITS_RATE.MESSAGES.RECUSED_002',
        details: 'LIMITS_RATE.MESSAGES.DETAILS_RECUSED_002'
      };
      case 'REQ-LIM-003': return {
        message: 'LIMITS_RATE.MESSAGES.RECUSED_003',
        details: 'LIMITS_RATE.MESSAGES.DETAILS_RECUSED_003'
      };
      case 'REQ-LIM-004': return {
        message: 'LIMITS_RATE.MESSAGES.RECUSED_004',
        details: 'LIMITS_RATE.MESSAGES.DETAILS_RECUSED_004'
      };
      default : return {
        message: reason,
        details: 'LIMITS_RATE.MESSAGES.DETAILS_FAILED'
      };
    }
  }

}
