import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-favorite-type.component.html',
  styleUrls: ['./modal-favorite-type.component.scss'],
})
export class ModalFavoriteTypeComponent {
  
  @Input() public bankName?: string;
/*   private langDefault: string;
  private layoutConfigValue: LayoutConfigModel; */

  constructor(
    public modalController: ModalController,
/*     private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService */
  ) {
/*     this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault); */
  }

  public chooseType(item: any) {
    this.modalController.dismiss(item);
  }

}
