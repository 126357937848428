import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
@Component({
  selector: 'app-modal-tracking-process',
  templateUrl: './modal-tracking-process.component.html',
  styleUrls: ['./modal-tracking-process.component.scss'],
})
export class ModalTrackingProcessComponent implements OnInit {

  @Input() public confirmBtn: string;
  @Input() public listApproves: []
  @Input() public amountRequired: number;
  @Input() public numberOfApprovals: number;

  public progressTextStatus: string;
  
  constructor(
    public modalController: ModalController,
    private translate: UiBsTranslateService,
  ) {}

  public ngOnInit() {}

  public setTrackerByStatus(aproval) {
    if (aproval == "APPROVED") {
      this.progressTextStatus = this.translate.applyTranslate("CASH_OUT.APPROVALS.MODAL.STEP_OF_APPROVAL.APPROVAL_STATUS.APPROVED")
      return 'stepItemContent aproved';
    } else if (aproval == "REPROVED") {
      this.progressTextStatus = this.translate.applyTranslate("CASH_OUT.APPROVALS.MODAL.STEP_OF_APPROVAL.APPROVAL_STATUS.REPROVED")
      return 'stepItemContent reproved';
    } else {
      return 'stepItemContent';
    }
  }

  public goBack() {
    this.modalController.dismiss();
  }
}
