import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractDataFilter',
  pure: false
})
export class ExtractDataFilterPipe implements PipeTransform {
  transform(names: any[], terms: string): any[] {
    let AUTHORIZED = "AUTHORIZED";
    let NOT_AUTHORIZED = "NOT_AUTHORIZED";
    let SCHEDULED = '"SCHEDULED"';
    /*     if (!names) return [];
        if (!terms) return names;    
        terms = terms.replace(',', '.');        
        terms = terms.toLowerCase();
        return names.filter((it) => {
          return JSON.stringify(it).toLowerCase().includes(terms);
        }); */
    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    /*  terms = terms.split('/').reverse().join('-'); */
    terms = terms.replace(',', '.');
    if (terms.substr(0, 4) === "auto") {
      terms = AUTHORIZED.toLowerCase();
    }
    if (terms.substr(0, 4) === "agen") {
      terms = SCHEDULED.toLowerCase();
    }
    if (terms === "não") {
      terms = NOT_AUTHORIZED.toLowerCase();
    }
    if (terms.length === 8) {
      let split = terms.split('');
      split.splice(0, 2)
      terms = split.join('');
    } else {
      return names.filter((it) => {
        return JSON.stringify(it).toLowerCase().includes(terms);
      });
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

  transformReceipts(names: any[], terms: string): any[] {
    /*     if (!search) return data;
    
        return data.filter(info => {
          let text = info.nameDestination.toLowerCase();
    
          if (info.accountDestination !== null) {
            text = `${info.accountDestination.toLowerCase()} ${text}`;
          }
    
          return text.indexOf(search.toLowerCase()) > -1;
        }); */
    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    terms = terms.split('/').reverse().join('-');
    terms = terms.replace(',', '.');
    if (terms.length === 8) {
      let split = terms.split('');
      split.splice(0, 2)
      terms = split.join('');
    } else {
      return names.filter((it) => {
        return JSON.stringify(it).toLowerCase().includes(terms);
      });
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

  public transformPipeLabel(names: any[], terms: string, labels?: string[]): any[] {
    if (!names) return [];
    let array = []
    if (!terms) return names;
    terms = terms.toLowerCase();
    names.filter((it) => {
      if (labels != undefined && labels.length > 0) {
        labels.filter(label => {
          console.log(it)
          if (it[label] === null || it[label] === undefined) return it;
          else if (it[label].toString().toLowerCase().includes(terms)) { array.push(it) }
        });
      } else {
        if (JSON.stringify(it).toLowerCase().includes(terms)) array.push(it);
      }
    });
    return array;
  }
}
