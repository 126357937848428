import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-modal-cnab-shipping-line',
  templateUrl: './modal-cnab-shipping-line.component.html',
  styleUrls: ['./modal-cnab-shipping-line.component.scss'],
})
export class ModalCnabShippingLineComponent implements OnInit {
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  @Input() shippingLine: string;
  @Input() returnLine: string;
  @Input() messageOccorrence: string;

  constructor(
    private modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) { }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  dismiss() {
    this.modalController.dismiss()
  }
}
