import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import {CompanyDataService, Step, StepFlowService} from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import {catchError, retry, takeUntil, tap} from "rxjs/operators";
import {of, Subject} from "rxjs";
import {environment} from "../../../../../../../../environments/environment";
import {Router} from "@angular/router";

@Component({
  selector: 'ui-t-company-data',
  templateUrl: './company-data.page.html',
  styleUrls: ['./company-data.page.scss'],
})
export class CompanyDataPage {
  companyDataForm: FormGroup;
  errorMessages: any;

  nextStep: Step;

  private destroy$: Subject<null> = new Subject();

  constructor(
    private companyDataService: CompanyDataService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private stepsService: StepFlowService,
    private router: Router,
    private prepareFormDevolution: PreparFormDevolutionService
  ) {
  }
  async ngOnInit() {
    this.nextStep = await this.stepsService.getSteps(environment, this.router.url);
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);
    
    this.companyDataForm = this.companyDataService.getCompanyDataForm((this.nextStep.accountType == "MEI") ? true : false);
    this.errorMessages = this.companyDataService.getErrorMessage();
    await this.companyDataService.updateForm();

    await this.prepareFormDevolution.prepareFormDevolution(this.nextStep, this.companyDataForm);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isMei(type: string) {
    if(type == "MEI") {
      return true;
    } else {
      return false;
    }
  }
 
  goContinue(): void {
    if(this.companyDataForm.invalid) {
      return;
    }


    this.companyDataService.sendCompanyData()
      .pipe(
        takeUntil(this.destroy$),
        tap(async res => {
          await this.stepsService.nextStep(this.navCtrl, this.nextStep);
        }),
        retry(2),
        catchError((err) => {
          this.companyDataService.getErrorCompanyData(err);
          return of(null);
        })
      ).subscribe();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.nextStep);
  }

  public isMobile(): boolean{
    if(window.innerWidth < 750){
      return true;
    }else{
      return false
    }
  }
}
