import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UICMenuAppService } from '@wlp/ui-c-menu-app';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'card-loading-page',
  templateUrl: './card-loading.page.html',
  styleUrls: ['./card-loading.page.scss'],
})
export class CardLoadingPage implements OnInit {
  public showValueFlag: boolean;
  public showValueIcon: string;
  public brand: string;
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private uicMenuApp: UICMenuAppService,
    private modalCtrl: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  showIcon(icon: string, extension?: string): string {
    let iconUrl = `assets/images/casa-feliz/wallet/${icon}.svg`;
    if (extension) {
      iconUrl = iconUrl.replace(/\.svg$/, `.${extension}`);
    }

    return iconUrl;
  }

  ionViewDidEnter() {
    this.uicMenuApp.applyStatus(false);
  }

  closeModal(): void {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}
