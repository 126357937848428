import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { CardConsultResultDto, CardsService } from '@wlp/ui-bs-cards';
import { ExtractDigitalAccountService, ExtractService } from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UiCCardRedefinePasswordComponent } from '@wlp/ui-c-card-redefine-password';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { environment } from '../../../../../../../../environments/environment';
import { ModalAlertCardComponent } from '../../../../modals/modal-alert-card/modal-alert-card.component';
import { ModalCardDocumentComponent } from '../../../../modals/modal-card-document/modal-card-document.component';
import { ModalExpenseControlComponent } from '../../../../modals/modal-expense-control/modal-expense-control.component';
import { ModalTokenComponent } from '../../../../modals/modal-token/modal-token.component';
import { ModalAlertComponent } from './../../../../modals/modal-alert/modal-alert.component';
import { ModalUnlockCardAlertComponent } from './../../../../modals/modal-unlock-card-alert/modal-unlock-card-alert.component';
import { ModalUnlockCardComponent } from './../../../../modals/modal-unlock-card/modal-unlock-card.component';

@Component({
  selector: 'ui-t-card-manager',
  templateUrl: './card-manager.page.html',
  styleUrls: ['./card-manager.page.scss'],
})
export class CardManagerPage implements OnInit {
  private SEVEN_DAYS = 30;
  private userProfile: UserInfoProfile;
  public extractList: Array<any> = [];
  public pageLimit = 10;

  public swButtons = false;
  public results: CardConsultResultDto;
  public cards: Array<any> = [];
  public cardsOrigin: Array<any>;
  public nameCard: any;
  public noCards = true;
  public cardSelected: any;
  public param: object;
  public paramSearch: object;
  public cardRequested = false;
  public cardOrderRequested = false;
  private accountData: any;
  public fontSize: string;
  public isVisibleExtractCart = true; //!environment.production;
  public realm: string;
  startDate: Date;
  endDate: Date;
  public nicknameForm: FormGroup;
  public errorMessages: any;
  public editableNickname = false;
  currentPage = 1;
  cardsPerPage = 4;
  public maxData: number;
  disableSearchBar = true;
  searchInCardsTable: string = '';
  arraySize: number;
  private readonly OPERATION: string = '1003';
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  public searchText: string = '';
  extractColors: { creditColor: string; debitColor: string };

  constructor(
    protected userInfoService: UserInfoService,
    private cardService: CardsService,
    private myDataService: UiBsMyDataService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private extractDigitalAccountService: ExtractDigitalAccountService,
    private cdr: ChangeDetectorRef,
    private extractService: ExtractService,
    private navCtrl: NavController,
    private translate: UiBsTranslateService
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
    this.errorMessages = this.cardService.getFormMessageErrorNickname();
    this.nicknameForm = this.cardService.createNicknameForm();
    this.maxData = 0;
  }

  public ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.loadUser();
    this.paramSearch = { searchDay: this.SEVEN_DAYS };
    this.prepareColorExtrac();
  }

  public selectCard(cards: any) {
    this.swButtons = false;
    this.cardSelected = cards;

    this.editNickname('close');
    this.nicknameForm.setValue({
      nickname: this.cardSelected.nickname ? this.cardSelected.nickname : this.cardSelected.carriersName,
    });
    const currentDate = new Date();
    const sevenDateAgo = this.getDatePeriodFilter(new Date(), this.SEVEN_DAYS);

    this.getExtractByPeriod(this.userProfile, cards, sevenDateAgo, currentDate);

    this.removeSelected(cards);
    this.cardOrderRequested = cards.currentAccount.cardCheck === null;
    if (this.cardSelected.carriersName.split('').length > 25) {
      this.fontSize = '10';
    } else {
      this.fontSize = '12';
    }
  }

  editNickname(type) {
    if (type == 'close') {
      this.editableNickname = false;
      this.nicknameForm.setValue({
        nickname: this.cardSelected.nickname ? this.cardSelected.nickname : this.cardSelected.carriersName,
      });
    } else {
      this.editableNickname = true;
    }
  }

  public requestCard() {
    this.loadingService.show();
    this.cardService
      .validateOperation(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((res) => {
        this.loadingService.hide();
        if (!res) {
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
          return;
        } else {
          if (!res.isAllowed) {
            this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED_NOT_FUNDS');
            return;
          } else {
            this.modalCardDocument(
              'CARDS.CARD_MANAGER.CARD_REQUEST.TITLE',
              'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CONTINUE',
              'CARDS.CARD_MANAGER.CARDS_ACTIVE.BUTTON_CANCEL',
              'request',
              'warning-circle'
            );
          }
        }
        return;
      })
      .catch((err) => {
        this.loadingService.hide();
        this.cardService.getError(err);
        this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
      });
  }

  public activeCartNoName() {
    this.navCtrl.navigateRoot('/app/cards/card-active');
  }

  public disabledRequestCard() {
    if (this.realm === 'sabank') {
      return true;
    } else {
      return this.cardsOrigin && this.cardsOrigin.length > 0;
    }
  }

  public async modalAlertCard(type?: any, message?: string, titleBtn?: string) {
    const modal = await this.modalController.create({
      component: ModalAlertCardComponent,
      cssClass: 'modal-alert-card-class',
      componentProps: {
        titleBtnConfirm: titleBtn,
        alertRequest: type === 'request' ? true : false,
        message,
        param: this.param,
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        switch (type) {
          case 'request': {
            this.checkRequisitionInProgressService(type);
            break;
          }
        }
      }
    });
    return await modal.present();
  }

  public async modalUnlockAlert(card: any) {
    const modal = await this.modalController.create({
      component: ModalUnlockCardAlertComponent,
      cssClass: 'modal-unlock-card-class',
      componentProps: {
        titleBtnConfirm: 'CARDS.CARD_MANAGER.CARD_UNLOCK_ALERT.CONTINUE',
        alertRequest: true,
        cardResponse: card,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.unlockCard(data.data);
      }
    });
    return await modal.present();
  }

  private async checkRequisitionInProgressService(type: string) {
    await this.cardService
      .checkRequisitionInProgress(this.userProfile.uuidAccount, this.accountData.documentDefault)
      .then((res) => {
        if (res.response === true) {
          this.modalCardNotification(
            'warning-circle',
            'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED_REQUEST_IN_PROGRESS'
          );
        } else if (res.response === false) {
          this.token(type);
        } else {
          console.error('error', res);
          this.token(type);
        }
      })
      .catch((err) => {
        console.error(err);
        this.token(type);
      });
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadUser() {
    const currentDate = new Date();
    const sevenDateAgo = this.getDatePeriodFilter(new Date(), this.SEVEN_DAYS);

    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadAcountData(this.userProfile.uuidAccount);
        this.getCards(this.userProfile);
        this.getRequestCardTax(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);

        // this.getExtractByPeriod(user, this.cardSelected, sevenDateAgo, currentDate);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private async getCards(userProfile: UserInfoProfile) {
    this.loadingService.show();

    // busca os dados do cartao
    await this.cardService
      .getCards(userProfile.uuidWhiteLabel, userProfile.uuidAccount)
      .then((res) => this.getCardsResult(res))
      .catch((err) => {
        // se falhar na primeira, deve procurar por status de pedidos na tabela
        this.cardService
          .getCardsStatus(userProfile.uuidAccount)
          .then((res) => {
            this.loadingService.hide(), this.getCardsResult(res);
          })
          .catch((err) => {
            // se todas falharem...
            this.loadingService.hide();
            console.error(err);
          });
      });
  }

  private getCardsResult(res) {
    this.loadingService.hide();
    try {
      if (res !== null) {
        this.results = res;
        if (this.results.statusCode === 400) {
          this.noCards = true;
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CONSULT_CARD_ERROR');
          this.cards = [];
        } else {
          this.cardRequested = this.disabledRequestCard();
          if (this.results.cards.length > 0) {
            this.maxData = this.results.cards.length - 1;
            this.cardsOrigin = this.results.cards;
            this.noCards = false;
            this.selectCard(this.results.cards[0]);
            if (this.results.cards.length > 5) {
              this.disableSearchBar = false;
            }
            this.arraySize = this.results.cards.length;
          } else if (this.results.account.length > 0) {
            this.cardRequested = this.disabledRequestCard();
            this.cardOrderRequested = true;
            this.noCards = true;
            this.selectCard(this.results.account[0]);
          } else {
            this.noCards = true;
          }
        }
      } else {
        this.noCards = true;
      }
    } catch (error) {
      this.noCards = true;
    }
  }

  getCardsOnCurrentPage() {
    const startIndex = (this.currentPage - 1) * this.cardsPerPage;
    const endIndex = startIndex + this.cardsPerPage;
    return this.cards.slice(startIndex, endIndex);
  }

  get totalPages() {
    return Math.ceil(this.cards.length / this.cardsPerPage);
  }

  filterCards() {
    if (!this.searchInCardsTable) {
      this.cards = this.cardsOrigin;
      return;
    }
    const searchTermLower = this.searchInCardsTable.toLowerCase();
    const searchResults = this.cardsOrigin.filter((card) => {
      return (
        card.carriersName.toLowerCase().includes(searchTermLower) ||
        (card.nickname && card.nickname.toLowerCase().includes(searchTermLower)) ||
        card.status.toLowerCase().includes(searchTermLower) ||
        card.currentAccount.cardCheck.includes(this.searchInCardsTable)
      );
    });
    this.cards = searchResults;
  }

  private removeSelected(selected) {
    if (this.cardsOrigin) {
      this.cards = this.cardsOrigin.filter((obj) => !obj.uuid.includes(selected.uuid));
    }
  }

  private async loadAcountData(uuidAccount: string) {
    await this.cardService
      .getAccountDataReduceWithAddress(uuidAccount)
      .pipe(
        tap((data) => {
          this.accountData = data;
        })
      )
      .subscribe();
  }

  private async getRequestCardTax(uuidWhiteLabel, uuidAccount) {
    await this.cardService
      .getCardTax(uuidWhiteLabel, uuidAccount)
      .then((res) => {
        const fomatter = new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        });
        this.param = { value: fomatter.format(res.value).replace('R$', '') };
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public formatNumberCard(numberCard: string) {
    if (numberCard) {
      return numberCard.substr(2, 4);
    } else {
      return '';
    }
  }

  public statusCard(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.ACTIVE';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.INACTIVE';
    } else {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.BLOQUED';
    }
  }

  public statusCardIcon(status: string) {
    if (status === '2' || status === 'Bloqueado') {
      return 'lock-closed';
    } else {
      return 'ellipse';
    }
  }

  public statusColor(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'active-color';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'inactive-color';
    } else {
      return 'blocked-color';
    }
  }

  public blockedCard(status: string) {
    if (status === '2' || status === 'Bloqueado') {
      return 'blocked';
    } else {
      return '';
    }
  }

  public detailsOrder(cardRequested) {
    this.modalCardDocument(
      'Detalhes do Pedido',
      'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CLOSE',
      null,
      'success',
      null,
      cardRequested
    );
  }

  private async modalCardDocument(
    titleModal: string,
    titleBtn: string,
    titleBtnCancel: string,
    type: string,
    tagImg: string,
    card?: any
  ) {
    const modal = await this.modalController.create({
      component: ModalCardDocumentComponent,
      cssClass: tagImg !== null ? 'modal-card-class' : 'modal-detail-class',
      componentProps: {
        title: titleModal,
        titleBtnConfirm: titleBtn,
        titleBtnCancel,
        alertRequest: type === 'request' ? true : false,
        tagImg,
        uuidAccountPayment: this.userProfile.uuidAccount,
        cardResponse: card,
        valueCard: this.param,
        accountData: this.accountData,
        doubleButton: (tagImg !== null) ? true : false
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        switch (type) {
          case 'request': {
            this.checkRequisitionInProgressService(type);
            break;
          }
          case 'unlock': {
            // this.token(data.data.document, type, card)
            // this.unlockService(card, data.data.document);
            break;
          }
        }
      }
    });
    return await modal.present();
  }

  public async token(type?: string, card?: any) {
    const modalToken = await this.modalController.create({
      component: ModalTokenComponent,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: true,
        validationCard: true,
        codeOperation: this.OPERATION,
      },
    });
    modalToken.onDidDismiss().then((data) => {
      if (!!data.data && !!data.role) {
        const token = {
          code: data.role,
        };

        switch (type) {
          case 'request': {
            this.requestCardService(token.code);
            break;
          }
          case 'unlock': {
            this.unlockService(card);
            break;
          }
          default: {
            this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
          }
        }
      }
    });
    return await modalToken.present();
  }

  private async requestCardService(token) {
    this.loadingService.show();
    await this.cardService
      .requestCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token)
      .then((res) => {
        const card = res;
        this.loadingService.hide();
        if (!!res && res.success === true) {
          this.modalCardDocument(
            'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.SUCCESS',
            'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_SUCCESS',
            null,
            'success',
            'solicitation-sucess-card',
            card
          );
          this.getCards(this.userProfile);
          this.cdr.detectChanges();
        } else {
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        console.error(err);
        this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
      });
  }

  public async unlockCard(card) {
    const modal = await this.modalController.create({
      component: ModalUnlockCardComponent,
      cssClass: 'modal-unlock-card-class',
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        if (!!card.currentAccount && data.data === card.currentAccount.cardCheck) {
          this.token('unlock', card);
        } else {
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.INVALID_NUMBERS');
        }
      }
    });
    return await modal.present();
  }

  private async modalCardNotification(status: string, message: string, confirmation?: boolean, card?: any) {
    const modal = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status,
        message,
        confirmBtn: confirmation ? 'CARDS.CARD_MANAGER.CARDS_LOCK.CONFIRM_YES' : 'CARDS.CARD_MANAGER.CARDS_LOCK.CLOSE',
        cancelBtn: 'CARDS.CARD_MANAGER.CARDS_LOCK.CONFIRM_NOT',
        confirmation: confirmation,
        translate: true,
      },
    });
    modal.onDidDismiss().then((callback) => {
      if (callback.data === true) {
        this.blockCard(card);
      }
    });
    return await modal.present();
  }

  public async changePassword(card) {
    const modal = await this.modalController.create({
      component: UiCCardRedefinePasswordComponent,
      cssClass: 'ui-c-card-redefine-password',
      componentProps: {
        errorMessages: this.cardService.getFormMessageErrorPassword(),
        formCardPassword: this.cardService.createformCardPassword(),
        imgPath: 'assets/images/cash-out/payment-notification/confirmado.svg',
        numberCard: `${!!card.nickname ? card.nickname : card.carriersName}: •••• •••• •••• ${this.formatNumberCard(card.currentAccount.cardCheck)}`,
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        this.changePasswordService(data.data, card);
      }
    });

    return await modal.present();
  }

  async expenseControl(card: any) {
    const modal = await this.modalController.create({
      component: ModalExpenseControlComponent,
      cssClass: 'modal-expense-control',
      componentProps: {
        card: card,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        this.updateExpense(data.data);
      }
    });

    return await modal.present();
  }

  private async updateExpense(expense) {
    this.loadingService.show();
    await this.cardService
      .limitUpdate(expense)
      .pipe(
        tap((res) => {
          this.loadingService.hide();
          this.getCards(this.userProfile);
          if (!!res) {
            this.modalCardNotification('success', 'CARDS.CARD_MANAGER.EXPENSE_CONTROL.MESSAGES.SUCCESS');
          } else {
            this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.EXPENSE_CONTROL.MESSAGES.FAILED');
          }
        }),
        catchError((_) => {
          this.loadingService.hide();
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.EXPENSE_CONTROL.MESSAGES.FAILED');
          return of(null);
        })
      )
      .subscribe();
  }

  saveNickname(card) {
    let form = this.nicknameForm.getRawValue();
    let prepare = {
      uuidPaymentCard: card.uuid,
      nickname: form.nickname,
    };
    this.editableNickname = false;
    this.updateNickname(prepare, card);
  }

  private async updateNickname(nick, card) {
    this.loadingService.show();
    await this.cardService
      .nicknameUpdate(nick)
      .pipe(
        tap((res) => {
          this.getCards(this.userProfile);
          this.loadingService.hide();
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  public getDatePeriodFilter(currentDate: Date, periodDays: number): Date {
    return new Date(currentDate.setDate(currentDate.getDate() - periodDays));
  }

  private getExtractByPeriod(userProfile: UserInfoProfile, card: any, startDate: Date, endDate: Date): void {
    this.loadingService.show();
    if (this.isVisibleExtractCart) {
      this.extractDigitalAccountService
        .getStatementByCard(userProfile.uuidWhiteLabel, userProfile.uuidAccount, card.uuid, startDate, endDate)
        .pipe(
          tap((res) => {
            this.extractList = res;
            this.loadingService.hide();
          }),
          catchError((_) => {
            this.loadingService.hide();
            return of(null);
          })
        )
        .subscribe();
    }
  }

  public dateControl(date: any): string {
    try {
      /* let dateFormat = new Date(date);
      dateFormat.setHours(dateFormat.getHours() - 3); */
      return `${moment(date).format('DD/MM/YYYY HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  private formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length === 6) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }
    return date;
  }

  public showIsBalance(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsBalance(typeStatement);
  }

  public showIsCreditOrDebit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsCreditOrDebit(typeStatement);
  }

  public isCredit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.isCredit(typeStatement);
  }

  public valueDisplayCreditOrDebit(extractDigitalAccount: any): number {
    return this.extractDigitalAccountService.valueDisplayCreditOrDebit(extractDigitalAccount);
  }

  public doInfinite(event) {
    setTimeout(() => {
      this.pageLimit += 10;
      event.target.complete();
    }, 1000);
  }

  private async unlockService(card) {
    this.loadingService.show();
    await this.cardService
      .unlockCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, card)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
        } else {
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.SUCCESS');
          this.getCards(this.userProfile);
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
      });
  }

  confirmBlockCard(card: any) {
    this.modalCardNotification(
      'warning-circle',
      this.translate.applyTranslate('CARDS.CARD_MANAGER.CARDS_LOCK.CONFIRM_LOCK_MESSAGE', {
        numberCard: `${!!card.nickname ? card.nickname : card.carriersName}: •••• •••• •••• ${this.formatNumberCard(card.currentAccount.cardCheck)}`,
      }),
      true,
      card
    );
  }

  public async blockCard(card) {
    this.loadingService.show();
    await this.cardService
      .blockCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, card)
      .then((res) => {
        this.loadingService.hide();

        if (res.statusCode === 400) {
          this.getCards(this.userProfile);
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.FAILED');
        } else {
          this.getCards(this.userProfile);
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.SUCCESS');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.FAILED');
      });
  }

  private async changePasswordService(pass, card) {
    this.loadingService.show();
    const password = {
      newPassword: pass.newPassword,
      currentPassword: pass.currentPassword,
      card: {
        cardNumber: card.cardNumber,
        status: card.status,
        activateCardViaAdministrative: card.activateCardViaAdministrative,
        currentAccount: {
          currentAccountNumber: card.currentAccount.currentAccountNumber,
          cardCheck: card.currentAccount.cardCheck,
          proxyNumber: null,
        },
      },
    };
    await this.cardService
      .changePassword(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, password)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_PASSWORD.MESSAGES.FAILED');
        } else {
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_PASSWORD.MESSAGES.SUCCESS');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('warning-circle', 'CARDS.CARD_MANAGER.CARDS_PASSWORD.MESSAGES.FAILED');
      });
  }

  public downloadStatement(format) {
    let url;
    switch (format) {
      case 'xlsx':
        this.extractDigitalAccountService
          .getStatementByCardDownload(
            this.userProfile.uuidWhiteLabel,
            this.userProfile.uuidAccount,
            this.startDate,
            this.endDate,
            this.cardSelected.uuid,
            format,
            this.cardSelected.currentAccount.cardCheck,
            this.cardSelected.nickname
          )
          .subscribe((res) => {
            FileSaver.saveAs(
              new Blob([res]),
              `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.xlsx`
            );
          });
        break;
      case 'ofx':
        this.extractDigitalAccountService
          .getStatementByCardDownload(
            this.userProfile.uuidWhiteLabel,
            this.userProfile.uuidAccount,
            this.startDate,
            this.endDate,
            this.cardSelected.uuid,
            format,
            this.cardSelected.currentAccount.cardCheck,
            this.cardSelected.nickname
          )
          .subscribe((res) => {
            FileSaver.saveAs(
              new Blob([res]),
              `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.ofx`
            );
          });
        break;
      case 'pdf':
        this.extractDigitalAccountService
          .getStatementByCardDownload(
            this.userProfile.uuidWhiteLabel,
            this.userProfile.uuidAccount,
            this.startDate,
            this.endDate,
            this.cardSelected.uuid,
            format,
            this.cardSelected.currentAccount.cardCheck.substring(2),
            this.cardSelected.nickname,
            this.extractColors.creditColor.replace('#', ''),
            this.extractColors.debitColor.replace('#', '')
          )
          .subscribe((res) => {
            this.extractService.downloadStatementPdf(res.content).subscribe((res) => {
              FileSaver.saveAs(
                new Blob([res]),
                `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`
              );
            });
          });
        break;
    }
  }

  dateStart(date) {
    this.startDate = new Date(date);
  }

  dateEnd(date) {
    this.endDate = new Date(date);
  }

  filterByDate() {
    this.getExtractByPeriod(this.userProfile, this.cardSelected, this.startDate, this.endDate);
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  prepareColorExtrac() {
    this.extractColors = {
      creditColor: '#006400',
      debitColor: '#ff0000',
    };
    if (environment.wlThemeParameterization.wlTheme.global.extractColors != undefined) {
      this.extractColors = environment.wlThemeParameterization.wlTheme.global.extractColors;
    }
  }
}
