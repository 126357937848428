import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LanguagesService, LanguagesList } from '@wlp/ui-bs-util';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.page.html',
  styleUrls: ['./general-settings.page.scss'],
})
export class GeneralSettingsPage implements OnInit {
  public langDefault: string;
  public allLanguages: LanguagesList[];
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private loadingService: LoadingService,
    private navCtrl: NavController,
    private translate: UiBsTranslateService,
    private languagesService: LanguagesService,
    private layoutConfigService: LayoutConfigService
  ) { }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;

    this.translate.setDefaultLang(this.langDefault);
    this.allLanguages = this.languagesService.getAllLanguages();
  }

  public languageChange(languageData) {
    this.loadingService.show();

    this.langDefault = languageData.detail.value;
    this.layoutConfigService.setConfig({ wlTheme: { languageDefault: this.langDefault } }, true);
    this.translate.use(this.langDefault);

    setTimeout(() => {
      this.loadingService.hide();
      window.location.assign('/app/dashboard');
    }, 1500);
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
}
