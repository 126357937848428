import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'ui-t-modal-billet-alert-cancel',
  templateUrl: './modal-billet-alert-cancel.html',
  styleUrls: ['./modal-billet-alert-cancel.scss'],
})
export class ModalBilletAlertCancelComponent implements OnInit {

  @Input() public confirm?: any;
  @Input() public title?: string;
  @Input() public description?: string;
  @Input() public alert1?: string;
  @Input() public alert2?: string;
  @Input() public confirmBtn: string;
  @Input() public cancelBtn: string;
  
  private readonly OPERATION: string = '1002';

  constructor(
    public modalController: ModalController,
  ) {
    
  }

  ngOnInit(): void {}

  dismiss() {
    this.modalController.dismiss()
  }

  dismissSuccess() {
    this.modalController.dismiss()
    this.confirm()
  }
}
