import { BilletCreateDataFilterPipe } from './../charging/billet/billet-create/billet-create-data-filter.pipe';
import { ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';

import { ExtractDataFilterPipe } from './extract-data-filter.pipe';
import { ExtractUtilInterface } from './extract-util.interface';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { CpManagerBasePage } from "../../cp-manager-base.page";
import { UserInfoService } from "@wlp/ui-bs-login";

export class ExtractUtil extends CpManagerBasePage implements ExtractUtilInterface {
  @ViewChild(`groupPagination`, { static: false }) groupPagination: ElementRef;
  @ViewChild(`btnList`, { static: false }) btnList: ElementRef;
  @ViewChild(`tableData`, { static: false }) tableData: ElementRef;
  @ViewChild(`contentHolder`, { static: false }) contentHolder: ElementRef;

  public listStatment: any[];
  public listStatmentShow: Array<any>;
  public listFuture: any[];
  public listShowFuture: Array<any>;
  public listReceipts: any[];
  public listReceiptsShow: Array<any>;
  public scrollTimes: number;
  public dateExtractInit: any = null;
  public dateExtractEnd: any = null;
  public optionDownload: any;
  public inputFilter: string;
  public currentPage: number;
  public dataPerPage: number;
  public maxData: number;

  public flagDateFilter: boolean;

  public searchText: string;

  public calInitialDate: Date;
  public calEndDate: Date;

  // datas do filtro
  public filterInitialDate: Date;
  public filterEndDate: Date;

  // Data inicial em dias anteriores
  protected INITIAL_DATE = 7;

  // flag esconde botoes (excel e pdf)
  public flagHideBTNS = false;

  // controle de cores para os botoes de periodos
  public btnColor: string;

  // Controle para identificar a rota que o usuário acessou e escolha do
  // tipo de filtro que será utilizado
  public tpVoucher: string;

  constructor(
    protected userInfoService: UserInfoService,
    public pipeFilter: ExtractDataFilterPipe | BilletCreateDataFilterPipe,
    public detection: ChangeDetectorRef,
    public modalController: ModalController,
    public loadingService: LoadingService,
  ) {
    super(userInfoService);

    this.listStatment = [];
    this.listStatmentShow = [];

    this.listFuture = [];
    this.listShowFuture = [];

    this.listReceipts = [];
    this.listReceiptsShow = [];

    this.inputFilter = '';

    this.currentPage = 1;
    this.dataPerPage = 20;
    this.maxData = 0;

    this.flagDateFilter = false;

    this.calInitialDate = moment(new Date()).subtract(this.INITIAL_DATE, 'days').toDate();
    this.calEndDate = new Date();
    this.filterInitialDate = null;
    this.filterEndDate = null;

    moment.locale('pt-br');
  }

  /**
   * metodo de filtro dos dados (usando pipe)
   * @param e
   */
  public filter(value) {
    if (value !== '') {
      this.listStatmentShow = this.pipeFilter.transform(this.listStatment, value);
      this.maxData = this.listStatmentShow.length;
      return;
    }

    // atualiza o front da tabela
    this.setArrayForShow(true);
    this.calcPagination();
  }

  /**
   * metodo de filtro dos dados (usando pipe)
   * @param e
   */
  public filterFuture(value) {
    if (value !== '') {
      this.listShowFuture = this.pipeFilter.transform(this.listFuture, value);
      this.maxData = this.listShowFuture.length;
      return;
    }

    // atualiza o front da tabela
    this.setArrayForShowFuture(true);
    this.calcPaginationFuture();
  }


  /**
 * metodo de filtro dos dados (usando pipe)
 * @param e
 */
  public filterReceipt(value) {
    if (value !== '') {
      this.searchText = value;
      this.listReceiptsShow = this.pipeFilter.transform(this.listReceipts, value);
      this.maxData = this.listReceiptsShow.length;
      return;
    }

    // atualiza o front da tabela
    this.setArrayForShowReceipts(true);
    this.calcPaginationReceipts();
  }

  /**
   * set de array que deve ser apresentado por cada pagina
   */
  public setArrayForShow(isNewSearch?: boolean) {
    if (isNewSearch) {
      this.currentPage = 1;
      this.listStatmentShow = [];
    }

    for (let i = ((this.currentPage - 1) * this.dataPerPage);
      i < (this.currentPage * this.dataPerPage); i++) {
      this.listStatmentShow.push(this.listStatment[i]);
    }

    this.detection.detectChanges();
  }

  /**
   * set de array que deve ser apresentado por cada pagina
   */
  public setArrayForShowFuture(isNewSearch?: boolean) {
    if (isNewSearch) {
      this.currentPage = 1;
      this.listShowFuture = [];
    }

    for (let i = ((this.currentPage - 1) * this.dataPerPage);
      i < (this.currentPage * this.dataPerPage); i++) {
      this.listShowFuture.push(this.listFuture[i]);
    }

    this.detection.detectChanges();
  }

  /**
   * set de array que deve ser apresentado por cada pagina
   */
  public setArrayForShowReceipts(isNewSearch?: boolean) {
    if (isNewSearch) {
      this.listReceiptsShow = [];
      this.currentPage = 1;
    }

    for (let i = ((this.currentPage - 1) * this.dataPerPage);
      i < (this.currentPage * this.dataPerPage); i++) {
      const index = (i < 0) ? (i * -1) : i;
      if (this.listReceipts[index] !== undefined) {
        this.listReceiptsShow.push(this.listReceipts[index]);
      }
    }

    this.detection.detectChanges();
  }

  /**
   * metodo de calculo das paginas, baseado no array (vezes que pode fazer o scroll)
   */
  public calcPagination() {
    this.maxData = this.listStatment.length;
    let paginationSize = this.maxData / this.dataPerPage;

    if (Number(paginationSize) === paginationSize && paginationSize % 1 !== 0) {
      paginationSize = Math.floor(paginationSize) + 1;
    }

    this.scrollTimes = paginationSize;
  }

  public calcPaginationReceiptShow() {
    this.maxData = this.listReceiptsShow.length;
    let paginationSize = this.maxData / this.dataPerPage;

    if (Number(paginationSize) === paginationSize && paginationSize % 1 !== 0) {
      paginationSize = Math.floor(paginationSize) + 1;
    }

    this.scrollTimes = paginationSize;
  }

  /**
  * metodo de calculo das paginas, baseado no array (vezes que pode fazer o scroll)
  */
  public calcPaginationFuture() {
    this.maxData = this.listFuture.length;
    let paginationSize = this.maxData / this.dataPerPage;

    if (Number(paginationSize) === paginationSize && paginationSize % 1 !== 0) {
      paginationSize = Math.floor(paginationSize) + 1;
    }

    this.scrollTimes = paginationSize;
  }

  /**
   * metodo de calculo das paginas, baseado no array (vezes que pode fazer o scroll)
   */
  public calcPaginationReceipts() {
    this.maxData = this.listReceipts.length;
    let paginationSize = this.maxData / this.dataPerPage;

    if (Number(paginationSize) === paginationSize && paginationSize % 1 !== 0) {
      paginationSize = Math.floor(paginationSize) + 1;
    }

    this.scrollTimes = paginationSize;
  }

  public dateControl(date: any, data, label): string {
    try {
      if (date.constructor === Array) {
        let dateFormated = `${moment(this.formattedDate(date)).format('DD/MM/YYYY')}`;
        let elementData = this.listReceiptsShow[this.listReceiptsShow.indexOf(data)]
        elementData[label] = dateFormated
        this.listReceiptsShow[this.listReceiptsShow.indexOf(data)] = elementData;
        return dateFormated;
      } else return date;
    } catch (err) {
      return '';
    }
  }

  public dateControlReceipt(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  public refControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('MM/YYYY')}`;
    } catch (err) {
      return '';
    }
  }

  public formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length === 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }

    return date;
  }

  public toggleDateFilter() {
    this.flagDateFilter = true;
    this.detection.detectChanges();
  }

  /**
   * responde ao evento de selecao de data do calendario
   * @param dateSelected
   * @param status
   */
  public onSelectedDate(dateSelected: moment.Moment, status: string): boolean {
    if (dateSelected === undefined) return;
    dateSelected = moment(dateSelected)
    this.optionDownload = ''
    const date = dateSelected.toDate();
    if (status === 'initial') {
      this.filterInitialDate = date;
      return;
    }

    this.filterEndDate = date;
    return;
  }

  public filterByDate() {
    this.loadingService.show();
    if (this.filterInitialDate === null || this.filterEndDate === null) {
      this.loadingService.hide();
      return;
    }
    //console.log('filter',this.filterInitialDate, this.filterEndDate);
    // aplica as datas aos filtros
    this.calInitialDate = moment(this.filterInitialDate).toDate();
    this.calEndDate = moment(this.filterEndDate).toDate();
    this.dateExtractInit = moment(this.filterInitialDate)
    this.dateExtractEnd = moment(this.filterEndDate)
    this.resetBtnFilters();
    this.getDigitalStatement(); // chama o metodo de busca por data
  }

  public filterByDateReceipts() {
    this.loadingService.show();
    if (this.filterInitialDate === null || this.filterEndDate === null) {
      this.loadingService.hide();
      return;
    }

    this.calInitialDate = this.filterInitialDate;
    this.calEndDate = this.filterEndDate;
    this.dateExtractInit = moment(this.filterInitialDate)
    this.dateExtractEnd = moment(this.filterEndDate)
    this.currentPage = 1;
    this.resetBtnFilters();
    this.getReceipts(this.tpVoucher);
  }

  public resetBtnFilters() {
    const btnsHolder: HTMLElement = (this.btnList.nativeElement as HTMLElement);
    const btns: NodeListOf<HTMLElement> = btnsHolder.querySelectorAll('.btn-filter');
    btns.forEach(btn => btn.classList.remove('primary'));
  }

  public async filterByDay(event: Event, days?: number) {
    this.loadingService.show();
    this.resetBtnFilters(); // reseta os botoes

    // aplica o estilo para o botao clicado
    const element: HTMLElement = (event.target as HTMLElement);
    element.classList.add('primary');

    // caso for por date
    if (days !== undefined) {
      /* const date = moment(this.calEndDate).subtract(days, 'days').toDate(); */
      const date = this.getDatePeriodFilter(new Date(), days);
      await this.getDigitalStatement(date);

      // toggle para o botao de perido
      this.flagDateFilter = false;
      // this.loadingService.hide();
      // return;
    }
    // this.loadingService.hide();
    // caso for selecionar o periodo (apresenta os campos de periodo)
    this.toggleDateFilter();
  }

  public filterByDayReceipts(event: Event, days?: number) {
    this.loadingService.show();
    this.resetBtnFilters(); // reseta os botoes

    // aplica o estilo para o botao clicado
    const element: HTMLElement = (event.target as HTMLElement);
    element.classList.add('primary');

    // caso for por date
    if (days !== undefined) {
      /* const date = moment(this.calInitialDate).subtract(days, 'days').toDate(); */
      const date = this.getDatePeriodFilter(new Date(), days);
      this.getReceipts(this.tpVoucher, date);

      // toggle para o botao de perido
      this.flagDateFilter = false;
      this.loadingService.hide();
      return;
    }
    this.loadingService.hide();
    // caso for selecionar o periodo (apresenta os campos de periodo)
    this.toggleDateFilter();
  }

  /**
   * infinite scroll
   */
  infiniteScrollReceipts() {
    const holder: HTMLElement = (this.contentHolder.nativeElement as HTMLElement);
    if (Math.round(holder.scrollHeight) - Math.round(holder.scrollTop) <= holder.clientHeight) {
      if (this.currentPage < this.scrollTimes) {
        this.currentPage += 1;
        this.setArrayForShowReceipts();
      }
    }
  }

  infiniteScrollFuture() {
    const holder: HTMLElement = (this.contentHolder.nativeElement as HTMLElement);
    if (Math.round(holder.scrollHeight) - Math.round(holder.scrollTop) <= holder.clientHeight) {
      if (this.currentPage < this.scrollTimes) {
        this.currentPage += 1;
        this.setArrayForShowFuture();
      }
    }
  }

  infiniteScroll() {
    const holder: HTMLElement = (this.contentHolder.nativeElement as HTMLElement);

    if (Math.round(holder.scrollHeight) - Math.round(holder.scrollTop) <= holder.clientHeight) {
      if (this.currentPage < this.scrollTimes) {
        this.currentPage += 1;
        /* this.setArrayForShow(); */
        if(this.tpVoucher === 'future') {          
          this.setArrayForShowFuture()
        }else {
          this.setArrayForShow()
        }    
      }
    }
  }

  infiniteScrollEvent(event: Event) {
    const holder: HTMLElement = (event.currentTarget as HTMLElement);
    if (Math.round(holder.scrollHeight) - Math.round(holder.scrollTop) <= holder.clientHeight) {
      if (this.currentPage < this.scrollTimes) {
        this.currentPage += 1;
        this.setArrayForShow();
      }
    }

  }

  agencyZeros(n: number, width, zeros = '0'): string | number {
    try {
      const num = n.toString();
      return num.length >= width ? n : new Array(width - num.length + 1).join(zeros) + n;
    } catch (err) {
      console.warn('Não existe valores a serem informados');
      return '';
    }
  }

  // metodo aplicado para o filterByDate (override!)
  public getDigitalStatement(date?: Date) { }

  public getReceipts(type: string, date?: Date) { }

  getDatePeriodFilter(currentDate: Date, periodDays: number): Date {
    this.calInitialDate = moment(new Date()).subtract(periodDays, 'days').toDate();
    this.calEndDate = new Date();
    this.dateExtractInit = moment(this.calInitialDate)
    this.dateExtractEnd = moment(this.calEndDate)
    return this.calInitialDate;
  }
}
