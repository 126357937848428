import { PreparFormDevolutionService } from '@wlp/ui-bs-util';
import { StorageService } from '@wlp/ui-bs-oauth';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { CompanyAddressService, ResponsibleMeiService, Step, StepFlowService } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import * as moment from 'moment';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'ui-t-responsible-mei',
  templateUrl: './responsible-mei.page.html',
  styleUrls: ['./responsible-mei.page.scss'],
})
export class ResponsibleMeiPage {
  public businessDataForm: FormGroup = null;
  public errorMessages: any;

  public personalDataForm: FormGroup = null;
  public errorPersonalMessages: any;

  public personalAddressForm: FormGroup = null;
  public errorPersonalAddressMessages: any;

  public form: FormGroup;

  public formStepControler: number;

  public nextStep: any;
  public listUf: Array<any>;

  private meiOwner: any;
  public stepsPartners: any;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private responsibleService: ResponsibleMeiService,
    private stepsService: StepFlowService,
    private companyAddressService: CompanyAddressService,
    private router: Router,
    private storageService: StorageService,
    private prepareFormDevolution: PreparFormDevolutionService
  ) {
    moment.locale('pt-BR');

    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    // primeiro formulario
    this.businessDataForm = this.responsibleService.getFormBusinessPartner();
    this.errorMessages = this.responsibleService.getFormBusinessPartnerError();

    // segundo formulario
    this.personalDataForm = this.responsibleService.getFormPersonalData();
    this.errorPersonalMessages = this.responsibleService.getFormBusinessPersonalError();

    // terceiro formulario
    this.personalAddressForm = this.responsibleService.getBusinessAddressForm();
    this.errorPersonalAddressMessages = this.responsibleService.getFormBusinessAddressError();

    this.responsibleService.updateForm();

    // primeiro form
    this.formStepControler = 0;
  }

  public async ngOnInit() {
    this.nextStep = await this.stepsService.getSteps(environment, this.router.url);

    this.listUf = this.companyAddressService.getUf();

    this.responsibleService.getMeiOwner().subscribe(res => {
      if (!!res && res.length > 0) {
        this.meiOwner = res[0];
        this.businessDataForm = this.responsibleService.getFormBusinessPartner(this.meiOwner);
        this.personalDataForm = this.responsibleService.getFormPersonalData(this.meiOwner.personalData);
        this.personalAddressForm = this.responsibleService.getBusinessAddressForm(this.meiOwner.personalAddress);
        this.preparePartnersDevolution();
      }
    });
  }

  private preparePartnersDevolution(form?: FormGroup) {
    if (this.nextStep.retryOnboarding != undefined) {
      this.stepsPartners = this.nextStep.retryOnboarding.partners;
      this.storageService.set('isRetryOnboarding', true);
    }

    // console.log('preparePartnersDevolution', this.stepsPartners);
    if (!!form) this.editPartner(form);
  }

  private async editPartner(form: FormGroup) {
    if (this.nextStep.retryOnboarding != undefined) {
      let step = this.stepsPartners.find(el => {
        return el.uuidPartner === this.meiOwner.id;
      });

      let partnerStartStep = step.steps.find(step => {
        return step.name === "PERSONAL_DATA";
      })

      // console.log('partnerStartStep', partnerStartStep);
      if (partnerStartStep != undefined) {
        await this.prepareFormDevolution.prepareFormDevolutionPartners(partnerStartStep.fields, form);
        // console.log(this.personalDataForm.controls);
      }
    }
  }

  public async goContinue() {
    await this.responsibleService.sendFormBusinessPartner(this.businessDataForm.getRawValue(), !!this.meiOwner ? this.meiOwner.id : null);
    this.formStepControler = 1;
    this.preparePartnersDevolution(this.personalDataForm);
  }

  public convertingDate() {
    const date = this.personalDataForm.get('birthDay').value;
    const toDate = moment(date, 'DD/MM/YYYY').toDate();

    return toDate.toISOString();
  }

  public async continueAddress() {
    const form = this.personalDataForm.getRawValue();
    form.birthDay = this.convertingDate();

    await this.responsibleService.sendFormBusinessPersonal(form);
    this.preparePartnersDevolution(this.personalAddressForm);
    this.formStepControler = 2;
  }

  public verifyCep(ev: any) {
    this.responsibleService.searchPostaCode(ev.target.value);
  }

  public async finishMei() {
    const form = this.personalAddressForm.getRawValue();
    await this.responsibleService.sendFormBusinessAddress(form);

    await this.stepsService.nextStep(this.navCtrl, this.nextStep);
  }

  public async goBack() {
    if (this.formStepControler === 0) {
      await this.stepsService.backStep(this.navCtrl, this.nextStep);
      return;
    }

    this.formStepControler--;
  }
}
