import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UiBsSignupModule } from '@wlp/ui-bs-signup';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCRetryRegistrationModule } from '@wlp/ui-c-retry-registration';
import { environment } from 'src/environments/environment';

import { InterceptService } from '../../../../../core/intercept/intercept.service';
import { ComponentsModule } from '../../../../../core/shared/modules/components.module';
import { RetryOnboardingRoutingModule } from './retry-onboarding-routing.module';
import { RetryOnboardingPage } from './retry-onboarding.page';

@NgModule({
  declarations: [
    RetryOnboardingPage
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    RetryOnboardingRoutingModule,
    UiBsTranslateModule.forRoot({ device: 'WEB', config: environment }),
    UiBsSignupModule.forRoot({ device: 'WEB', config: environment }),
    UiCRetryRegistrationModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class RetryOnboardingModule { }
