import { NavController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'app-developer',
  templateUrl: './developer.page.html',
  styleUrls: ['./developer.page.scss'],
})
export class DeveloperPage implements OnInit {
  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;
  constructor(
    private loadingService: LoadingService,
    private layoutConfigService: LayoutConfigService,
    private navCtrl: NavController,
    private translate: UiBsTranslateService
  ) { }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);
  }

  public storageReset() {
    this.loadingService.show();
    this.layoutConfigService.resetConfig();

    setTimeout(() => {
      this.loadingService.hide();
      window.location.assign('/app/dashboard');
    }, 1500);
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
}
