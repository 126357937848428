import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'billetCreateDataFilter',
})
export class FavoritesDataFilterPipe implements PipeTransform {
  transform(data: any[], search) {
    if (!search) return data;

    return data.filter(info => {
      let name = (info.name || '').toLowerCase()
      let document = (info.document || '').toLowerCase();
      let codeBank = (info.codeBank || '').toLowerCase();
      let bankName = (info.bankName || '').toLowerCase();
      let accountNumber = (info.accountNumber || '').toLowerCase();
      let agencyNumber = (info.agencyNumber || '').toLowerCase();

      return name.indexOf(search.toLowerCase()) > -1 || document.indexOf(search.toLowerCase()) > -1
        || codeBank.indexOf(search.toLowerCase()) > -1
        || bankName.indexOf(search.toLowerCase()) > -1 || accountNumber.indexOf(search.toLowerCase()) > -1
        || agencyNumber.indexOf(search.toLowerCase()) > -1;
    });
  }

}
