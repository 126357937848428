import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter',
  pure: false
})
export class DataFilterPipe implements PipeTransform {
  transform(names: any[], terms: string): any[] {
    /*     if (!names) return [];
        if (!terms) return names;    
        terms = terms.replace(',', '.');        
        terms = terms.toLowerCase();
        return names.filter((it) => {
          return JSON.stringify(it).toLowerCase().includes(terms);
        }); */

    if (!names) return [];
    if (!terms) return names;
    terms = terms.toLowerCase();
    terms = terms.split('/').reverse().join('-');
    terms = terms.replace(',', '.');
    if (terms.length === 8) {
      let split = terms.split('');
      split.splice(0, 2)
      terms = split.join('');
    } else {
      return names.filter((it) => {
        return JSON.stringify(it).toLowerCase().includes(terms);
      });
    }
    terms = terms.toLowerCase();
    return names.filter((it) => {
      return JSON.stringify(it).toLowerCase().includes(terms);
    });
  }

}
