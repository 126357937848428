import { Component, OnInit } from '@angular/core';
import { OnboardingEcService } from './onboarding-ec.service';
import { OnboardingEcInterface } from './onboarding-ec.interface';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'app-onboarding-ec',
  templateUrl: './onboarding-ec.page.html',
  styleUrls: ['./onboarding-ec.page.scss'],
})
export class OnboardingEcPage implements OnInit {
  isCreate: boolean;
  titleData: OnboardingEcInterface;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private OnboardingEcService: OnboardingEcService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    //

    // this.OnboardingEcService.getTitleSubject().pipe(tap(data => {
    //   this.titleData = data;
    // })).subscribe();
  }
  getData() {
    this.titleData = this.OnboardingEcService.getTitleSubject();
    return this.titleData.isTitle;
  }
}
