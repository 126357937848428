import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController, ToastController } from '@ionic/angular';
import { IPageBase } from '@wlp/ui-bs-signup';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { PartnersEcService } from '@wlp/ui-bs-ec';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'ui-t-partners-ec.page',
  templateUrl: './partners-ec.page.html',
  styleUrls: ['./partners-ec.page.scss'],
})
export class PartnersECPage implements OnInit, IPageBase {
  public userProfile: UserInfoProfile;

  formAddPartners: FormGroup;
  listPartners: any;

  errorFormMessages: any;
  businessPartner: any;
  indexItem: any;

  isDisabled: boolean;
  readonly: boolean;
  flagPartner: boolean;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private partnerEcService: PartnersEcService,
    private loadingService: LoadingService,
    private toastController: ToastController,
    protected userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.formAddPartners = this.partnerEcService.getFormBusinessPartner();
    this.errorFormMessages = this.partnerEcService.getFormMessageError();
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.isDisabled = false;
    this.indexItem = 0;
    this.loadUser();
    this.loadPartners();
  }

  loadUser() {
    try {
      this.userInfoService.getInfoProfile().then((user) => {
        this.userProfile = user;
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  loadPartners() {
    this.listPartners = this.partnerEcService.getPartnersList(
      this.userProfile.uuidAccount,
      this.userProfile.uuidWhiteLabel
    );
  }

  ionViewWillEnter() {
    this.loadPartners();
  }

  goBack() {
    this.navCtrl.navigateRoot('cp-manager/dashboard');
  }

  editPartner(index) {
    this.isDisabled = false;
    this.indexItem = index;
    this.businessPartner = this.listPartners[this.indexItem];
    this.businessPartner ? (this.readonly = this.businessPartner.master) : false;
    this.toEdit();
  }

  toEdit() {
    if (this.indexItem !== null && this.businessPartner) {
      this.formAddPartners = this.partnerEcService.editForm(this.businessPartner);
    }
  }

  deletePartner(partner: any) {
    let uuidBusinessPartner = partner.uuidBusinessPartner;
    const removedPartner = this.partnerEcService.removePartner(
      this.userProfile.uuidAccount,
      this.userProfile.uuidWhiteLabel,
      uuidBusinessPartner
    );
    this.listPartners = this.listPartners.filter(
      (partner) => partner.uuidBusinessPartner !== removedPartner.uuidBusinessPartner
    );
  }

  continue() {
    this.navCtrl.navigateRoot('cp-manager/dashboard');
    const types = [
      { type: 'legalRepresentative', value: this.listPartners[0].legalRepresentative },
      { type: 'attorney', value: this.listPartners[0].attorney },
      { type: 'partner', value: this.listPartners[0].partner },
    ];

    const type = types.filter((arr) => {
      if (arr.value === true) return arr.type;
    });

    // envia o usupario para a página de documentos com o tipo.
    this.navCtrl.navigateRoot(['onboarding-ec/upload-documents-ec'], { queryParams: { type: type[0].type } });
  }

  public async savePartner() {
    if (!this.indexItem) {
      if (!this.findCpfExists()) {
        this.indexItem = this.partnerEcService.addNewPartner(
          this.formAddPartners,
          this.userProfile.uuidAccount,
          this.userProfile.uuidWhiteLabel
        );
        await this.loadPartners();
      } else {
        this.toastError();
      }
    } else {
      const editPartner = await this.partnerEcService.editPartner(
        this.formAddPartners,
        this.userProfile.uuidAccount,
        this.userProfile.uuidWhiteLabel,
        this.indexItem
      );
      const uuidBusinessPartner = this.listPartners[this.indexItem].uuidBusinessPartner;
      editPartner.uuidBusinessPartner = uuidBusinessPartner;
      this.listPartners[this.indexItem] = editPartner;
    }
    this.formAddPartners = this.partnerEcService.getFormBusinessPartner();
    this.indexItem = null;
  }

  async toastError() {
    const toast = await this.toastController.create({
      message: 'Ocorreu um erro ao salvar. Por favor tente novamente. CPF já cadastrado!',
      duration: 3000,
    });
    toast.present();
  }

  findCpfExists(): boolean {
    const listCpf = this.partnerEcService
      .getPartnersList(this.userProfile.uuidAccount, this.userProfile.uuidWhiteLabel)
      .map((partner) => partner.docNumber);
    return listCpf.some((docNumber) => docNumber === this.formAddPartners.get('docNumber').value);
  }

  addPartner() {}
}
