import { LoadingService } from './../../../../../core/layout/services/loading.service';
import { ContractService } from '@wlp/ui-bs-signup';

import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';

@Component({
  selector: 'ui-t-modal-privacy-policy',
  templateUrl: './modal-privacy-policy.component.html',
  styleUrls: ['./modal-privacy-policy.component.scss'],
})
export class ModalPrivacyPolicyComponent implements OnInit {

  @Input() wlName: string;
  @Input() company: any;
  @Input() policy: any;
  @Input() tokenValidation : boolean;
  contract: any;

  constructor(
    private modalController: ModalController,
    public toastController: ToastController,
    public contractService: ContractService,
    private loadService: LoadingService

  ) { }

  ngOnInit() {
    this.loadService.show();
    this.contractService.getTermByuuid(this.policy.uuid)
      .then(res => {
        this.loadService.hide();
        res.subscribe(res => {
          this.contract = res;
        })
      })
  }

  async cancel() {
    this.modalController.dismiss({
      dismissed: false,
    });
  }

  async confirm() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }
}
