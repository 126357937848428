import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { PrePixService } from '@wlp/ui-bs-pre-pix';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { combineLatest } from 'rxjs';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

@Component({
  selector: 'app-choose-key',
  templateUrl: './choose-key.component.html',
  styleUrls: ['./choose-key.component.scss'],
})
export class ChooseKeyComponent implements OnInit {
  prePixForm?: FormGroup;
  prePixErrorMessages?: any;
  type_account: string;
  type_person: string;
  accountInfo: {
    type_person: string;
    type_account: string;
    account: string;
    brand: string;
    name: string;
  };

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private prePixService: PrePixService,
    private uiBsMyDataService: UiBsMyDataService,
    private userInfoService: UserInfoService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.prePixForm = this.prePixService.getFormPrePix();
    this.prePixErrorMessages = this.prePixService.getFormMessageError();
    this.userInfoService.getInfoProfile().then((userInfoProfile) => {
      combineLatest([
        this.uiBsMyDataService.getAccountPayment(userInfoProfile.uuidAccount),
        this.prePixService.getPrePixFormFromBehavior(),
      ]).subscribe((data) => {
        switch (data[0].accountPaymentType.typeFlow) {
          case 0:
            this.type_account = 'PHHYSICAL_PERSON';
            break;
          case 1:
            this.type_account = 'LEGAL_PERSON';
            break;
          case 2:
            this.type_account = 'MEI';
            break;
          case 3:
            this.type_account = 'LIBERAL_PROFESSIONAL';
            break;
        }

        if (data[0].accountPaymentType.typeFlow === 1 || data[0].accountPaymentType.typeFlow === 2) {
          this.type_person = 'PJ';
        } else {
          this.type_person = 'PF';
        }

        this.accountInfo = {
          // type_account: data[0].accountPayment.accountModel,
          type_account: this.type_account,
          type_person: this.type_person,
          // type_person: data[0].accountPaymentType.name,
          brand: userInfoProfile.agencyBank,
          account: userInfoProfile.numberAccountBank,
          name: userInfoProfile.nameComplete,
        };

        this.prePixForm.patchValue({
          ...data[1],
          docNumberCheck: !!data[1].cpf || !!data[1].cnpj,
          phoneCheck: !!data[1].phone,
          emailCheck: !!data[1].email,
          docNumber: data[1].cpf || data[1].cnpj || data[0].accountPaymentPerson.documentDefault || '',
          phone: data[0].accountPaymentContact.cellphone || '',
          email: data[0].accountPaymentContact.email || '',
        });
      });
    });
  }
  getPrePixObject() {
    const formValues = this.prePixForm.getRawValue();
    let cpf = '';
    let cnpj = '';

    if (formValues.docNumber.length <= 14) {
      cpf = formValues.docNumber;
    } else {
      cnpj = formValues.docNumber;
    }

    const prePixObject = {
      cpf: formValues.docNumberCheck ? cpf : '',
      cnpj: formValues.docNumberCheck ? cnpj : '',
      phone: formValues.phoneCheck ? formValues.phone : '',
      email: formValues.emailCheck ? formValues.email : '',
    };

    return prePixObject;
  }

  isFormEmpty() {
    const formValues = this.prePixForm.getRawValue();
    const prepixObject = this.getPrePixObject();
    const arrFields = [];

    if (formValues.docNumberCheck === true) {
      arrFields.push(prepixObject.cpf !== '' || prepixObject.cnpj !== '');
    }

    if (formValues.phoneCheck === true) {
      arrFields.push(prepixObject.phone !== '');
    }

    if (formValues.emailCheck === true) {
      arrFields.push(prepixObject.email !== '');
    }

    return arrFields.filter((value) => value === false).length > 0 || arrFields.length === 0;
  }

  sendDataToPrePixFormBehaviorSubject() {
    const objectToBeSent = this.getPrePixObject();
    this.prePixService.setPixByObject({ ...this.accountInfo, ...objectToBeSent } as any);
  }

  goBack() {
    this.navCtrl.navigateRoot(['app/pix/pre-register/intro-pix']);
  }
  continue() {
    this.sendDataToPrePixFormBehaviorSubject();
    this.navCtrl.navigateRoot(['app/pix/pre-register/confirmation-pix']);
  }
  cancel() {
    this.navCtrl.navigateRoot(['app/dashboard']);
  }
}
