import {Component, Input} from '@angular/core';
import {ModalController, NavController} from '@ionic/angular';
import {UiBsTranslateService} from '@wlp/ui-bs-translate';
import {LayoutConfigModel} from 'src/app/core/layout/models/layout-config.model';
import {LayoutConfigService} from 'src/app/core/layout/services/layout-config.service';
import {environment} from '../../../../../../environments/environment';
import {LoadingService} from '../../../../../core/layout/services/loading.service';


@Component({
  selector: 'modal-migration-success',
  templateUrl: './modal-migration-success.component.html',
  styleUrls: ['./modal-migration-success.component.scss'],
})
export class ModalMigrationSuccessComponent {

  @Input() bankLiquidation: string;
  @Input() agency: string;
  @Input() account: string;
  @Input() initiails: string;

  @Input() isNewAccount = true;
  @Input() isNewAccountError = true;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private navCtrl: NavController,
    private loadingService: LoadingService,
  ) {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

  }

  public async dismiss() {

    if (this.isNewAccount) {
      console.log('ModalSucess: Carregando ');
      this.loadingService.show();
      setTimeout(() => {

        sessionStorage.removeItem(environment.keyStorage.menu);
        this.loadingService.hide();

        window.location.href = 'app/dashboard';
      }, 3000);

    } else {
      this.modalController.dismiss();
    }

  }

}
