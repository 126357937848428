import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RetryOnboardingPage } from './retry-onboarding.page';

const routes: Routes = [
  {
    path: '',
    component: RetryOnboardingPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RetryOnboardingRoutingModule {}
