import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {DdaService} from '@wlp/ui-bs-cashout';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';
import {of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import {ModalDetailsDdaComponent} from '../../../../../modals/modal-details-dda/modal-details-dda.component';

@Component({
  selector: 'ui-t-dda-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss'],
})
export class ListPage  implements AfterViewInit {
  public userProfile: UserInfoProfile;
  public listDebitAutomatic: any[];

  constructor(
    protected userInfoService: UserInfoService,
    public modalController: ModalController,
    private navCtrl: NavController,
    public loadingService: LoadingService,
    private service: DdaService,
  ) {
  }

  public ngAfterViewInit() {
    this.loadUser();
  }

  public goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }

  public goContinue(data) {
    this.navCtrl.navigateRoot(`/app/payment/billet/${data.linhaDigitavel}`);
  }

  public async goResume(data) {

    const listData = [
      [
        { name: 'Beneficiario', value: data.beneficiario },
        { name: 'Nome Fantasia', value: data.nomeFantasia },
        { name: 'Data Vencimento', value: this.formatDateToString(data.dataVencimento) },
      ],
      [
        { name: 'Endereço', value: data.endereco },
        { name: 'Cidade', value: data.cidade },
        { name: 'UF', value: data.uf },
        { name: 'CEP', value: data.cep },
      ],
      [
        { name: 'Valor Abatimento', value: this.formatReal(data.valorAbatimento) },
        { name: 'Valor do Desconto', value: this.formatReal(data.valorDesconto01 + data.valorDesconto02 + data.valorDesconto03) },
        { name: 'Valor Percentual da Multa', value: data.valorPercentualMulta + '%'},
        { name: 'Valor', value: this.formatReal(data.valor) },
      ],
    ];

    const modal = await this.modalController.create({
      component: ModalDetailsDdaComponent,
      cssClass: 'modal-resume-dda',
      componentProps: {
        data: listData,
      },
    });
    return await modal.present();
  }

  private formatReal(vl) {
    let value = vl.toFixed(2);
    value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    return value.replace(/\s/g, '');
  }

  protected formatDateToString(data): string {
    const date = new Date(data);
    return moment(date).format('DD/MM/yyyy');
  }

  private async loadUser() {
    try {
      this.userProfile = await this.userInfoService.getInfoProfile();
      this.getData();
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private getData() {
    this.loadingService.show();
    this.service.getListDebitAutomatic(this.userProfile.uuidAccount)
      .pipe(
        tap((data) => {
          this.listDebitAutomatic = data;
          this.loadingService.hide();
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        }),
      ).subscribe();
  }
}
