import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonSlides, ModalController, NavController } from '@ionic/angular';
import { AccountBalanceService, AccountRechargeService } from '@wlp/ui-bs-cashin';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

import { ModalBilletReviewComponent } from '../../../../modals/modal-billet-review/modal-billet-review.component';
import { NotificationsPage } from '../../notifications/notifications.page';
import { ModalAlertComponent } from './../../../../modals/modal-alert/modal-alert.component';

@Component({
  selector: 'ui-t-recharge',
  templateUrl: './recharge.page.html',
  styleUrls: ['./recharge.page.scss'],
})
export class RechargePage implements OnInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  iconName: string | 'eye-off-outline' | 'eye-outline';
  showBLC = false;
  balance: string;
  balanceResponse: any;
  userResponse: any;
  nameInitials: string;
  flagDisplayCard: boolean;
  flagDisplayNotification: boolean;
  messages = [];
  balanceSubject$: string;
  formRecharge: FormGroup;
  errorFormMessages: any;

  userProfile: UserInfoProfile;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountBalanceService: AccountBalanceService,
    private accountRechargeService: AccountRechargeService,
    private headerUserInfoService: UICHeaderUserInfoService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {
    this.iconName = 'eye-off-outline';
    this.balance = '';
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.loadUser();
    this.errorFormMessages = this.accountRechargeService.getFormErrorMessage();
    this.formRecharge = this.accountRechargeService.getRechargeData();

    // atualiza o saldo da conta
    this.headerUserInfoService.updateValue(true);
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        this.loadBalance(user.uuidWhiteLabel, user.uuidAccount);
      });
    } catch (e) {
      console.log('Failed to load user details', e);
    }
  }

  onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagDisplayCard = this.flagDisplayCard ? false : true;
  }

  onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = this.flagDisplayNotification ? false : true;
  }

  iconGrid() {
    this.navCtrl.navigateRoot('#');
  }

  async showNotifications() {
    const modal = await this.modalController.create({
      component: NotificationsPage,
      cssClass: 'modal-custom-class',
    });
    return await modal.present();
  }

  showBalance() {
    if (this.iconName === 'eye-off-outline') {
      this.iconName = 'eye-outline';
      this.showBLC = true;
    } else {
      this.iconName = 'eye-off-outline';
      this.showBLC = false;
    }
  }

  getInitial(name) {
    const parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  slideBack() {
    this.slides.slidePrev();
  }

  slideNext() {
    this.slides.slideNext();
  }

  private loadBalance(uuidWL: string, uuidAccount: string): void {
    this.balanceResponse = this.accountBalanceService
      .getBalance(uuidWL, uuidAccount)
      .then((res) => this.accountBalanceService.setBalanceSubject(res.balance))
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  async showBilletRechargeReviewModal() {
    const modal = await this.modalController.create({
      component: ModalBilletReviewComponent,
      cssClass: 'modal-billet-class',
      componentProps: {
        billetRequest: true,
      },
      backdropDismiss: false,
    });

    modal.onDidDismiss().then(({ data }) => {
      if (!data) {
        this.navCtrl.navigateRoot('app');
      }
    });
    return await modal.present();
  }

  public validateOperation() {
    this.loadingService.show();
    this.accountRechargeService.saveValue(this.formRecharge.value);

    this.accountRechargeService.validateOperation(
        this.userProfile.uuidWhiteLabel,
        this.userProfile.uuidAccount,
        this.formRecharge.value.valueField
      )
      .then((res) => {
        this.loadingService.hide();

        if (!res) {
          this.statusTransaction('warning-circle', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED', false);
          return;
        } else {
          if (!res.isAllowed) {
            this.statusTransaction('warning-circle', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED_NOT_FUNDS', true);
            return;
          } else {
            this.showBilletRechargeReviewModal();
            return;
          }
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.accountRechargeService.getErrorAccountRecharge(err);
        this.statusTransaction('warning-circle', 'CASH_IN.ACCOUNT_RECHARGE_REVIEW.MESSAGES.FAILED', true);
      });
  }

  public async statusTransaction(status: string, message: string, confirmation?: boolean) {
    this.loadingService.hide();

    const modalSuccess = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class',
      componentProps: {
        status: status,
        message: message,
        confirmBtn: 'CARDS.CARD_MANAGER.CARDS_LOCK.CONFIRM_YES',
        confirmation: confirmation,
        translate: true,
      },
      backdropDismiss: false,
    });
    modalSuccess.onDidDismiss().then((data) => data);
    return await modalSuccess.present();
  }

  goBack() {
    this.navCtrl.navigateRoot('/app/dashboard');
  }
}
