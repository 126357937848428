import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ModalController, NavController} from '@ionic/angular';
import {AddCardService} from '@wlp/ui-bs-cashout';
import {PaymentService} from '@wlp/ui-bs-prospera';
import {of, Subject} from 'rxjs';
import {catchError, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'ui-t-modal',
  templateUrl: './modal-prospera-wallet.component.html',
  styleUrls: ['./modal-prospera-wallet.component.scss'],
})
export class ModalProsperaWalletComponent implements OnInit, OnDestroy {

  @Input() public data: any;
  @Input() public wL: string;

  public succeed: boolean;

  public listUf: any[];
  public errorMessages: any;
  public imagePath: string;
  public formAddCard: FormGroup;

  private id: string;
  private destroy$: Subject<null> = new Subject();

  constructor(public addCardService: AddCardService,
              public paymentService: PaymentService,
              private navCtrl: NavController,
              private route: ActivatedRoute,
              public modalController: ModalController) {
    this.succeed = true;
  }

  public ngOnInit() {
    this.errorMessages = this.addCardService.getErrorMessage();
    this.formAddCard = this.addCardService.getCardForm();
    this.listUf = this.addCardService.getUf();

    this.formAddCard.get('cvv').setValidators([Validators.required]);

    if (this.data) {
      this.formAddCard.patchValue(this.data);
      this.onChangeNumberCard(this.data.numberCard);
      this.imagePath = this.addCardService.findCardFlagForName(this.data.brand);
      this.formAddCard.get('numberCard').setValidators([
        Validators.required,
        Validators.maxLength(16)
      ]);
    }

    this.route.params.pipe(
      takeUntil(this.destroy$),
      tap((params) => {
        if (params.id) {
          this.id = params.id;
        }
      }),
    )
      .subscribe();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChangeCard(event) {
    // console.log('form', this.formAddCard);
  }

  public f(control: string): AbstractControl {
    return this.formAddCard.get(control);
  }

  public onChangeNumberCard(numberCard: string) {
    if (this.wL) {
      this.paymentService.getWalletBin(numberCard, this.wL)
        .pipe(
          takeUntil(this.destroy$),
          tap((data) => {
            this.f('brand').patchValue(String(data.provider));
            this.imagePath = this.addCardService.findCardFlag(numberCard);
          }),
          catchError((_) => {
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  public onChangePostalCode(code: string) {
    this.addCardService.searchPostalCode(code);
  }

  public toggleSucceed(): void {
    this.succeed = !this.succeed;
  }

  public async dismiss(el) {
    this.modalController.dismiss(el);
  }
}
