import { LoadingService } from './../../../../../../../core/layout/services/loading.service';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController, ModalController } from '@ionic/angular';
import { ContractService, AccountTypeService, StepFlowService, Step } from '@wlp/ui-bs-signup';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { environment } from 'src/environments/environment';

import { ModalPrivacyPolicyComponent } from '../../../../modals/modal-privacy-policy/modal-privacy-policy.component';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-contract',
  templateUrl: './contract.page.html',
  styleUrls: ['./contract.page.scss'],
})
export class ContractPage implements OnInit {
  wlName: string;
  company: any;
  contractForm: FormGroup;
  isDisabled: boolean = false;
  agreementAccepted: boolean = false;
  backButtonValue: boolean;
  accept: boolean = true;
  contract: any;
  politys: any[];
  currentStep: Step;
  partnerObj: any;
  isPartner: boolean;
  preAccountData: any;

  aceeptContracts = {
    idBusinessPartner: null,
    contracts: [],
  };

  contractAccept: {
    uuidContractTerms: '';
    accept: false;
  };
  partnerBusiness: any;
  idBusinessPartner: any;
  identifier: string = '';

  constructor(
    private navCtrl: NavController,
    private contractService: ContractService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService,
    private accountTypeService: AccountTypeService,
    private modalController: ModalController,
    private stepsService: StepFlowService,
    private router: Router,
    private loadService: LoadingService,
    private storage: Storage
  ) {
    this.backButtonValue = false;
    this.isDisabled = false;
    this.agreementAccepted = false;
    this.wlName = this.layoutConfigService.getConfig('wlTheme.wlName');
    this.company = this.layoutConfigService.getConfig('wlTheme.company');
  }

  async ngOnInit() {
    this.isPartner = true;
    this.storage.get('idBusinessPartner').then(async (result) => {
      if (result != null) {
        this.currentStep = await this.stepsService.getSteps(environment);
        this.aceeptContracts.idBusinessPartner = result;
        this.isPartner = false;
        this.idBusinessPartner=result;
        this.getDataInfo();
      }
    });

    this.storage.get('partnerName').then(async (result) => {
      if (result != null) {
        this.partnerObj = result;
      }
    });

    this.loadService.show();
    const layoutConfigValue = this.layoutConfigService.getSavedConfig();
    const langDefault = layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(langDefault);

    this.contract = {};

    this.contractForm = this.contractService.getContractForm();
    await this.contractService.updateForm();

    try {
      this.currentStep = await this.stepsService.getSteps(environment, this.router.url);
    } catch (e) {
      this.loadService.hide();
    }

    try {
      const terms = await this.contractService.getTerms();
      //console.log(terms)
      this.prepareList(terms);
    } catch (e) {
      this.loadService.hide();
    }

    this.loadService.hide();
  }

  async goBack() {
    await this.stepsService.backStep(this.navCtrl, this.currentStep);
  }

  async modalFlow(policy) {
    const modal = await this.modalController.create({
      component: ModalPrivacyPolicyComponent,
      cssClass: 'modal-flow-container-inner',
      componentProps: { wlName: this.wlName, company: this.company, policy },
      backdropDismiss: false,
    });
    await modal.present();
    return await modal.onWillDismiss();
  }

  acceptContract(event, item?) {
    if (event.target.checked) {
      this.aceeptContracts.contracts = this.aceeptContracts.contracts.filter((el) => el.uuidContractTerms != item.uuid);
      this.isValidation();
      return;
    }
    if (item.isContract) {
      const contract = {
        accept: true,
        uuidContractTerms: item.uuid,
        title: item.title,
      };
      let arr = this.aceeptContracts.contracts.filter((el) => {
        return el.uuidContractTerms === contract.uuidContractTerms;
      });
      if (arr.length === 0) {
        this.aceeptContracts.contracts.push(contract);
        this.isValidation();
      }
      return;
    }

    this.modalFlow(item).then((res) => {
      if (res.data.dismissed) {
        const contract = {
          accept: res.data.dismissed,
          uuidContractTerms: item.uuid,
          title: item.title,
        };
        let arr = this.aceeptContracts.contracts.filter((el) => {
          return el.uuidContractTerms === contract.uuidContractTerms;
        });
        if (arr.length === 0) {
          this.aceeptContracts.contracts.push(contract);
          this.isValidation();
        }
      } else {
        event.target.checked = res.data.dismissed;
      }
    });
  }

  isValidation() {
    this.isDisabled = this.contract.policy.length === this.aceeptContracts.contracts.length;
  }

  prepareList(result) {
    this.contract = result;
    const initialContract = {
      title: this.contract.contract.title,
      uuid: this.contract.contract.uuid,
      isContract: true,
    };
    this.contract.policy.push(initialContract);
  }

  async continue() {
    try {
      this.loadService.show();
      const result = await this.contractService.sendContractV2(this.aceeptContracts);
      if (result) {
        await this.stepsService.nextStep(this.navCtrl, this.currentStep);
        this.loadService.hide();
      }
      this.loadService.hide();
    } catch (e) {
      this.loadService.hide();
    }
  }
  findPartnerBusinessById(id: string) {
    return this.preAccountData.businessPartner.find((arr: any) => arr.id === id);
  }
  
  setPartnerObj(companyName: string, cpf: string, fullname: string) {
    const partnerObj = {
      companyName: companyName,
      cpf: cpf,
      fullname: fullname
    }
    this.storage.set('partnerName', partnerObj);
  }
  
  getIdentifier() {
    if (this.partnerBusiness.master && this.partnerBusiness.signature && 
        !this.partnerBusiness.attorney && !this.partnerBusiness.legalRepresentative) {
      return 'ONBORDING.V2.FORM.LABEL.PARTNERS';
    } else if (this.partnerBusiness.attorney) {
      return 'ONBORDING.V2.FORM.LABEL.ATTORNEYS';
    } else if (this.partnerBusiness.legalRepresentative) {
      return 'ONBORDING.V2.FORM.LABEL.LEGAL_REPRESENTATIVES';
    } else {
      return 'ONBORDING.V2.FORM.LABEL.PARTNERS';
    }
  }
  
  async returnPartner() {
    if (this.preAccountData.companyData) {
      this.partnerBusiness = this.findPartnerBusinessById(this.idBusinessPartner);
      this.partnerObj.companyName = this.preAccountData.companyData.companyName;
      this.partnerObj.fullname = this.partnerObj.fullname;
      this.setPartnerObj(
        this.preAccountData.companyData.companyName, 
        this.preAccountData.cpf, 
        this.partnerObj.fullname
      );
    }
  }
  
  async getDataInfo() {
    const res = await this.accountTypeService.getAccount();
    this.preAccountData = res;
    await this.returnPartner();
    this.identifier = this.getIdentifier();
  }
}
