import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FaqService } from '@wlp/ui-bs-ec';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { OnboardingEcInterface } from '../onboarding-ec.interface';
import { OnboardingEcService } from '../onboarding-ec.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.page.html',
  styleUrls: ['./faq.page.scss'],
})
export class FaqPage implements OnInit {
  public faqlist: Array<any>;
  public faqFilter: Array<any>;
  public faqSelected: any;
  public searchText = '';

  titleData: OnboardingEcInterface;

  private langDefault: string;
  private layoutConfigValue: LayoutConfigModel;

  
  constructor(
    private navCtrl: NavController,
    private faqService: FaqService,
    private onboardingEcService: OnboardingEcService,
    private layoutConfigService: LayoutConfigService,
    private translate: UiBsTranslateService
  ) {}

  ngOnInit(): void {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.faqlist = this.faqService.getFaq();
    this.faqFilter = this.faqlist;
    this.faqSelected = this.faqlist[0];

    (this.titleData = {
      isTitle: true,
      title: 'ONBOARDING_EC.FAQ.HEADER.TITLE',
      subtitle: 'ONBOARDING_EC.FAQ.HEADER.SUBTITLE',
    }),
      this.onboardingEcService.setTitleSubject(this.titleData);
  }

  goBack() {
    this.navCtrl.back();
  }

  search() {
    if (this.searchText === undefined || this.searchText === '') {
      this.faqFilter = this.faqlist;
    } else {
      this.faqFilter = this.faqlist.filter(
        (unit) => (unit.title + unit.fullDescription.toUpperCase()).indexOf(this.searchText.toUpperCase()) > -1
      );
      // console.log('pesquisar', this.faqFilter );
    }
  }

  formatDate(date) {
    const day = date.getDate().toString(),
      dayF = day.length == 1 ? '0' + day : day,
      month = this.month(date.getMonth() + 1),
      year = date.getFullYear();
    return dayF + '-' + month + '-' + year;
  }

  month(month) {
    let monthString;
    switch (month) {
      case 1:
        monthString = 'Janeiro';
        break;
      case 2:
        monthString = 'Fevereiro';
        break;
      case 3:
        monthString = 'Março';
        break;
      case 4:
        monthString = 'Abril';
        break;
      case 5:
        monthString = 'Maio';
        break;
      case 6:
        monthString = 'Junho';
        break;
      case 7:
        monthString = 'Julho';
        break;
      case 8:
        monthString = 'Agosto';
        break;
      case 9:
        monthString = 'Setembro';
        break;
      case 10:
        monthString = 'Outubro';
        break;
      case 11:
        monthString = 'Novembro';
        break;
      case 12:
        monthString = 'Dezembro';
        break;
      default:
        monthString = '--';
    }
    return monthString;
  }

  openFaq(item) {
    this.faqSelected = item;
  }
}
