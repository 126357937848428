import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'pre-register',
    loadChildren: () => import('./pre-register/pre-register.module').then((m) => m.PreRegisterModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'devolution',
    loadChildren: () => import('./devolution/devolution.module').then( m => m.DevolutionPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PixRoutingModule {}
