import { ModalAlertComponent } from './../../../../../modals/modal-alert/modal-alert.component';
import { tap, catchError } from 'rxjs/operators';
import { ModalTokenComponent } from './../../../../../modals/modal-token/modal-token.component';
import { StorageService } from '@wlp/ui-bs-oauth';
import { BilletService } from '@wlp/ui-bs-cashin';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UserInfoService } from '@wlp/ui-bs-login';
import { NavController, ModalController } from '@ionic/angular';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { BrMaskDirective } from 'br-mask';

@Component({
  selector: 'app-billet-lot-list',
  templateUrl: './billet-lot-list.page.html',
  styleUrls: ['./billet-lot-list.page.scss'],
})
export class BilletLotListPage implements OnInit {
  public listLots: Array<any> = [];
  public listLotsShow: Array<any> = [];
  private userProfile: UserInfoProfile;
  public totalValueBillets: number;
  public totalQuantityBillets: number;
  public totalFee: number;
  public lotNumber: string;
  public uuidLotBillet: string;
  public statusLotBillet: string;
  private readonly OPERATION: string = '1002';

  constructor(
    private navCtrl: NavController,
    protected userInfoService: UserInfoService,
    private billetService: BilletService,
    private loadingService: LoadingService,
    private router: Router,
    private storage: StorageService,
    private cdr: ChangeDetectorRef,
    private modalController: ModalController,
    public brMask: BrMaskDirective
  ) {}

  ngOnInit() {
    //this.loadUser();
    this.storage.get('uuidLotBillet').then((uuidLotBillet) => {
      if (uuidLotBillet != undefined) {
        this.uuidLotBillet = uuidLotBillet;
      } else {
        this.router.navigate(['/app/billing/billet-lot']);
      }
    });
    this.storage.get('statusLotBillet').then((statusLotBillet) => {
      if (statusLotBillet != undefined) {
        this.statusLotBillet = statusLotBillet;
      }
    });
  }

  ionViewWillEnter() {
    this.loadUser();
  }

  ionViewWillLeave() {
    this.removeStorage();
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(async (user) => {
        this.userProfile = user;
        await this.getLotlistBillets();
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private removeStorage() {
    this.storage.remove('uuidLotBillet');
    this.storage.remove('statusLotBillet');
  }

  private ConfirmLote(uuidLotBillet: string, token: string) {
    this.loadingService.show();
    this.billetService
      .confirmLotBillet(uuidLotBillet, token)
      .pipe(
        tap((data) => {
          if (data.status === true) {
            this.statusNotification('success', data.message, 'Em breve estará disponível para download.', true);
            this.loadingService.hide;
          } else {
            this.statusNotification('warning-circle', data.message, 'errou ao gerar o lote', true);
          }
        }),
        catchError((_) => {
          this.loadingService.hide();
          return of(null);
        })
      )
      .subscribe();
  }

  public async token() {
    const modalToken = await this.modalController.create({
      component: ModalTokenComponent,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: true,
        codeOperation: this.OPERATION,
      },
    });
    modalToken.onDidDismiss().then((data) => {
      if (data['data']) {
        this.ConfirmLote(this.uuidLotBillet, data.role);
      }
    });
    return await modalToken.present();
  }

  public goBack() {
    this.router.navigate(['/app/dashboard']);
  }

  confirm() {
    this.token();
  }

  cancel() {
    this.router.navigate(['/app/billing/billet-lot']);
  }

  private async statusNotification(status: string, message: string, description: string, translateFlag: boolean) {
    this.loadingService.hide();
    const translate = translateFlag ? true : false;
    const modalStatus = await this.modalController.create({
      component: ModalAlertComponent,
      cssClass: 'modal-alert-class-lot-list',
      componentProps: {
        status: status,
        message: message,
        description: description,
        confirmBtn: 'CASH_IN.BILLING.BILLET_LOT_LIST.BUTTONS.CLOSED_BUTTON',
        translate: translate,
      },
      backdropDismiss: false,
    });
    modalStatus.onDidDismiss().then((data) => {
      this.navCtrl.navigateRoot('/app/billing/billet-lot');
    });
    return await modalStatus.present();
  }

  /**
   * METODO RESPONSAVEL POR LISTAR OS BOLETOS VINCULADOS AO LOTE!
   */
  private getLotlistBillets() {
    this.billetService.getLotListBillets(this.userProfile.uuidAccount, this.uuidLotBillet).subscribe(
      (res) => {
        this.listLots = res.billets;
        this.totalValueBillets = res.lot.value;
        this.totalQuantityBillets = res.lot.amount;
        this.totalFee = res.lot.totalFee;
        this.lotNumber = res.lot.lotNumber;
        this.listLotsShow = this.listLots;
        this.loadingService.hide();
      },
      (err) => {
        console.error(err);
        this.loadingService.hide();
      },
      () => {
        this.loadingService.hide();
        this.cdr.detectChanges();
      }
    );
    this.cdr.detectChanges();
  }
}
