import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ExtractDigitalAccountService, TypeView} from '@wlp/ui-bs-cashout';
import * as FileSaver from 'file-saver';
import {LayoutConfigService} from '../../../../../core/layout/services/layout-config.service';

enum ExportFormat {
  XLSX = 'xlsx',
  OFX = 'ofx',
  PDF = 'pdf',
}
@Component({
  selector: 'modal-export-extract',
  templateUrl: './modal-export-extract.component.html',
  styleUrls: ['./modal-export-extract.component.scss'],
})
export class ModalExportExtractComponent implements OnInit {
  @Input() public selectedOperation: string;
  @Input() public startDate: string;
  @Input() public endDate: string;
  @Input() public uuidWhiteLabel: string;
  @Input() private uuidAccount: string;
  @Input() private extractColors: any;
  public selectedFormat: ExportFormat;
  public selectedView: TypeView;

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private extractDigitalAccountService: ExtractDigitalAccountService,
  ) {}

  ngOnInit() {}
  public downloadStatement(format, type: TypeView) {
    console.log('downloadStatement', type, format, this.selectedOperation);

    switch (format) {
      case ExportFormat.XLSX:
        this.extractDigitalAccountService.downloadStatement(this.uuidWhiteLabel, this.uuidAccount, this.startDate, this.endDate, type, format, this.selectedOperation, this.extractColors.creditColor, this.extractColors.colorDebit).subscribe((res) => {
          const blob = new Blob([res], {type: 'application/vnd.ms-excel'});
          FileSaver.saveAs(blob, `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.xlsx`);
        });
        break;
      case ExportFormat.OFX:
        this.extractDigitalAccountService.downloadStatement(this.uuidWhiteLabel, this.uuidAccount, this.startDate, this.endDate, type, format, this.selectedOperation, this.extractColors.creditColor, this.extractColors.colorDebit).subscribe((res) => {
          FileSaver.saveAs(new Blob([res]),
            `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.ofx`);
        });
        break;
      case ExportFormat.PDF:
        this.extractDigitalAccountService.downloadStatement(this.uuidWhiteLabel, this.uuidAccount, this.startDate, this.endDate, type, format, this.selectedOperation, this.extractColors.creditColor.replace('#', ''), this.extractColors.debitColor.replace('#', '')).subscribe((res) => {
          this.extractDigitalAccountService.downloadStatementPdf(res.content).subscribe(res => {
            FileSaver.saveAs(new Blob([res]),
              `Extrato-${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getUTCFullYear()}.pdf`);
          });
        });
        break;
    }
  }
  public handleExportOrCloseModal(isConfirmButton: boolean, type?: TypeView, format?: ExportFormat) {
    this.modalController.dismiss();
    if (isConfirmButton) {
      this.downloadStatement(format, type);
    }
  }

}
