import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modal-alert-docs',
  templateUrl: './modal-alert-docs.component.html',
  styleUrls: ['./modal-alert-docs.component.scss']
})
export class ModalAlertDocsComponent implements OnInit {

 /*  @Input() modalImage?: string;
  @Input() status: string;
  @Input() message: string;
  @Input() btnText: string;
  @Input() confirmation?: boolean;
  @Input() public description?: string;
  @Input() public confirmBtn: string;
  @Input() public cancelBtn?: string;
  @Input() public param?: any;
  @Input() public notButtons?: boolean = false;
  public translator: boolean;
  @Input() set translate(value) {
    if (value !== undefined && value !== null) {
      this.translator = value;
    }
  } */
  private confirmatedIcon: any;
  private warningIcon: any;
  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
  ) {
    this.confirmatedIcon = this.layoutConfigService.getImagesPages('confirmated');
    this.warningIcon = this.layoutConfigService.getImagesPages('warning-circle');
  }

  ngOnInit() {

  }
 

  confirm(confirm: boolean) {
    this.modalController.dismiss(confirm);
  }

 /*  classNotification() {
    if (this.status === 'success') {
      return 'success-text m-0 px-3';
    } else if (this.status === 'warning-circle') {
      return 'failed-text m-0 px-3';
    } else {
      return 'message-solicitation';
    }
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  } */
  public getImageTag() {
    let item: any = this.layoutConfigService.getImagesPages('warning-circle');
    return item.path;
  }
}
