import { ModalController } from '@ionic/angular';
import { LayoutConfigService } from '../../../../../core/layout/services/layout-config.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'access-web-mobile-device',
  templateUrl: './access-web-mobile-device.component.html',
  styleUrls: ['./access-web-mobile-device.component.scss']
})
export class ModalAlertAccessWebMobileDeviceComponent implements OnInit {


  @Input() public message: string;
  @Input() appleStoreImage: string;
  @Input() appleStoreImageLink: string;
  @Input() googleStoreImage: string;
  @Input() googleStoreImageLink: string;  
  @Input() isMobile: boolean;  

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
  ) {    
  }

  ngOnInit() {
    
  }

  
  confirm(confirm: boolean) {
    this.modalController.dismiss(confirm);
  }

  classNotification() {
    return 'message-solicitation';    
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
