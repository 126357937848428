import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

import * as moment from 'moment';
import { LayoutConfigModel } from 'src/app/core/layout/models/layout-config.model';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { UiBsTranslateService } from '@wlp/ui-bs-translate';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BilletStatusEnum, LotStatusEnum } from '@wlp/ui-bs-cashin';
@Component({
  selector: 'ui-t-form-filter',
  templateUrl: './form-filter.html',
  styleUrls: ['./form-filter.scss'],
})
export class FormFilter implements OnInit {
  private layoutConfigValue: LayoutConfigModel;
  private langDefault: string;

  public customPopoverOptionsTypeView: any;
  public dateStart;
  public dateEnd;

  filterLotForm: FormGroup;
  @Output() handleSubmit = new EventEmitter<string>();

  @Input() lotOrBillet: 'lot' | 'billet';
  @Input() public statusSelected: BilletStatusEnum | string;
  statusOptions: {value: string; label: string;}[] = [];
/*   dateEnd: any;
  dateStart: string; */

  constructor(
    private layoutConfigService: LayoutConfigService,
    public modalController: ModalController,
    private translate: UiBsTranslateService,
    private formBuilder: FormBuilder,
    private crd: ChangeDetectorRef
  ) {
    moment.locale('pt-br');

    this.customPopoverOptionsTypeView = {
      header: this.translate.applyTranslate('CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.LABEL'),
    };
  }

  ngOnInit() {
    this.layoutConfigValue = this.layoutConfigService.getSavedConfig();
    this.langDefault = this.layoutConfigValue.wlTheme.languageDefault;
    this.translate.setDefaultLang(this.langDefault);

    this.createFilterForm();
    this.configureDateFilters();
    this.setStatusOptions();
  }

  configureDateFilters() {
    const currentDate = moment(); 
    const sevenDaysAgo = moment().subtract(7, 'days'); 

    const formattedCurrentDate = currentDate.format('DD/MM/YYYY');
    const formattedSevenDaysAgo = sevenDaysAgo.format('DD/MM/YYYY');
  
    this.filterLotForm.get('startDate').setValue(formattedSevenDaysAgo);
    this.filterLotForm.get('endDate').setValue(formattedCurrentDate);
  }

  setStatusOptions() {
    if (this.lotOrBillet === 'billet') {
      this.statusOptions = [
        { value: "PENDING", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.BILLET.PENDING' },
        { value: "OVERDUE", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.BILLET.OVERDUE' },
        { value: "COMPENSATED", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.BILLET.COMPENSATED' },
        { value: "CANCELED", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.BILLET.CANCELED' },
        { value: "DRAFT", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.BILLET.DRAFT' },
      ]
    } else {
      this.statusOptions = [
        { value: "PENDING", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.PENDING' },
        { value: "AVAILABLE", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.AVAILABLE' },
        { value: "IN_VALIDATION", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.IN_VALIDATION' },
        { value: "IN_PROCESS", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.IN_PROCESS' },
        { value: "IN_PROCESS_ERROR", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.IN_PROCESS_ERROR' },
        { value: "DRAFT", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.DRAFT' },
        { value: "CANCELED", label: 'CASH_IN.BILLING.BILLET_LOT.FILTER.STATUS.OPTION.LOT.CANCELED' },
      ]
    }
  }

  createFilterForm() {
    this.filterLotForm = this.formBuilder.group({
      status: new FormControl(''),
      searchKey: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    });

    this.filterLotForm.valueChanges.subscribe(e => {
      this.dateStart = moment(this.filterLotForm.controls.startDate.value, 'DD/MM/YYYY').format('YYYY-MM-DD') ;
      this.dateEnd = this.filterLotForm.controls.endDate.value;
      this.crd.detectChanges();
    });
  }

  onSelectedDate(event, startOrEnd: 'initial' | 'end') {
    const inputValue = event ? moment(event, 'DD/MM/YYYY').format('YYYY-MM-DD') : '';
    const formKey = startOrEnd === 'initial' ? 'startDate' : 'endDate';
    this.filterLotForm.get(formKey).setValue(inputValue);    
  }

  handleClickSubmit() {
    this.handleSubmit.emit()
  }

  selectStatus(value: LotStatusEnum | string) {
    this.filterLotForm.get('status').setValue(value);
    this.statusSelected = value;
  }


}
