import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { routesCP } from './routes/routes-cp';

let routes: Routes = routesCP;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemesRoutingModule {}
